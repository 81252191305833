@import "includes.scss"; 

.register {
    width: 100%;
    display: flex;
    align-items: flex-start;
    .register_from {
        background: $white;
        width: 600px;
        min-height: 100vh;
        max-width: 50%;
        padding:50px;
        .register-head {
            display: block;
            text-align: center;
            img {
                @media screen and (max-width:$large) {
                    width: 100%;                    
                }
                @media screen and (max-width: $small) {

                    width: 100%;
                }
                display: block;
                margin: 0 auto 30px;
            }
            h4 {
                display: block;
                padding-bottom: 30px;
                margin: 0;
                color: $primary-color;
                font-family: $secondaryfontRegular;
                font-size: 30px;
                line-height: 28px;
            }
            
             
        }
        .login-errors {
            display: block; 
            background: rgba($secondary-color , 0.15);
            margin-bottom: 20px;
            padding: 10px 15px;
            display: flex;
            border-radius: 4px;
            .info_icon{
                min-width: 19px;
                height: 19px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: url('@/../../images/main/info_icon.png') no-repeat center center;
                background-size: 100%;
            }
            p {
                font-family: $secondaryfontRegular;
                font-weight: normal;
                font-size: 14px;
                padding: 0 0 0 10px;
                margin: 0px; 
                color: $primary-color;
            }
        }
        .qs-title {
            font-family: $secondaryfontMedium;
            font-size: 15px;
            color: $primary-color;
            margin-bottom: 15px;
            max-width: 100%;
            margin: 0;
            padding: 0 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // border-bottom: 1px solid #C8CFD2;
            .qs-info{
              color: $base-color;
              font-size: 13px;
            //   max-width: 215px;
              text-align: right;
              line-height: 16px;
            }
          }
    }
    .register-text {
        width: calc(100% - 600px);
        min-width: 50%;
        display: flex;
        align-items: flex-end;
        height: 100vh;
        position: sticky;
        top: 0px;
        padding-left: 5%;
        padding-bottom: 40px;
        padding-right: 10px;
        background: url('../images/register_bg.jpg') no-repeat;
        background-size: cover;
        overflow-y: auto;
        p {
            font-family: $neutralfontMedium;
            font-size: 24px;
            line-height: 36px;
            color: $white;
            padding: 20px 0 20px 24px;
            margin: 0px;
            position: relative;
            &:before {
                width: 4px;
                height: 100%;
                position: absolute;
                left: 0px;
                top: 0px;
                background: $secondary-color;
                content: '';
            }
        }
    }

    
    @media screen and (max-width: $large) {
        .register_from {

        }
        .register-text {
            p br {display: none;}
        }
    }

    @media screen and (max-width: $medium) {
        .register_from { 
            padding: 50px 20px;
        }
    }
    @media screen and (max-width: $belowMedium) {
        display: block;
        .register_from {
            width: 100%;
            max-width: inherit;
            padding: 50px 20px;
        }
        .register-text {
            display: none;
        }
    }

    &.register_v2 {
        background: url('../images/reg_step_1.png') no-repeat;
        background-size: cover;
        padding: 50px;
        background-attachment: fixed;
        &.step1 {background: url('../images/reg_step_1.png') no-repeat; background-size: cover; background-attachment: fixed;}
        &.step2 {background: url('../images/reg_step_2.png') no-repeat; background-size: cover; background-attachment: fixed;}
        &.step3 {background: url('../images/reg_step_3.png') no-repeat; background-size: cover; background-attachment: fixed;}
        .register_from {
            padding: 0px 0 40px 0;
            border-radius: 20px;
            .register-head {
                border-bottom: 1px solid rgba($primary-color , 0.1);
                padding: 20px 10px;
                margin-bottom: 30px;
                position: relative;
                img {
                    margin: 0 auto 20px;
                    height: 45px;
                    width: auto;
                }
                h4 {
                    padding: 0px;
                    margin: 0px;
                    font-family: $basefontMedium;
                    font-size: 30px;
                    color: $primary-color;
                }
                .progress {
                    width: 30%;
                    height: 3px;
                    display: block;
                    background: #55D2FE;
                    border-radius: 4px;
                    position: absolute;
                    left: 0px;
                    top: 100%;
                    margin-top: -1px;
                    transition: all 0.5s ease-in-out;
                    &.step1 {
                        width: 33.3%;
                    }
                    &.step2 {
                        width: 66.6%;
                    }
                    &.step3 {
                        width: 100%;
                    }
                }
                .progress-emp {
                    width: 30%;
                    height: 3px;
                    display: block;
                    background: #55D2FE;
                    border-radius: 4px;
                    position: absolute;
                    left: 0px;
                    top: 100%;
                    margin-top: -1px;
                    transition: all 0.5s ease-in-out;
                    &.step1 {
                        width: 50%;
                    }
                    &.step2 {
                        width: 100%;
                    }
                    &.step3 {
                        width: 100%;
                    }
                }
            } 
        }
        .register-text {
            background: transparent;
            height: calc(100vh - 100px);
            top: 50px;
        }
        .form-container {
            padding: 0 50px;
            @media screen and (max-width:$medium) {
                padding: 0 20px;
            }
        }
        .tags_list {
            display: flex;
            flex-wrap: wrap;
            padding: 0px;
            margin: -10px -3px 20px -3px; 
            li {
                list-style-type: none;
                display: flex;
                align-items: center;
                padding: 5px 10px 5px 15px;
                background: #F2F2F2;
                border-radius: 16px;
                min-height: 30px;
                font-family: $neutralfontMedium;
                font-size: 13px;
                color: $primary-color;
                margin: 0 3px 5px 0;
                em {
                    width: 20px;
                    height: 20px;
                    background: url('../images/icons/close.svg') no-repeat center;
                    display: block;
                    cursor: pointer;
                    margin-left: 10px;
                }
            }
        }
        .fileuploader {
            .files-list {
                position: static;
                padding: 20px 0 30px;
                ul {
                    flex-wrap: wrap;
                    li {
                        width: 33.3%;
                        border: 1px solid #70707033;
                        border-radius: 6px;
                        position: relative;
                        .remove_btn {
                            border-radius: 50%;
                            border: 1px solid #07243066;
                            img {
                                width: 10px;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width:576px) {
            padding: 10px;
        }
    }
    &.invite_signup {
       // height: calc(100vh - 246px);
        align-items: center;
        .register_from {
            padding: 0px;   
            min-height: auto;         
            .register-head {
                border-bottom: 0px;
                padding: 40px 0;
                .para-text {
                    font-size: 24px;
                }
            }
        }
        .register-text {
            height: calc(100vh - 180px);
            align-items: center;
        }
        .new_access {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 20px;
            a {
                width: 30%;
                text-decoration: none;
            }
            span {
                font-size: 14px;
                color: rgba($primary-color , 0.5);
                padding: 0 8px;
            }
        }
    }
}

@media screen and (max-width: $small) {
    .maincontent {
        padding: 0px;
    }
}

.form-group.radio_group {
    .form-labels {
        font-family: $neutralfontMedium;
        font-size: 15px;
        color: $primary-color;
        margin-bottom: 10px;
    }
    .custom-radio {
        .form-check {
            padding-right: 0;
            margin-right: 10px;
            position: relative;
            .form-check-input {
                height: 45px;
                min-width: 100px;
                display: flex;
                align-items: center;
                padding: 0 15px;
                border-radius: 6px;
                padding-right: 0;
                &:checked {
                    background: $primary-color;
                }
                &:checked + .form-check-label {
                    color: $white;
                }
            }
            .form-check-label {
                position: absolute;
                left: 0;
                top: 0px;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                margin: 0;
                font-family: $neutralfontMedium;
                font-size: 14px;
                color: $primary-color;
            }
        }
    }
    
}

.sub_title {
    font-family: $neutralfontSemiBold;
    font-size: 15px;
    color: $primary-color;
    line-height: 20px;
    position: relative;
    padding-bottom: 5px;
    display: block;
    margin: 0 0 20px 0;
    &:after {
        content: '';
        width: 50px;
        height: 2px;
        background: #007192;
        display: block;
        position: absolute;
        left: 0px;
        top: 100%;
        border-radius: 4px;
    }
}