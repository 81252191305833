@import "includes.scss"; 

.form-group {
    display: block;
    margin-bottom: 20px;
    position: relative;
    .form-label {
        @include formLabel;
        display: flex;
        align-items: center
    }
    .simpleselect { 
        @include formField;
        &:focus {
            @include formFieldFocus
        }
        .simpleselect__control {
            border: 0px;
            min-height: 100%;
            box-shadow: none;
            background: transparent;
            &:focus {
                @include formFieldFocus
            }
            .simpleselect__indicators {
                .simpleselect__indicator-separator {
                    display: none;
                }
            }
            
        }
        .simpleselect__menu {           
            z-index: 99;
        }
        &.multi_select  {
            height: auto !important;
            min-height: 45px;
            .simpleselect__control {min-height: 44px; height: auto !important;}            
        }
    }
    .form-error {
        @include formError;
    } 
} 

// .form-actions {
//     display: flex;
//     justify-content: space-between;
//     padding: 0px;
//     margin: 0px;
//     a {
//         font-family: $primaryfontRegular;
//         color: $primary-color;
//         font-size: 14px;
//         padding: 5px 0 0 0;
//         margin: 0px;
//         &:hover {
//             color: $secondary-color;
//         }
//     }
// }

// .strengthbar {
//     > div {
//         margin-top: 0px !important;
//     }
//     > p {
//         font-family: $secondaryfontRegular;
//         font-size: 14px;
//         text-transform: capitalize;
//     }
// }

.simpleselect.status_update {
    .simpleselect__control{
        height: 30px;
        min-height: 30px;
        border-radius: 20px;
        min-width: 160px;
        .simpleselect__value-container {
            .simpleselect__placeholder {
                font-size: 13px;
            }
            .simpleselect__input-container{
                padding: 0;
            }
            .simpleselect__single-value{
                font-size: 13px;
            }
        }
        &.simpleselect__control--menu-is-open {
            z-index:9999;
        }
    }
    .simpleselect__indicators{
        .simpleselect__dropdown-indicator{
            padding: 0 8px;
        }
    }
    input[value='interview'] .simpleselect__control{
        background: #FFEFDD;
        border-color: #FFEFDD;
    }
}

