
@import "includes.scss"; 

a {
  text-decoration: none;
}
/////////////////// Jobs List Starts ////////////////////////
.details_list {
  position: relative;
  z-index: 9;
}
.Jobs-list {
  .job-list  {
    display: flex;
    align-items: center;
    background: $white;
    padding: 15px 20px;
    border-radius: 4px;
    margin-bottom: 5px;
    justify-content: space-between; 
    .job-item {
      display: flex; 
      cursor: pointer;
      figure{
        width: 40px;
        height: 40px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: rgba($color: $secondary-color, $alpha: 0.05);
        margin: 0;
        overflow: hidden;
        img{
          max-width: 100%;
          object-fit: contain;
        }
      }
      .job-info {
        padding-left: 10px;
        .job_title {
          display: block;
          font-family: $primaryfontMedium;
          font-size: 16px;
          color: $secondary-color;
          padding-right: 20px;
          display: block;
          line-height: 20px;
        }
        .job-email {
          font-family: $primaryfontMedium;
          font-size: 12px;
          color: $secondary-color;
          display: block; 
          line-height: 14px;
          margin-bottom: 5px; 
        }
        .company-name {
          font-family: $primaryfontMedium;
          font-size: 14px;
          color: $primary-color;
          margin: 0 0 3px 0;
          padding: 0 20px 0 0;
        }
        .job-location {
          display: flex;
          align-items: center;
          flex-wrap: wrap;          
          line-height: normal;
          background: url('../images/icons/location.svg') no-repeat 0 0;
          padding:0 0 0 15px;
          margin: 0px;
          line-height: 14px;
          span {
            font-family: $primaryfontRegular;
            font-size: 12px;
            color: $primary-color;
            position: relative;
            padding: 0 8px 0 4px;
            &:after {
              content: '';
              width: 3px;
              height: 3px;
              background: $primary-color;
              border-radius: 50%;
              position: absolute;
              right: 0px;
              top: 50%;
              transform: translateY(-50%);
            }
            &:nth-last-child(1):after {
              display: none;
            }
          }
        }
        .job-documents {
          background: url('../images/icons/documents.svg') no-repeat top left;
          background-size: 13px;
          padding-left: 15px;
          margin-top: 3px;
          font-family: $primaryfontRegular;
            font-size: 13px;
            color: $primary-color;
            margin-bottom: 0px;
            display: flex;
            flex-wrap: wrap;
            span {
              font-family: $primaryfontRegular;
              font-size: 12px;
              color: $primary-color;
              position: relative;
              padding: 0 8px 0 4px;
              &:after {
                content: '';
                width: 3px;
                height: 3px;
                background: $primary-color;
                border-radius: 50%;
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
              }
              &:nth-last-child(1):after {
                display: none;
              }
            }
        }
        .job-type {
          margin: 0px;
          display: flex;
          align-items: center;
          line-height: 14px;
          padding: 0 0 5px 0;
          span {
            font-family: $primaryfontRegular;
            font-size: 12px;
            color: $primary-color;
            position: relative;
            padding: 0 8px 0 4px;
            &:nth-child(1) {
              padding-left: 0px;
            }
            &:after {
              content: '';
              width: 4px;
              height: 4px;
              background: $primary-color;
              border-radius: 50%;
              position: absolute;
              right: 0px;
              top: 50%;
              transform: translateY(-50%);
            }
            &:nth-last-child(1):after {
              display: none;
            }
          }
        }
      }
    }
    .updated_on {
      font-family: $primaryfontRegular;
      font-size: 14px;
      color: rgba($primary-color , 0.6);
      padding-top: 3px;
      white-space: nowrap;
      b {
        font-weight: normal;
        font-family: $primaryfontMedium;
        font-size: 14px;
        color: $primary-color;
      }
    }
    .details_list_item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      padding:20px;
      position: relative;
    }
    .job_right {
      text-align: right;
      position: absolute;
      top: 10px;
      right: 10px;
      .more-menu {
        display: inline-block;
      }
      .matches{
        font-family: $primaryfontRegular;
        font-size: 13px;
        color: $primary-color;
        display: block;
        margin-bottom: 5px;
      }
    }
    .matched_profile_list {
      padding: 0;
      
    }
        .matched_profile_sec {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 30px;
          width: 100%;
          &:nth-child(1) {
            border-top: 1px solid #8A89891A;
          }
          &:nth-child(even) {
            background: #F8F8F8;
          }
    
          .profile_sec {
            display: flex;
            align-items: center;
    
            .profile_icon {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;
              border-radius: 50%;
    
              img {
                max-width: 100%;
                object-fit: contain;
              }
            }
    
            .profile_title_sec {
              display: flex;
              flex-direction: column;
              margin-left: 10px;
    
              .profile_name {
                font-family: $primaryfontRegular;
                font-size: 15px;
                color: $primary-color;
    
                &:hover {
                  font-family: $primaryfontMedium;
                  color: $secondary-color;
                  text-decoration: underline;
                  cursor: pointer;
                }
              }
    
              .role_exp {
                font-family: $primaryfontRegular;
                font-size: 13px;
                color: $base-color;
                line-height: normal;
              }
            }
          }
    
          .action_sec {
            display: flex;
            align-items: center;
    
            .simpleselect_container.form-group {
              margin-bottom: 0;
    
              // .simpleselect.status_update {
              //   height: 30px;
              // }
            }
    
            >* {
              margin-right: 20px;
    
              &:last-child {
                margin: 0;
              }
            }
    
            span {
              cursor: pointer;
    
              &.matched {
                font-family: $primaryfontRegular;
                font-size: 13px;
                color: $primary-color;
                line-height: normal;
                padding-left: 17px;
                cursor: text;
                background: url('@/../../../../public/images/main/lightning.svg') no-repeat left center;
                white-space: nowrap;
              }
            }
            .messages_icon {
              position: relative;
              em {
                width: 10px; height: 10px;
                background: $secondary-color;
                border-radius: 50%;
                position: absolute;
                right: -5px;
                top: -1px;
              }
            }
          }
    
          @media screen and (max-width:$belowMedium) {
            flex-wrap: wrap;
    
            .profile_sec {
              margin-bottom: 15px;
            }
          }
    
          @media screen and (max-width:$small) {
            .action_sec {
              flex-wrap: wrap;
    
              >* {
                margin-bottom: 10px !important;
              }
            }
          }
        }
        .view_all_btn{
          width: auto;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $primaryfontRegular;
          font-size: 13px;
          color: $primary-color;
          line-height: normal;
          border: 1px solid #8A898933;
          border-radius: 20px;
          margin: 20px 30px 0 60px;
          cursor: pointer;
      }
      &.job-list-v2 {
        display: block;
        padding: 0px;
        border-radius: 0;
        @media screen and (max-width:$medium) { 
          padding: 0;
          margin: 0;
          .details_list_item {
            padding: 15px 10px;
            border-radius: 0px;
            .job_right  {
              top: 10px;
              right: 10px;
              .matches {                
                margin-bottom: 0;
              }
              .more-menu {
                position: static;
              }
            }
          }
          &:nth-child(even) {
            .details_list_item {
              background: #f6f6f6;
            }
          }
        }
      }
    @media screen and (max-width:$small) {
      padding: 15px 20px 20px 20px;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      .job-item { 
        flex-direction: row; 
        
        .job-info {
          
          .job_title {
            padding-right: 0px;
          }
          .company-name {
            padding: 0px;
          }
        }
      } 
      .more-menu {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .updated_on {
        font-size: 12px;
        padding-left: 50px;
        display: block;
        b {
          font-size: 12px;
        }
      }
    }
  }
}

.offcanvas.job-details {
  width: 850px !important;
  .description {
    width: 100%;
    overflow: auto;
  }
  &.forum-details {
    .description .jobs-details {
      .subtitle {
        font-family: $primaryfontMedium;
        font-size: 20px;
        color: $primary-color;
        font-weight: normal;
        margin-bottom: 10px;
      }
      .forum-discription {
          font-family: $neutralfontRegular;
          font-size: 15px; 
          color: #072430;
        h2 {
          font-family: $neutralfontRegular;
          font-size: 13px;
          color: #007192;
          padding: 0px;
          margin: 0 0 6px 0;
          font-weight: normal;
          b , strong {
            font-weight: normal;
            font-family: $neutralfontBold
          };
        }
        p {
          font-family: $neutralfontRegular;
          font-size: 15px;
          line-height: 22px;
          color: #072430;
        }
      }
    }
    .discussion-title {
      font-family: $primaryfontMedium;
      font-size: 20px;
      color: $primary-color;
      font-weight: normal;
      margin-bottom: 10px;
      padding: 0px 0 0 0px;
    }
    .job-discussions {
      border: 1px solid #ddd;
      border-radius: 10px;
      h2 {
        font-family: $primaryfontMedium;
        font-size: 20px;
        color: $primary-color;
        font-weight: normal;
        margin-bottom: 10px;
        padding: 20px 0 0 20px;
      }
      .discussion-title {
        font-family: $primaryfontMedium;
        font-size: 20px;
        color: $primary-color;
        font-weight: normal;
        margin-bottom: 10px;
        padding: 20px 0 0 20px;
      }
      .discussion_head {
        display: flex;
        margin: 0px;
        padding: 15px 20px;
        li {
          list-style-type: none;
          border: 1px solid #00719233;
          border-radius: 30px;
          height: 32px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          margin-right: 10px;
          .form-check .form-check-label {
            font-family: $basefontMedium;
            font-size: 16px;
            color: #072430;
            text-transform: capitalize;
          }
        }
      }
      .discussion_list {
        margin: 0px;
        padding: 0px;
        li {
          list-style-type: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;
          .dis_left {
            display: flex;
            align-items: flex-start;
            figure {
              width: 60px;
              height: 60px;
              min-width: 60px;
              display: flex;
              align-items: center;
              border: 1px solid #ddd;
              border-radius: 50%;
              margin: 0px;
              overflow: hidden;
              img {
                max-width: 100%;
              }
            }
            .dis_info {
              display: block;
              width: calc(100% - 60px);
              padding-left: 10px;
              .name {
                font-family: $neutralfontSemiBold;
                font-size: 15px;
                line-height: 18px;
                color: #072430;
                padding: 0px 0 3px 0;
                margin: 0px;
              }
              div {
                font-family: $neutralfontRegular;
                font-size: 12px;
                color: #072430;
              }
            }
          }
          .dis_right {
            display: flex;
            align-items: center;
            .simpleselect_container.form-group  {
              margin-bottom: 0px;
            }
          }
          &:nth-last-child(even) {
            background: #fafafa;
          }
        }
      }
    }
  }
  @media screen and (max-width:$large) {
    width: 90% !important;
  }
  @media screen and (max-width:$small) {
    width: calc(100% - 20px) !important;
  }
}

/////////////////// Jobs List Ends////////////////////////

.jobs {
   padding-top: 40px;
   max-width: 1156px;
   margin: 0 auto;
   display: flex;
   background-color: $white;
   &.pt-0 {
    padding-top: 0px;
   }
  .jobs-list {
   background-color: $base-color1;
   width: 330px;    
    .job {
      padding: 20px 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgba($white , 0.7);
      border-radius: 4px 0px 0px 4px;  
      width: calc(100% - 4px);
      margin-bottom: 4px;
      &.w-352 {
        width: 352px;
      }
      .logo {
          display: flex;
          min-width: 48px;
          height: 48px;
          overflow: hidden;
          margin-right: 10px;
          img {
            max-width: 100%;
        }
      }
      .details {
        width: calc(100% - 58px);
        // display: flex;
        // align-items: flex-end;
        .w-100 {
          width: 100%;
        }
        .details-container {
          h2 {
            font-family: $primaryfontMedium;
            color: $primary-color;
            font-size: 16px;
            margin-bottom: 0px;
            &.active {
              color: $secondary-color;
            }
          }
          span {
            font-family: $primaryfontRegular;
            font-size: 12px;
            color: $primary-color;
            display: block;
          }
          
        }
        .duration {
          display: flex;
          justify-content: space-between;
          p {
            display: flex;
            flex-wrap: wrap;
            span {
              font-family: $primaryfontRegular;
              font-size: 12px;
              color: $primary-color;
              position: relative;
              padding: 0 8px 0 4px;
              &:after {
                content: '';
                width: 3px;
                height: 3px;
                background: $primary-color;
                border-radius: 50%;
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
              }
              &:nth-last-child(1):after {
                display: none;
              }
            }
          }
          span {
            font-family: $primaryfontRegular;
            font-size: 12px;
            color: $base-color;
          } 
        
        }
      }
      &.active {
        width: 100%;
        background: $white;
      }
    }
    &.jobs-listv2 {
      .job  {
        
        padding: 10px 0 10px 15px;
        .logo {
          min-width: 36px;
          height: 36px;
        }
        .company-title {
          width: calc(100% - 36px);
          padding: 0 0 0 0px;          
          h2 {
            font-family: $primaryfontMedium;
            color: $primary-color;
            font-size: 14px;
            margin-bottom: 0px;
            &.active {
              color: $secondary-color;
            }
          }
          span {
            font-family: $primaryfontRegular;
            font-size: 12px;
            color: #6D6E70;
            display: block;
          }
        }
        .details {
          width: 100%;
          padding: 15px 0 0 0;
          .details-container {
            // p {
            //   background: url('../images/icons/location.svg') no-repeat 0 0;
            //   padding: 0 0 0 15px;
            // }
          }
          // .duration.type {
          //   display: block;
          //   p {
          //     background: url('../images/icons/job') no-repeat 0 0;
          //   }
          // }
        }
      }
    }
  }
  
  @media screen and (max-width:$large) {
    flex-direction: column;
    .jobs-list{
      display: flex;
      flex-direction: row;
      overflow: auto;
      width: 100%;
      .job{
        width: 100%;
        min-width: 300px;
        margin-right: 10px;
      }
    }
    .description{
      width: 100%;
      
      .jobs-data{
        flex-direction: column;
        align-items: center;
      }
      .navigation{
        justify-content: center;
        margin-top: 15px;
      }
      .cards-container {
        flex-direction: column;
        .cards {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.description {
  background-color: $white;
  padding: 20px 25px;
  width: calc(100% - 330px);
  position: relative;
  .tab-content{
    padding-bottom: 25px;
  }
  &.w-100 {
    width: 100%;
  }
  .jobs-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .job-title {
    display: block;
    padding: 0 0 20px 0;
    h4 {
      font-family: $primaryfontLight;
      font-size: 32px;
      color: $primary-color ;
      padding: 0px;
      margin: 0px 0 15px 0;
    }
    .job-location {
      display: flex;
      align-items: center;
      flex-wrap: wrap;          
      line-height: normal;
      background: url('../images/icons/location.svg') no-repeat 0 0;
      padding:0 0 0 15px;
      margin: 0px;
      line-height: 14px;
      span {
        font-family: $primaryfontRegular;
        font-size: 12px;
        color: $primary-color;
        position: relative;
        padding: 0 8px 0 4px;
        &:after {
          content: '';
          width: 3px;
          height: 3px;
          background: $primary-color;
          border-radius: 50%;
          position: absolute;
          right: 0px;
          top: 50%;
          transform: translateY(-50%);
        }
        &:nth-last-child(1):after {
          display: none;
        }
      }
    }
    p {
      font-family: $primaryfontRegular;
      font-size: 14px;
      color: $primary-color;
      padding: 0 0 0 7px;
      margin: 0px;
      span {
        font-family: $primaryfontRegular;
        font-size: 13px;
        color: $primary-color;
        position: relative;
        padding: 0 8px 0 4px;
        &:nth-child(1) {
          padding-left: 0px;
        }
        &:after {
          content: '';
          width: 4px;
          height: 4px;
          background: $primary-color;
          border-radius: 50%;
          position: absolute;
          right: 0px;
          top: 50%;
          transform: translateY(-50%);
        }
        &:nth-last-child(1):after {
          display: none;
        }
      }
    }
  }
  .jobs-details {
    padding-bottom: 20px;
    h2 {
      font-family: $primaryfontRegular;
      font-size: 30px;
      color: $primary-color;
      margin-bottom: 5px;
    }
    label {
      font-family: $primaryfontRegular;
      font-size: 14px;
      color: $primary-color;
      display: block;
      span {
        color: $secondary-color;
      }
    }
  }
  .oval-btn {
    border: 1px solid $base-color;
    padding: 10px 18px;
    border-radius: 20px;
    padding: 0 15px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 10px;
      height: 16px;
      margin-right: 5px;
    }
    label {
      font-family: $primaryfontRegular;
      font-size: 13px;
      color: $primary-color;
    }

  }
  .navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 0px;
    list-style-type: none;
    flex-wrap: wrap;
    margin: 0px;
    li {
      margin: 3px 0;
    }
    .Apply {
      padding: 11px 22px;
      background-color: $secondary-color;
      border-radius: 6px;
      margin-left: 10px;
      font-family: $secondaryfontMedium;
      color: $white;
      font-size: 15px;
    }
    .action {
      width: 40px;
      height: 40px;
      border: 1px solid $base-color;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
    }
  }
  .cards-container {
      display: flex;
    //  flex-wrap: wrap;
      justify-content: space-between;
      margin: 0px -5px 20px;
      .cards {
       // width: calc(25% - 8px);
       width: 100%;
        background-color: $base-color1;
        border-radius: 6px;
        padding: 0px 20px;
        height: 90px;
        align-items: center;
        display: flex;
        margin: 0 5px;
        .cards-content {
          padding: 0px 20px;
        }
        h3 {
          font-family: $primaryfontRegular;
          font-size: 12px;
          color: #8A8989;
          margin-bottom: 3px;
        }
        label {
          font-family: $primaryfontRegular;
          font-size: 15px;
          color: #000000;
          
        }
      }
      @media screen and (max-width:$large) {
        flex-wrap: wrap;
        .cards {
          width: calc(50% - 10px);
          margin: 0 5px 10px;
        }
      }
      @media screen and (max-width:$large) { 
        .cards {
          width: 100%;
        }
      }
  }
  .click-btn {
    width: 100%;
    display: block;
    border-radius: 20px;
    padding: 6px 0px;
    border: 1px solid $base-color2;
    text-align: center;
    font-family: $primaryfontRegular;
    color: $primary-color;
    font-size: 13px;
    margin-top: 10px;
  }
  .messages {
    .messages-content {
      display: flex;
      margin-bottom: 30px;
      .account-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        overflow: hidden;
        margin-right: 10px;
        img {
          width: 100%;
        }
      }
      .text-data {
        width: calc(100% - 50px)
      }
      .attachments {
        padding: 13px 20px;
        background-color: $base-color1;
        border-radius: 6px;
        ul {
          @include listStyling;
          @include flexProperities;
          margin: 10px 0px 0px 0px;
          .file {
            @include flexProperities;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid $border-color;
            background-color: $white;
            margin-right: 10px;
          }
          li {
            @include paragraphText;
            font-size: 15px;
          }
        }
      }
      .bulletpoints {
        margin-bottom: 0px;
        li {
          @include paragraphText;
          font-size: 15px
        }
      }

      h4 {
        @include headingText;
        margin-bottom: 0px;
       
      }
      p {
        @include paragraphText;
        margin-bottom: 0px;
      }
      // &:last-child {
      //   margin-bottom: 0px;
      // }
    }
    .file-transfer {
      padding: 10px 15px;
      background-color: #F7F9FF;
      @include flexProperities;
      justify-content: space-between;
      .input {
        width: 70%;
        background-color: transparent;
        @include paragraphText;
        padding: 5px 10px;
        border: none;
        &:focus-visible {
          outline: none;
        }
      }
      .filecontainer {
        @include flexProperities;
      }
      .attach {
          display: flex;
          height: 17px;
          width: 17px;
          overflow: hidden;
          margin-right: 10px;
          img {
            max-width: 100%;
          }
      }
      .send {
        @include flexProperities;
        width: 40px;
        height: 40px;
        justify-content: center;
        border: 1px solid $border-color;
        border-radius: 6px;
        background-color: $white;
        img {
          max-width: 100%;
        }
      }
    }
  }
 
  .data {
    display: block;
    padding: 0 0 20px 0;
    h5 {
      font-family: $primaryfontRegular;
      font-size: 13px;
      color: $secondary-color;
      margin: 0 0 5px 0;
      padding: 0px;
    }
    p {
      font-family: $primaryfontRegular;
      font-size: 15px;
      color: $primary-color;
      padding: 0px;
      margin: 0 0 10px 0;
      word-break: break-word;
    }
    .bullet-points {
      padding: 0 0 0 25px;
      margin: 0 0 10px 0;
      li{
        font-family: $primaryfontRegular;
        font-size: 15px;
        color: $primary-color;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .filters {
      list-style: none;
      padding-left: 0px;
      display: flex;
      flex-wrap: wrap;
      margin: 0px;
      li {
        background-color: $base-color1;
        padding: 6px 20px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        margin: 0px 10px 10px 0px;
        label {
          font-family: $primaryfontRegular;
          font-size: 14px;
          color: $primary-color;
        }
        span {
          cursor: pointer;
          margin-left: 20px;
        }
      }
    }
  }
  
}

.offcanvas.offcanvas-end {
  width: 1000px;
}
.offcanvas-body {
  padding: 0px !important;
}
.status {
  @include flexProperities;
  justify-content: space-between;
  .status-container {
    display: flex;
    .application-status {
      padding: 0px 30px;
      background-color: #FFFFFF;
      border-radius: 6px;
      display: flex;
      align-items: center;
      height: 50px;
      &.mr-10 {
        margin-right: 10px;
      }
      &.btn-2 {
        background-color: transparent;
        border: 1px solid #FFFFFF;
      }
      img {
        margin-right: 10px;
      }
      label {
        @include headingText;
        font-size: 15px;
        &.label-2 {
          color: #FFFFFF;
        }
      }
    }
  }
}

// .nav-tabs {
//   border-bottom: 0px;
//   .nav-item {
//     button {
//       @include ovalBtn1;
//       // margin-right: 10px;
//     }
//   }
//   .active {
//     background-color: $secondary-color!important;
//     color: $white!important;
//     position: relative;
//     &::after {
//       content: "";
//       top: 100%;
//       left: 50%;
//       border: solid transparent;
//       height: 0;
//       width: 0;
//       position: absolute;
//       pointer-events: none;
//       border-color: rgba(255, 255, 255, 0);
//       border-top-color: $secondary-color;
//       border-width: 4px;
//       margin-left: -4px;
//     }
//   }
//   .nav-link {
//     color: $base-color3;
//   }
// }


.all_jobs {
  .Jobs-list {
    .job-list {
      .details_list_item .matched_profile_sec{
        border-top: 0px;
        background: #fff;
        padding: 0;
      }
    }
  }
}


.messages_icon {
  position: relative;
  em {
    width: 10px; height: 10px;
    background: $secondary-color;
    border-radius: 50%;
    position: absolute;
    right: -5px;
    top: -1px;
  }
}


.job_description_v2 {
  width: 100%;
  .secondary_tabs  {
    position: sticky;
    top: 0;
    background: #fff;
    padding: 10px 0 10px 0px;
}
  .tab-content {
    padding-bottom: 50px;
   
  }
  .jobs-data {
    margin-bottom: 20px;
    .jobs-details {
      padding-bottom: 0;
      h2 {
        font-size: 22px;
        margin-bottom: 0;
      }
      .duration {padding-top: 5px;}
      .job_location {
        display: flex;
        flex-wrap: wrap;
        .duration {
          padding: 0px;
          p {
            padding: 0px 10px 0 0;
            margin: 0px 6px 0 0;
            position: relative;
            &::after {
              content: '';
              width: 4px;
              height: 4px;
              display: block;
              border-radius: 50%;
              background: $primary-color;
              position: absolute;
              right: 0px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          &:nth-last-child(1) p:after {
            display: none;
          }
        }        
      }
    }
    .navigation {
      li {
        margin: 0 0px 0 8px;
        .action  {margin: 0px; cursor: pointer;}
        .oval-btn {
          height: 34px;
        }
      }
    }
  }
}

.marr5 {
  margin-right: 5px;
}