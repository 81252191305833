
@mixin formField {
    width: 100%;
    height: 45px;
    background: $white;
    border-radius: 6px;
    border: 1px solid rgba($base-color , 0.3);
    box-shadow: none;
    outline: none;
    font-family: $primaryfontRegular;
    color: $primary-color;
    font-size: 15px;
}
@mixin formFieldFocus {
    border: 1px solid $base-color;
}
@mixin formLabel {
    display: block;
    font-size: 13px;
    margin-bottom: 3px;
    line-height: 16px;
    font-family: $primaryfontRegular;
    color: rgba($primary-color , 0.7);
}
@mixin primaryBtn { 
    font-family: $secondaryfontMedium;
    font-size: 15px;
    color: $white; 
    display: flex;
    align-items: center;
    justify-content: center;
    background: $secondary-color;
    border: 0px;
    padding: 0 20px;
    margin: 0;
    cursor: pointer;
    &:hover, &:active, &:focus {
        background: $primary-color !important;
        border-color: $primary-color !important;
    };
}

@mixin cancelBtn { 
    font-family: $primaryfontMedium;
    font-size: 15px;
    color: $base-color; 
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 0px;
    padding: 0 20px;
    margin: 0 0 0 10px;
    height: 40px;
    &:hover, &:active, &:focus {
        background: $base-color5;
    };
}



@mixin ovalBtn1 {
    padding: 6px 30px;
    font-family: $primaryfontRegular;
    font-size: 14px;
    border-radius: 20px;
}

@mixin headingText {
    font-family: $primaryfontMedium;
    font-size: 14px;
    color: $primary-color;
}

@mixin paragraphText {
    font-family: $primaryfontRegular;
    font-size: 14px;
    color: $primary-color;
}

@mixin flexProperities {
    display: flex;
    align-items: center;
}

@mixin listStyling {
    padding-left: 0px;
    list-style: none;
}
 
 
@mixin pageTitle {
    font-family: $secondaryfontRegular;
    font-size: 24px;
    color: $primary-color;
    padding: 0px;
    margin: 0px;
}

@mixin formError {
    position: absolute;
    top: 100%;
    left: 0px;
    font-family: $secondaryfontRegular;
    font-size: 12px;
    line-height: 16px;
    padding: 0px;
    margin: 0px;
    color: $error;
}

@mixin joinBtn {
    padding: 0px 39px;
    height: 50px;
    text-decoration: none;
    border-radius: 25px;
    font-family: $basefontBold;
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    @media screen and (max-width:$medium) {
        padding: 0px 20px;
        height: 40px;
        font-size: 14px;
    }
}