@import "includes.scss"; 

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white;
    padding: 0 20px;
    height: 80px;
    position: relative;
    z-index: 999;
    a{
        color: #000;
    }
    .logo {
        a {
            display: block;
            img {
                height: 60px;
            }
            @media screen and (max-width:$belowMedium) {
                img {
                    height: 40px;
                }
            }
        }
    }
    .nav-menu {
        .mobile-menu {display: none;}
        ul { 
            display: flex;
            padding: 0px;
            margin: 0px;
            > li {
                list-style-type: none;
                padding: 0 30px;
                position: relative;
                &.active{
                 a{
                    &::after{
                        content: '';
                        width: 100%;
                        position: relative;
                        bottom: -28px;
                        left:0px;
                        display: block;
                        border-bottom: 3px solid #fff;
                        opacity: 0.6;
                    }

                 }  
                }
                a {
                    font-family: $secondaryfontRegular;
                    font-size: 16px;
                    color: $primary-color;
                    text-decoration: none;                    
                    &.sublist {
                        position: relative;
                        padding: 0 20px 0 0;
                        display: block;
                        width: 100%;
                        &:after {
                            top: 9px;
                            right: 0px;
                            left: inherit;
                            border: solid transparent;
                            content: "";
                            height: 0;
                            width: 0;
                            position: absolute;
                            pointer-events: none;
                            border-color: rgba(138, 137, 137, 0);
                            border-top-color: #8A8989;
                            border-width: 7px;
                            margin-left: -7px;
                        }
                    }
                }
                .submenu {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    background: $white;
                    box-shadow: 0px 0px 15px #0000000D;
                    border-radius: 6px;
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    display: none;
                    padding: 10px 0;
                    li {
                        list-style-type: none;
                        padding: 0; 
                        a {
                            white-space: nowrap;
                            font-size: 14px;
                            color: $primary-color;
                            text-align: center;
                            padding: 5px 0;
                            display: block;
                            
                        }
                        &:hover {
                                background:#fafafa;
                                color: $secondary-color;
                            }
                            &.active {
                                background:#fafafa;
                                color: $secondary-color;
                            }
                    }
                }
                &:hover {
                    > a {color: $secondary-color;
                        &.sublist:after {
                            border-top-color:$secondary-color;
                        }
                    }
                    .submenu {display: block;}
                }
                &.active {
                    > a {color: $secondary-color;
                        &.sublist:after {
                            border-top-color:$secondary-color;
                        }
                    }                    
                }
                
            }
        }
        @media screen and (max-width:$medium) { 
            .mobile-menu {
                width: 30px;
                height: 30px;
                display: block;
                position: absolute;
                right: 6px;
                top: 15px;
                background: url('@/../../../assets/images/mobile-menu.png') no-repeat center;
                background-size: 25px auto;
                &.menu_open {
                    background: url('@/../../../assets/images/menu-close.png') no-repeat center;
                    background-size: 25px auto;
                }
            }
            ul {
                position: absolute;
                right: 0px;
                top: 100%;
                background: #fff;
                width: 100%;
                display: none;
                box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
                li {
                    text-align: left;
                    padding: 7px 10px;
                    a {color: $primary-color !important;}
                    .submenu {
                        position: static;
                        display: block;
                        box-shadow: none;
                        background: #fafafa;
                        padding: 10px 20px;
                        li {
                            background: transparent;
                            a {
                                text-align: left;
                            }
                        }
                    }
                }
                &.menu-open {
                    display: block;
                    padding: 0 10px;
                    li {
                        width: 100%;
                        margin:5px 0;
                        border-radius: 6px;
                        &:hover:not(:last-child) {
                            background: rgba(0, 112, 146, 0.7);
                            a {
                                color: $white !important;
                            }                         
                        }
                        a {
                            display: block;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .header-actions {
        ul { 
            display: flex;
            padding: 0px;
            margin: 0px;
            align-items: center;
            li {
                list-style-type: none;
                padding: 0 15px;
                a {
                    text-decoration: none;
                }
                .profile {
                    width: 40px;
                    height: 40px;
                    //border: 1px solid #1010101A;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0px;
                    overflow: hidden;
                    img {
                        display: block;
                        max-width: 100%;
                    }
                }
            }
        }
        @media screen and (max-width:$medium) {
            padding-right: 30px;
            ul li {
                padding: 0 10px;
            }
        }
    }
    @media screen and (max-width:$large) {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        right: 0;
        height: auto;
        padding: 10px 20px;
        box-shadow: 0px 4px 7px rgba($black , 0.1);
    }
    @media screen and (max-width:$small) {
        padding: 10px;
    }
}

.hover-menu {
    position: relative;

    .hover-icon {
        cursor: pointer;
    }

    .menu-list {
        display: none;
        position: absolute;
        top: 100%;
        right: 0px;
        background: $white;
        box-shadow: 0px 0px 10px #00000026;
        border-radius: 6px;
        padding: 10px 0;

        li {
            font-family: $primaryfontRegular;
            font-size: 14px;
            padding: 5px 15px !important;
            color: $primary-color;
            white-space: nowrap;
            text-align: right;
            display: block;
            cursor: pointer;

            &:hover {
                color: $secondary-color;
            }
        }
    }

    &:hover .menu-list {
        display: block;
    }
}

.profile-modal {
    .modal-content {
        .modal-header {
            padding: 0;
            margin: 0;
            border: 0;
            .btn-close {
                padding: 0px;
                position: absolute;
                top: 0px;
                right: 0px;
                background-color: #fff;
                opacity: 1;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background-size: 12px;
                z-index: 99;
            }
        }
        .edit_btn {
            font-size: 14px;
            font-family: $primaryfontRegular;
            background: $white;
            color: $primary-color; 
            padding: 0 10px;
            height: 22px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            svg {
                padding-right: 5px;
                path {
                    fill: $primary-color;
                }
            }
        }
        .modal-body {
            padding: 20px !important;
        }
    }
}
.basic_info {
    background: transparent linear-gradient(99deg, rgba(#F15B28 , 0.8) 0%, rgba(#F1A428 , 0.8) 100%) 0% 0% no-repeat padding-box;
    width: 100%;
    padding: 20px;
    border-radius: 6px 6px 0 0;
    h4{
        font-family: $secondaryfontMedium;
            font-size: 18px;
            color: $primary-color;
            display: flex;
            justify-content: space-between;
            align-items: center;
    }
}


.profile_info {
    display: flex;
    figure {
        width: 75px;
        padding: 0px;
        margin: 0px;
        img {
            width: 100%;
        }
    }
    .profile_details {
        width: calc(100% - 75px);
        padding: 0 0 0 15px;
        h5 {
            font-family: $primaryfontBold;
            font-size: 24px;
            color: $white;
            padding: 0px;
            margin: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        p {
            font-family: $primaryfontRegular;
            font-size: 14px;
            color: $white;
            padding: 0 0 0 22px;
            margin: 0 0 3px 0;
            &.email { background: url('../images/icons/mail-white.svg') no-repeat left center;}
            &.phone { background: url('../images/icons/smartphone-white.svg') no-repeat left center;}
        }
    }
    .swiper {
        width: 100px;
        min-width: 100px;
        figure {
            width: 100% !important;
            border-radius: 0 !important;
            display: flex;
            align-items: center;
            img {
                min-height: inherit;
            }
        }
    }
}

.community-details {
    h4 {
        font-family: $secondaryfontMedium;
        font-size: 18px;
        color: $primary-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .edit_btn {
            background-color: #f47f53 !important;
            color: $white !important;
            svg path {
                fill: $white !important;
            }
        }
    }

}
.community-info {
    display: flex;
    align-items: flex-start;
    padding: 5px 0 0 0;
    figure {
        padding: 0;
        margin: 0px;
        width: 50px;
        img {
            display: block;
            width: 100%;
        }
    }
    .profile_details {
        width: calc(100% - 50px);
        padding: 0 0 0 10px;
        h5 {
            font-family: $primaryfontMedium;
            font-size: 16px;
            color: $primary-color;
            padding: 0 0 4px 0;
            margin: 0;
        }
        p {
            font-family: $primaryfontRegular;
            font-size: 14px;
            color: $primary-color;
            padding: 0px;
            margin: 0px;
            &.address {
                background: url('../images/icons/location.svg') no-repeat 0 3px;
                padding-left: 18px;
            }
        }
    }
}

@media screen and (max-width:$large) {
//    body { padding-top: 80px;}  
}
@media screen and (max-width:$medium) {
    // body { padding-top: 60px;}  
}

.profile-info-sec {
    display: block;  
    padding-bottom: 30px;
    h4 {
        font-family: $primaryfontMedium;
        font-size: 14px;
        color: $secondary-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .edit_btn {
            color: $primary-color;
            font-size: 14px;
            font-family: $primaryfontMedium;
            cursor: pointer;
            opacity: 0.7;
            svg {
                margin-right: 4px;
                path {
                    fill: $primary-color;
                }
            }
            &:hover {
                opacity: 1;
            }
        }        
    }
    .profile_info {
        border: 1px solid $border-color;
        padding: 15px;
        background: $white;
        figure {
            padding: 0;
            margin: 0px;
            width: 75px;
            height: 75px;
            border-radius: 50%;
            overflow: hidden;
            img {
                display: block;
                width: 100%;
                
            }
        }
        .profile_details {
            width: calc(100% - 75px);
            padding: 0 0 0 10px;
            label {
                font-family: $primaryfontMedium;
                font-size: 16px;
                line-height: 18px;
                color: $primary-color;
                padding: 0 0 4px 0;
                margin: 0;
            }
            p {
                font-family: $primaryfontRegular;
                font-size: 14px;
                color: $primary-color;
                padding: 0px;
                margin: 0px;
                svg {
                    min-width: 25px;
                     
                }
                &.address {
                    background: url('../images/icons/location.svg') no-repeat 0 3px;
                    padding-left: 18px; 
                }
                &.phone {
                    background: url('../images/icons/phone.svg') no-repeat 0 3px;
                    padding-left: 18px;
                }
            }
        }
    }
    &.single {
        padding-bottom: 15px;
        .profile_info {border: 0px;}
    }
    &.community_info_sec {
        .profile_info {
            border: 0px;
            padding: 15px 0px;
        }
        .description {
            width: 100%;
            padding: 0;
            figure {
                margin: 0 0 20px;
                img {
                    height: 100%;
                }
            }
            h5 {
                margin: 0;
                padding: 0px!important;
            }
            .data {
                .filters {
                    li {
                        margin: 5px 10px 10px 0;
                    }
                }
                &:last-child {
                    padding-bottom: 0px;
                }
            }
            .swiper {
                padding: 0 0 20px 0;
                .swiper-wrapper .swiper-slide {
                  height: 100%;
                  width: auto;
                  figure {
                    margin: 0px;
                    img {
                      height: 100%;
                      width: auto;
                    }
                  }
                }
              }
        }
    }
}