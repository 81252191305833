
@import "includes.scss";  
@import "buttons.scss";

.dashboard_page {
  background: #F9FAFB;
  padding-bottom: 50px;
  &.talent_dashboad .container {
    max-width: 1140px;
  } 
  .nodatafoundtext {
    background-size: 130px; 
    align-items: flex-end;
    background-position:  center 20px ;
    padding-bottom: 25px;
    min-height: 165px;
    font-size: 16px;   
    padding-top: 0px;
    &.custom-msg {
      max-width: 294px;
      margin: 0 auto;
      text-align: center;
      min-height: 180px;
    }
  }
  .nodataTemplate {
    .accordian-table {
      table tr {
        display: none;
        height: 40px;
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          display: table-row;
        }
      }
    }
  }
  @media screen and (max-width:$medium) {
    padding-top: 70px;
  }
}
.dashboard_cnt {
  padding-top: 0px;
  padding-bottom: 50px;
}

.db-header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
      max-width: 183px;
      max-height: 50px;
    }
    ul {
      display: flex;
      align-items: center;
      list-style-type: none;
      padding: 0px;    
      margin: 0px;  
      li {
        margin-right: 10px;
        .form-group {
          margin-bottom: 0px;
          width: 300px;
          .search {
            width: 100%;
          }
          &.simpleselect_container {
            width: 200px;
          }
        }
        h3 { 
          font-family: $basefontMedium;
          color: $primary-color;
          font-size: 36px;
          padding:0 10px 0 0;
          margin: 0px;
        }
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    .badge {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0px;
      span {
        font-family: $neutralfontMedium;
        color: $primary-color;
        font-size: 12px;
        text-align: right;
        font-weight: normal;
        padding-right: 7px;
      }
    }
    .form-group {
      margin-bottom: 0px;
      width: 300px;
      .search {
        width: 100%;
      } 
    }
    .analysis{
      margin-right: 10px;
    }
  }
  @media screen and (max-width:$medium) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
    height: auto;
    .right {
      width: 100%;
      justify-content: space-between;
    }
  }
}

.emp_db_cnt {
  display: flex;   
  justify-content: space-between;
  width: 100%;
  border: 1px solid rgba($primary-color , 0.1);
  border-radius: 6px;
  background: $white;
  margin-bottom: 20px;
  .emp_db_left {
    width: 260px;
    display: block;
    background: $primary-color;
    border-radius: 6px 0 0 6px;
    
    .emp_db_titles {
      padding: 20px 0 0 0;
      h5 {
        color: #55D2FE;
        font-family: $basefontBold;
        font-size: 18px;
        line-height: 21px;
        border-left: 3px solid #55D2FE;
        padding: 0 0 0 13px;
        margin-bottom: 10px;
      }
      ul {
        padding: 0 0 0 13px;
        list-style-type: none;
        li {
          margin:2px 0;
          a {
            padding: 10px 0 10px 15px;
            font-family: $neutralfontSemiBold;
            font-size: 14px;
            color: rgba($white , 0.8);
            margin: 0px;
            border-radius: 6px 0 0 6px;
            display: block;
            cursor: pointer;
            &:hover {
              background: $white;
              color: $primary-color;
            }
          }
          &.active a {
            background: $white;
            color: $primary-color;
          }
        }
      }
      @media screen and (max-width:$medium) {
        ul {
          display: flex;
          overflow: auto;
          li {
            a {
              height: 40px;
              padding: 0 15px;
              display: flex;
              align-items: center;
              border-radius: 6px;
            }
          }
        }
      }
    }
  }

  .emp_db_right {
    width: calc(100% - 260px);
    padding: 20px;
    .db_right_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      .emp-title {
        display: flex;
        align-items: center;
        span.dp {
          width: 60px;
          height: 60px;
          border: 1px solid #E2E2E2;
          border-radius: 50%;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          img {
            width: 100%;
          }
        }
        h4 {
          font-family: $primaryfontMedium;
          font-size: 20px;
          color: $primary-color;
        }
        ul {
          display: flex;
          align-items: center;
          padding: 0px;
          margin: 0px;
          list-style-type: none;
          li {
            margin: 0 10px 0 0;
            span {
              background: #E6F9FF;
              padding: 0 15px;
              border-radius: 14px;
              font-family: $neutralfontMedium;
              font-size: 12px;
              color: $primary-color;
              display: flex;
              align-items: center;
              height: 25px;
              svg {
                display: block;
                margin: 0 5px 2px 0;
              }
            }
            label {
              color: #6D6E70;
              font-family: $neutralfontMedium;
              font-size: 12px;
              padding: 0px;
              margin: 0px;
              display: flex;
              align-items: center;
              height: 25px;
              svg {
                display: block;
                margin: 0 5px 2px 0;
              }
            }
          }
        }
        @media screen and (max-width:$small) {
          h4 {
            font-size: 16px;
          }
        }
      }
      .emp_actions {
        display: flex;
        align-items: center;
        .status {
          font-family: $neutralfontMedium;
          font-size: 16px;
          color: $primary-color;
          padding: 0px 0 0 24px;
          margin:0 0px 0 0;
          background: url('../images/tick.svg') no-repeat left center;
        }
        .more-menu {
          padding-right: 0;
          margin-left: 20px;
          .dropdown {
            .btn-secondary {
              width: 25px;
              height: 36px;
              background-color: #E6F9FF;
              border: 0px;
            }
          }
        }
      }
    }
    .emp_db_main {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .db_left_cnt {
        width: calc(100% - 240px);
        padding: 0 10px 0 0;
        .emp_counter {
          width: 100%;
          padding-bottom: 10px;
          ul {
            padding: 0px;
            list-style-type: none;
            display: flex;
            margin: 0 -5px;
            li {
              width: 33.3%;
              display: block;
              padding: 0 5px;
            }
          }
        }
        .skills_sec {
          display: flex;
          //align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 10px;
          .skill_block {
            width: calc(50% - 10px);            
            border: 1px solid #F5F5F5;   
            height: 230px;    
            .chart_cnt.vertical_chart_cnt ul {
              height: calc(100% - 20px);
              &.spacing {
                padding-top: 17px;
              }
            }     
            .vertical_chart_item .chart_value_cnt {
              height: calc(100% - 34px);
            }
            &.locations_block {
              height: auto;
              .chart_item .chart_name {
                line-height: 16px;
              }
            }
          }
        }
      }
      .emp_db_events {
        width: 240px;
        .events__block {          
          .swiper {
            padding-bottom: 30px;
            .event_card {
              border: 0px;
            }          
            .swiper-pagination {
              bottom: 10px;
              .swiper-pagination-bullet {
                border: 1px solid $secondary-color;
                &.swiper-pagination-bullet-active {
                  background: $secondary-color;
                }
              }
            }
          }          
        }
      }
    }
  }
  @media screen and (max-width:$extraLarge) {
    .emp_db_right .db_left_cnt {
      .skills_sec {
        flex-wrap: wrap;
        .skill_block {
          width: 100% !important;
          margin-bottom: 20px;
        }
      }
    }
  }
  @media screen and (max-width:$large) {
     
    .emp_db_main {
      flex-direction: column;
      .db_left_cnt {
         width: 100% !important;
      }
      .emp_db_events {
        width: 100% !important;
      } 
    }
  }
  @media screen and (max-width:$medium) {
    flex-direction: column;
    .emp_db_left {
      width: 100%;
    }
    .emp_db_right {
      width: 100%;
      padding: 0px;
      .db_right_head {
        padding: 10px;
        align-items: flex-start;
        flex-direction: column;
        .emp_actions {
          padding: 10px 0 0 60px;
        }
      }
      .emp_db_main {
        flex-direction: column;
        .db_left_cnt {
          width: 100%;
          padding: 0 10px;
          .emp_counter {
            ul {
              flex-flow: wrap;
              li {
                width: 50%;
                padding: 0 5px 10px;
              }
            }
          }
          .skills_sec {
            flex-wrap: wrap;
            padding-bottom: 20px;
            .skill_block {
              width: 100%;
              margin-bottom: 15px
            }
          }
        }
        .emp_db_events {
          width: 100%;
          padding: 0 10px 10px;
        }
      }
    }
  }
  &.emp_db_cnt_mobile {
    @media screen and (max-width:$medium) {
      border-top: 0px;
      .emp_db_left {
        border-radius: 0;
        .emp_db_titles {
          display: none;
          padding: 10px 0 0 0;
          ul {
            margin-bottom: 0;
            padding: 0;
            li {
              margin: 0;
              padding: 0 10px;
              white-space: nowrap;
              &.active {
                a {
                  border-radius: 6px 6px 0 0;
                }
              }
            }
          }
          h5 {
            display: none;
          }
          &.show {
            display: block;
          }
        }
      }
    }
  }
}
.offcanvas-body   .emp_db_right {
  width: calc(100%);
}
.count-item {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E6F9FF;
  border-radius: 6px;
  p {
    strong {
      display: block;
      font-family: $neutralfontSemiBold;
      font-size: 24px;
      color: $primary-color;
    }
    font-family: $neutralfontMedium;
    font-size: 14px;
    color: #6D6E70;
    padding: 0px;
    margin: 0px;
    text-align: center;
  }
  @media screen and (max-width:$medium) {
    height: 75px;
    p {
      font-size: 12px;
      strong {
        font-size: 18px;
      }
    }
  }
}

.events_block {
  width: 100%; 
  display: block; 
  h4 {
    font-family:$neutralfontMedium;
    font-size: 14px;
    line-height: 18px;
    color: $secondary-color;
    padding: 0px;
    margin: 0px; 
    display: flex;
    align-items: center;
    padding: 0 0 0 0px;
     
  }
  .swiper {
    padding: 30px 0px 60px;
    .slider_item {
    display: block;
    text-align: center;
    padding: 0 15px;
    span {
      height: 26px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      font-family: $basefontMedium;
      font-size: 14px;
      border: 1px solid rgba(#E6F9FF , 0.5);
      border-radius: 16px;
      margin: 0 auto 15px;
      color: $white;
    }
    label {
      display: block;
      font-family: $neutralfontBold;
      font-size: 16px;
      line-height: 20px;
      color: $white;
      padding: 0px;
      margin: 0px 0 10px 0;
    }
    em {
      display: block;
      font-family: $neutralfontSemiBold;
      font-size: 14px;
      line-height: 17px;
      color: $white;
      padding: 0px;
      margin: 0px 0 25px 0;
      font-style: normal;
    }
    .slide_actions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .btn {
        width: 80px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        font-family: $basefontBold;
        font-size: 16px;
        color: $white;
        border: 1px solid $white;
        margin: 0 5px 10px;
        &:hover {
          background: $white;
          color: $primary-color;
        }
      }
    }
    }
    .swiper-pagination {
      display: flex;
      justify-content: center;
      bottom: 20px;
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        border: 1px solid $white;
        background: transparent;
        opacity: 1;
        &.swiper-pagination-bullet-active {
          background: $white;
        }
      }
    }
  }
  .events_head {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 10px;
    .viewall {
      font-family: $basefontMedium;
      font-size: 14px;
      color: $secondary-color;
      text-decoration: none;
      &:hover {
        color: $primary-color;
      }
    }
  }
  .events__block {
    width: 100%;
    background: $white;
    display: block;
    border-radius: 6px;
    padding-bottom: 20px;
    border: 1px solid rgba($secondary-color , 0.33);
    figure {
      width: 100%;
      display: block;
      padding: 0px;
      margin: 0px;
      position: relative;
      background: #fafafa;
      height: auto;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
      span {
        position: absolute;
        right: 10px;
        bottom: 0px;
      }
    }
    .swiper {
      padding: 0px;
    }
    .slider_item {
      text-align: left;
      .event_labels {
        display: flex;
        align-items: center;
        padding: 10px 0;
        span { 
          background: #E6F9FF;
          padding: 0 10px;
          border-radius: 16px;
          display: flex;
          align-items: center;
          color: $primary-color;
          font-size: 13px;
          font-family: $basefontMedium;
          padding: 0px 15px 0 30px;
          margin: 0px 5px 0 0;
          border: 0px;
          height: 25px;
          &.virtual {
            background: url('../images/icons/virtual.svg') no-repeat 10px center #E6F9FF;
          }
          &.physical {
            background: url('../images/icons/physical.svg') no-repeat 10px center #E6F9FF;
          }
          &.paid {
            background: url('../images/icons/paid.svg') no-repeat 15px 6px #E6F9FF;
          }
          &.free {
            background: url('../images/icons/free.svg') no-repeat 15px 6px #E6F9FF;
          }
        }
       
      } 
     label {
      color: $primary-color;
      font-family: $neutralfontMedium;
      font-size: 16px;
      color: #0D0D0D;
     }
     em.date {
      background: transparent; 
      border-radius: 0px;
      display: flex;
      align-items: center;
      color: #007191;
      font-size: 13px;
      font-family: $basefontMedium;
      padding: 0px 10px 0 25px;
      margin: 0px 0 10px 0;
      background: url('../images/icons/calendar.svg') no-repeat 0 0;
    }
     .slide_actions {
      justify-content: space-between;
      .btn {
        height: 32px;
       // min-width: 80px;
        padding: 0 10px;
        color: $primary-color;
        font-family: $primaryfontMedium;
        font-size: 12px;
        line-height: 13px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white !important;
        border: 1px solid #007192;
        margin: 0px;
        width: calc(50% - 5px);
        &:hover {
          color: $primary-color;
        }
        &.join {
          background: #007192 !important;
          color: $white;
          text-decoration: none;
          width: 100%;
          margin-bottom: 6px;
          &:hover {
            color: $white;
          }
        }
        &.mark_intrst {
          line-height: 12px;
        }
      }
     }
    }
  }
}

.db-events_sec {
  .db-events_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    h5 {
      font-family: $basefontMedium;
      font-size: 18px;
      line-height: 24px;
      padding: 0px;
      color: $primary-color;
      margin: 0px;
    }
    a {
      font-family: $basefontMedium;
      font-size: 14px;
      line-height: 24px;
      padding: 0px;
      color: $secondary-color;
      margin: 0px;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: $primary-color;
      }
    }
  }
  .db-events_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    .db-event_item {
      width: 25%;
      padding: 0 10px 20px;
      @media screen and (min-width: $small) and (max-width: $large) {
        width: 50%;
      }
    }
    @media screen and (max-width:$small) {
      .db-event_item {
        width: 100%;
      }
    }
  }
  .view_communities {
    width: 100%;
    height: 50px;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-family: $primaryfontMedium;
    font-size: 16px;
    color: $primary-color;
    text-decoration: none;
    cursor: pointer;
  }
}

.event_card {
  width: 100%;
  display: block;
  border: 1px solid #F2F2F2;
  border-radius: 6px;
  overflow: hidden;
  figure {
    width: 100%;
    padding: 0px;
    margin: 0px;
    height: 160px;
    overflow: hidden;
    img {
      width:100%; 
      display: block;
      margin: 0 auto;
    }
  }
  .event_info {
    padding: 20px;
    display: block;
    background: $white;
    width: 100%;    
    h6 {
      font-family: $neutralfontSemiBold;
      font-size: 16px;
      line-height: 18px;
      color: $primary-color;
      padding: 0px;
      margin: 0px 0 10px 0;
    }
    .event_labels {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;
      label {
        background: #E6F9FF;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 15px;
        font-family: $neutralfontMedium;
        font-size: 12px;
        color: $primary-color;
        border-radius: 16px;
        margin: 0 10px 0 0px;
        img {
          display: block;
          margin: 0 4px 2px 0;
        }
      }
      span {
        color: #6D6E70;
        font-family: $neutralfontMedium;
        font-size: 12px;  
        display: flex;
        align-items: center;
         svg {
          margin: 0 5px 2px 0; 
         }
       
      }
    }
    .event_actions {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;
      a {
        text-decoration: none;
      }
      .btn {
        min-width: 80px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        font-family: $basefontBold;
        font-size: 16px;
        color: $primary-color;
        border: 1px solid #007192;
        margin: 0 5px;
        padding: 0 15px;
        &:hover {
          background: #007192;
          color: $white;
        } 
        &.join {
          background: #007192;
          color: $white;
          text-decoration: none;
          &:hover {
            background: $white;
            color: $primary-color;
          }
        }
      }
    }
  }
  .event_info_v2 {
    padding: 0 15px 10px;
    .event_labels {
      display: flex;
      align-items: center;
      padding: 10px 0;
      margin-bottom: 5px;
      span { 
        background: #E6F9FF;
        padding: 0 10px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        color: $primary-color;
        font-size: 13px;
        font-family: $basefontMedium;
        padding: 0px 15px 0 30px;
        margin: 0px 5px 0 0;
        border: 0px;
        height: 25px;
        &.virtual {
          background: url('../images/icons/virtual.svg') no-repeat 10px center #E6F9FF;
        }
        &.physical {
          background: url('../images/icons/physical.svg') no-repeat 10px center #E6F9FF;
        }
        &.paid {
          background: url('../images/icons/paid.svg') no-repeat 15px 6px #E6F9FF;
        }
        &.free {
          background: url('../images/icons/free.svg') no-repeat 15px 6px #E6F9FF;
        }
      }
      .follower {
        font-family: $neutralfontMedium;
        font-size: 13px;
        color: #6D6E70;
        font-style: normal;
        padding: 0 0 0 17px;
        margin: 0 0 0 5px;
        background: url('../images/icons/follower.svg') no-repeat center left;
      }
    } 
    h6 {
      color: $primary-color;
      font-family: $neutralfontMedium;
      font-size: 16px;
      color: #0D0D0D;
    }
    em.date {
      background: transparent; 
      border-radius: 0px;
      display: flex;
      align-items: center;
      color: #007191;
      font-size: 13px;
      font-family: $basefontMedium;
      padding: 0px 0px 0 25px;
      margin: 0px 0 20px 0;
      background: url('../images/icons/calendar.svg') no-repeat 0 0;
      font-style: normal;
    }
    .event_actions {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 0px;
      .btn {
        height: 32px;
        min-width: 80px;
        padding: 0 10px;
        color: $primary-color;
        font-family: $primaryfontMedium;
        font-size: 12px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white !important;
        border: 1px solid #007192;
        white-space: nowrap;
        margin-bottom: 10px;
        &:hover {
          color: $white;
          background: #007192 !important;
        }
        // &.join {
        //   background: #007192 !important;
        //   color: $white;
        //   &:hover {
        //     color: $white;
        //   }
        // }
      }
      &.event_actions_v2 {
        .btn {
          width: calc(50% - 10px);
          padding: 0;
          justify-content: center;
          font-size: 13px;
          &:hover {
            background: $white !important;
            color: #072430 !important;
          }
          &.join {
            background: #007192 !important;
            color: #ffffff !important;
            width: 100%;
            justify-content: center;
          }
          @media screen and (max-width:$large) {
            width: 100%;
          } 
        }
      }
    }
    }
}

/******************  Community Dashboard ****************/
.cd_banner {
  background: $primary-color;
  padding: 40px 0; 
  .cd_banner_cnt {
    display: flex;
    align-items: center;    
    justify-content: space-between;
    .cd_banner_left {
      display: flex;
      align-items: center; 
      max-width: 50%;
      .no_slider {
        display: block;
        margin: 0px 20px 0 0;
        width: 100px;
        height: 100px;
        min-width: 100px;
        background: transparent;
        // border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px;
        img {
          display: block;
          max-width: 200px;
          // height: auto;
          // width: calc(100% - 20px);
          width: 100%;
          height: 100%;
          margin: 0 auto;
        }
        .without_img {
          min-height: 100%;
        }
      }
      h4 {
        font-family: $neutralfontSemiBold;
        font-size: 20px;
        line-height: 29px;
        color: $white;
        margin-bottom: 10px;
      }
      label {
        background: #55D2FE66;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 15px;
        font-family: $neutralfontSemiBold;
        font-size: 12px;
        color: $white;
        border-radius: 16px;
        margin: 0 15px 0 0px;
        svg {
          display: block;
          margin-right: 5px;
        }
      }
      span.follower {
        color: $white;
        font-family: $neutralfontSemiBold;
        font-size: 12px;  
        display: flex;
        align-items: center;
        padding: 0 0 4px 0;
        margin-top: 4px;
        svg {
          margin: 0 5px 0 0;
          display: block;
        }
      }
      .rectangle {
        width: 150px;
      }
      .swiper {
       min-width: 100px;
       width: 100px;
       margin-right: 15px;
      // background: rgba($white , 0.15);
        .swiper-wrapper {
          
          width: 100%;
          figure {
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
    .cd_banner_right {
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0px;
        margin: 0px;
        list-style-type: none;
        li {
          margin: 0 0 0 10px;
          .acion_btn {
            height: 45px;
            min-width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 20px;
            font-family: $basefontBold;
            font-size: 18px;
            color: $white;
            text-decoration: none;
            border-radius: 6px;
            cursor: pointer;
            &.btn {
              text-decoration: none;
            }
            &.join {
              background: #007192;
            }
            &.follow {
              border: 1px solid rgba($white , 0.6);
            }
            &.share {
              background: rgba($white , 0.15);
            }
          }
          .more-menu {
            .dropdown .dropdown-toggle {
              background: url('../images/icons/menu_dots.svg') no-repeat center;
              border: 0px;
              opacity: 1;
              background-size: auto 100%;
              padding: 0;
              height: 30px;
            }  
          }
        }
      }
    }
  }
  @media screen and (max-width:$large) {
    padding-top: 120px;
  }
  @media screen and (max-width:$medium) {
    padding: 25px 0;
    padding-top: 85px;
    .cd_banner_cnt {
      flex-direction: column;
      align-items: flex-start;
      .cd_banner_left {
        max-width: 100%;
      }
      .cd_banner_right {
        ul {
          padding-top: 20px;
          li {
            margin: 0 8px 0 0;
            .invite_btn {
              padding: 0 10px;
              font-size: 12px;
              height: 36px;
            }
            &:nth-last-child(1) {
              margin: 0;
              .more-menu {
                padding-right: 0;
                width: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.cd_nav_menu {
  width: 250px;
  display: block;
  background: $white;
  position: sticky;
  top: 10px;
  ul {
    display: block;
    padding: 10px;
    margin: 0px;
    list-style-type: none;
    li {
      padding-bottom: 10px;
      a {
        display: flex;
        align-items: center;
        border-radius: 6px;
        height: 36px;
        text-decoration: none;
        font-family: $neutralfontMedium;
        font-size: 14px;
        color: $primary-color;
        span {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 10px 0 0;
          border: 1px solid #E6F9FF;
          border-radius: 6px; 
          i {
            background: $white;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px; 
            img {
              display: block;
            }
          }
        }          
        &:hover {
          background: #E6F9FF;         
          color: #007192;    
        }
      }
      &.active {
        a {
          background: #E6F9FF;         
          color: #007192; 
        }
      }
    }
  }
}
.cd_body_sec {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;  
  .cd_body_cnt {
    display: block;
    width: calc(100% - 550px);    
    padding: 33px 30px; 
    .quick_action {
      width: 100%;
      display: block;
      .quick_action_btn  {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 45px;
        background: transparent linear-gradient(93deg, #FFEEB7 0%, #FFF9B7 100%) 0% 0% no-repeat padding-box;
        border-radius: 40px;
        p {
          font-family: $neutralfontMedium;
          font-size: 15px;
          color: $primary-color;
          padding: 0px;
          margin: 0px 10px 0 0;
        }
        &.renewal {
          background: transparent linear-gradient(93deg, #FFD3D3 0%, #FFD9D9 100%) 0% 0% no-repeat padding-box;
          .renewal_btn {
            background: #FF7C7C;
            height: 31px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 15px;
            border-radius: 40px;
            font-family: $basefontMedium;
            font-size: 15px;
            color: $white;
            border: 0px;
            outline: none;
            margin: 0 0 0 10px;
          }
        }
      }
    }
    &.no_right_bar {
      width: calc(100% - 250px);
      padding: 20px 0 0 20px;
    }
    &.db_events {
      .events_wrap .evernt-cards {
        width: 33.3%;        
      }
      .page_wrapper {
        display: block;
      }
    }
    .sb_profile .sb_profile_cnt .profile-tabs .nav-tabs li {
      margin: 0 3px;
    }
  }
  .cd_job_seekers {
    .job_seekers_list {
      display: block;
      ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0px;
        list-style-type: none;
        margin: 0 -5px;
        li{
          width: 33.3%;
          padding: 0 5px;
           .job_seekers {
            height: 100%;
           }
          
        }
      }
    }
  }
  .title_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h6 {
      display: block;
      font-family: $basefontMedium;
      font-size: 16px;
      color: $primary-color;
      padding: 0px;
      margin: 0px;
    }
    a {
      display: block;
      font-family: $basefontMedium;
      font-size: 14px;
      color: $secondary-color;
      padding: 0px;
      margin: 0px;
      text-decoration: none;
      &:hover {
        color: $primary-color;
      }
    }
  }
  .cd_body_right {
    width: 300px;
    padding-top: 30px;
    .right_block {
      display: block;
      margin: 0 0 30px 0;
      
      ul.tags {
        display: flex;
        flex-wrap: wrap;
        padding: 0px;
        margin: 0 -3px;
        li {
          height: 30px;
          display: flex;
          align-items: center;
          padding: 0 15px;
          margin: 0 3px 6px;
          border: 1px solid #6D6E7027;
          background: $white;
          font-family: $neutralfontMedium;
          font-size: 12px;
          color: #6D6E70;
          border-radius: 24px;
        }
      }
      .events__block {
        .swiper {
          padding-bottom: 30px;
          .event_card {
            border: 0px;
          }          
          .swiper-pagination {
            bottom: 10px;
            .swiper-pagination-bullet {
              border: 1px solid $secondary-color;
              &.swiper-pagination-bullet-active {
                background: $secondary-color;
              }
            }
          }
        } 
      }
    }
  }
  @media screen and (max-width:$large) {
    flex-direction: column;
    .cd_nav_menu {
      width: 100%;
      margin-bottom: 10px;
      ul {
        display: flex;
        overflow: auto;
        padding: 10px 0 0 10px;
        li {
          padding-bottom: 0;
          padding-right: 10px;
          a {
            padding-right: 10px;
          }
        }
      }
    }
    .cd_body_cnt {
      width: 100%;
      padding: 33px 0px;
      .cd_companies {
        .swiper {
          .swiper-wrapper {
            .swiper-slide {
              width: 100% !important;
              &:nth-child(2) {
                display: none;
              }
            }
          }
        }
      }
      .cd_job_seekers {
        .job_seekers_list {
          ul {
            li {
              width: 50%;
              padding: 0 5px 10px;
              &:nth-child(3) {
                display: none;
              }
            }
          }
        }
      }
      .cd_charts_sec {
        flex-wrap: wrap;
        .cd_chart {
          width: 100%;
          margin-bottom: 20px;
        }
      }
      &.no_right_bar {
        width: 100%;
        padding: 0;
      }
    }
    .cd_body_right {
      width: 100%;
    }
  }
}
.tags-list {
  padding: 15px;
  .title {
    @include headingText;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    margin: 0 -3px;
    li {
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      margin: 0 3px 6px;
      border: 1px solid #6D6E7027;
      background: $white;
      font-family: $neutralfontMedium;
      font-size: 12px;
      color: #6D6E70;
      border-radius: 24px;
    }
  }
}

.cd_company {
  background: $white;
  border: 1px solid #00719233;
  border-radius: 6px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 15px;
  cursor: pointer;
  figure {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #0724301A;
    border-radius: 50%;
    padding: 0px;
    margin: 0px 0px 0 0;
    overflow: hidden;
    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
  }
  h6 {
    font-family: $neutralfontSemiBold;
    font-size: 15px;
    color: $primary-color;
    padding: 0px;
    margin: 0 0 5px 0;
  }
  address {
    font-family: $neutralfontMedium;
    font-size: 12px;
    color: #6D6E70;
    padding: 0px 0 0 16px;
    margin: 0 0 0px 0;
    background: url('../images/icons/location.svg') no-repeat center left;
    background-size: auto 12px;
  }
  .cd_company_info {
    display: block;
    width: calc(100% - 60px);
    padding: 0 0 0 15px;     
    span {
      font-family: $neutralfontMedium;
      font-size: 12px;
      color: $secondary-color;
      padding: 0px 0 0 0px;
      margin: 0 0 0px 0;  
      white-space: nowrap;
      width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.job_seekers {
  background: $white;
  border: 1px solid #00719233;
  border-radius: 6px;
  display: block;
  text-align: center;
  width: 100%; 
  padding: 20px;
  min-height: 199px;

  figure {
    width: 48px;
    height: 48px;
    display: block; 
    border-radius: 50%;
    padding: 0px;
    margin: 0px auto 10px;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  h6 {
    font-family: $neutralfontSemiBold;
    font-size: 15px;
    color: $primary-color;
    padding: 0px;
    margin: 0 0 5px 0;
  }
  span {
    font-family: $neutralfontMedium;
    font-size: 12px;
    color: #6D6E70;
    padding: 0px 0 20px 0px;
    margin: 0 0 0px 0;
    display: block;
    width: 100%;
  }
  a {
    font-family: $basefontMedium;
    font-size: 14px;
    color: $secondary-color;
    padding: 0px 0px;
    margin: 0 0 0px 0;
    text-decoration: none;
    &:hover {
      color: $primary-color;
    }
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .job_seekers_info {
    width: 100%;
    word-break: break-all;
  }
}
body .dashboard_page {
  .swiper-button-next, .swiper-button-prev {
    width: 32px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    background: #E6F9FF;
    &.swiper-button-disabled {
      display: none;
    }
    &:after {
      color: $primary-color;
      font-size: 12px !important;
      font-weight: bold;
    }
  }
  .swiper-button-next {
    border-radius: 6px 0 0 6px;
    right: 0px;
  }
  .swiper-button-prev {
    border-radius: 0 6px 6px 0;
    left: 0px;
  }
}

.cd_companies {
  .swiper-button-next, .swiper-button-prev {
    width: 32px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    background: #E6F9FF;
    &.swiper-button-disabled {
      display: none;
    }
    &:after {
      color: $primary-color;
      font-size: 12px;
      font-weight: bold;
    }
  }
  .swiper-button-next {
    border-radius: 6px 0 0 6px;
    right: 0px;
  }
  .swiper-button-prev {
    border-radius: 0 6px 6px 0;
    left: 0px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    list-style-type: none;
    padding: 0px;
    li {
      width: 33.3%;
      padding: 0 5px 10px;
    }
  }
}


.marb30 {
  margin-bottom: 30px;
}

.cd_charts_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .cd_chart {
    width: calc(50% - 10px);
    .chart_block {
      width: 100%;
      height: 210px;
      background: $white;
      border: 1px solid #00719233;
      border-radius: 6px; 
    }    
  }
}

.cd_forum_sec {
  .cd_forum_list {
    display: block;
    background: $white;
    border: 1px solid #00719233;
    border-radius: 6px;
    ul {
      padding:15px 20px;
      margin: 0px;
      list-style-type: none;
      li {
        padding: 0px;
        margin: 0px;
        &:nth-last-child(1) {
          .forum_item {
            border-bottom: 0px;
          }
        }
      }
    }
  }
}

.forum_item {
  background: $white;
  border-bottom: 1px solid #00719233;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  padding: 0px;
  text-decoration: none;
  figure {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center; 
    border-radius: 50%;
    padding: 0px;
    margin: 0px 20px 0 0;
    background: #F9FAFB;
    img {
      display: block;
    }
  }
  h6 {
    font-family: $neutralfontSemiBold;
    font-size: 15px;
    color: $primary-color;
    padding: 0px;
    margin: 0 0 0px 0;
  }
  span {
    font-family: $neutralfontMedium;
    font-size: 12px;
    color: #6D6E70;
    padding: 0px;
    margin: 0 20px 0px 0; 
  }
  svg {
    width: 30px;
  }
  &:hover {
    background: rgba($secondary-color , 0.02);
  }
  .forum_item_title {
    width: calc(100% - 170px);
    display: flex;
    align-items: center;
    figure {
      margin-right: 0px;
    }
    h6 {
      width: calc(100% - 50px);
      padding-left: 20px; 
      overflow: hidden;
      text-overflow: ellipsis;
      align-items: center;
      display: flex;
      display: -webkit-box; 
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

/*   Talent Dashboard  */


.dt_profile_cnt {
  padding: 20px 40px;
  background-color: $bg-color;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
      .profile_items {

        h5 {
          font-family: $basefontMedium;
          font-size: 24px;
          color: $primary-color;
          margin-bottom: 15px;
        }
  
        .profile_details {
          display: flex;
          margin: 0;
          padding: 0;
  
          .profile_list {
            list-style-type: none;
            margin-right: 10px;
  
            a {
              display: inline-flex;
              text-decoration: none;
              height: 40px;
              align-items: center;
              justify-content: center;
              border-radius: 20px;
              background-color: $white;
              padding: 0px 20px;
              font-family: $basefontMedium;
              font-size: 15px;
              color: $primary-color;

                img {
                  margin-right: 10px;
                }

            }
          }
        }

      }

      .profile_perc {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        border: 2px solid $white;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
          // span {
          //   width: 76px;
          //   height: 76px;
          //   border-radius: 50%;
          //   border: 1px solid rgb(7 36 48 / 20%);
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   font-family: $basefontBold;
          //   font-size: 28px;
          //   color: $primary-color;
          //   small {
          //     font-size: 16px;
          //   }
          // }
          .percent {
            position: relative;
            background: $white;
            padding: 5px;
            border-radius: 50%;
            svg {
              position: relative;
              width: 110px;
              height: 110px;
              transform: rotate(-90deg);
              circle {
                width: 100%;
                height: 100%;
                fill: none;
                stroke: rgba(#072430 , 0.2);
                stroke-width: 1;
                stroke-linecap: round;
                &:last-of-type {
                 // stroke-dasharray: 280px;
                  //stroke-dashoffset: calc(625px - (625px * var(--percent)) / 100);
                  stroke-dasharray: 310px;
                  stroke-dashoffset: calc(310px - (310px * 50) / 100);
                  stroke: #072430;
                  stroke-width: 4;
                }
              }
            }
          }
          .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            h3 {
              font-weight: 200;
              font-size: 28px;
              color: $primary-color;
              font-family: $basefontBold;
              padding: 0px;
              margin: 0px;
              span {
                font-size: 16px;
              }
            }
          }
          
           
      }

     
}

.dt_card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #F9FAFB;
  .title {
    font-family: $basefontMedium;
    font-size: 18px;
    color: $primary-color;
    margin: 0;

  }

  .viewall {
    font-family: $basefontMedium;
    font-size: 14px;
    color: $secondary-color;
    text-decoration: none;
    &:hover {
      color: $primary-color;
    }
  }
  svg {
    width: 24px;
    background: $white;
  }
  .simpleselect_container {
    margin-bottom: 0px;
    .simpleselect {
      height: 30px;
      .simpleselect__control {
        height: 30px;
      }
    }
  }
}

.dt_mainsec {
  .dt_cards_sec {
    width: 100%;
    display: block;
    border: 1px solid rgba($secondary-color , 0.33);
    border-radius: 10px;
    margin-bottom: 20px; 
    min-height: calc(100% - 20px);
    background: $white;
    overflow: hidden;
  }
}

.job_card {
  background-color: $white;
  padding: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  .jobseeker_card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F9FAFB;    
    border-radius: 6px;
    padding: 20px 0px;
    .profile_pic {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid #707070;
      display: block;
      overflow: hidden;
      img {
        width: 100%;
        display: block;
      }
    }
    .profile_name {
      font-family: $neutralfontMedium;
      font-size: 15px;
      color: $primary-color;
      margin: 4px 0px;
    }
    .profile_role {
      font-family: $neutralfontMedium;
      font-size: 12px;
      color: #6D6E70;
      margin-top: 3px;
      margin-bottom: 5px;
    }
    .profile_location {
      font-family: $neutralfontMedium;
      font-size: 12px;
      color: #6D6E70;
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }
    .matched_perc {
      margin-top: 20px;
      margin-bottom: 12px;
      font-family: $neutralfontBold;
      font-size: 15px;
      color: $secondary-color;
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
    }
    .view_profile {
      border: 1px solid $secondary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $basefontMedium;
      font-size: 14px;
      color: $primary-color;
      border-radius: 14px;
      padding: 5px 22px;
      text-decoration: none;
      background-color: $white;
      &:hover {
        background: $secondary-color;
        color: $white;
      }
    }
  }
  .swiper  {
    padding: 10px;
  }
  &.matching_jobs {
    padding: 10px;
    .jobseeker_card {
      width: calc(50% - 5px);
    }
    .match_candidate{
      width: calc(24.7% - 5px);
      background-color: #F0F7FD;
      &:nth-child(even) {
        background: #F9FAFB;
      }
    }
  }
  &.empmatch_cnt{
    width: 100%;
  }
}



.events_tasks {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .upcoming_events {
    width: 49%;
    border: 1px solid rgba($secondary-color , 0.33);
    border-radius: 10px;
    .events_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      background-color: #F9FAFB;
      h3 {
        font-family: $basefontMedium;
        font-size: 18px;
        color: $primary-color;
        margin: 0;
      }
      a {
        text-decoration: none;
        font-family: $basefontMedium;
        font-size: 14px;
        color: $secondary-color;
      }
    }    
  }

  .tasks {
    width: 49%;
    border: 1px solid #00719233;
    border-radius: 10px;
    .task_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      background-color: #F9FAFB;
      h3 {
        font-family: $basefontMedium;
        font-size: 18px;
        color: $primary-color;
        margin: 0;
      }
      a {
        text-decoration: none;
        font-family: $basefontMedium;
        font-size: 14px;
        color: $secondary-color;
      }

    }

    .task_list {
      padding: 30px 20px;
      background-color: $white;
      margin: 0;
      list-style-type: none;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .task_item {
        margin-bottom: 25px;
        p {
          font-family: $neutralfontMedium;
          font-size: 15px;
          color: $primary-color;
          margin: 0;
          display: flex;
          align-items: center;
          line-height: 16px;
          &::before {
            content: '';
            display: inline-flex;
            align-items: center;
            width: 18px;
            height: 18px;
            border: 1.5px dashed $primary-color;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
      }

    }

  }



}


.event_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 34px 0px;
  background-color: $white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  label {
    font-family: $neutralfontMedium;
    font-size: 12px;
    color: #6D6E70;
    margin-bottom: 20px;
  }

p {
  font-family: $neutralfontMedium;
  font-size: 20px;
  color: $primary-color;
  max-width: 330px;
  margin: 0 auto 10px;
  text-align: center;
}

.time_date {
  font-family: $neutralfontMedium;
  font-size: 14px;
  color: $secondary-color;
  margin-bottom: 20px;
  
}

.event_category {
  display: flex;
  .attendees {
    height: 30px;
    border: 1px solid #07243026;
    border-radius: 6px;
    display: flex;
    margin-right: 10px;
    .followers_img {
      padding: 0px 10px;
      border-right: 1px solid #07243026;
      background-color: #07243026;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .total_attend {
      padding: 0px 15px;
      font-family: $neutralfontMedium;
      font-size: 12px;
      color: $primary-color;
      display: flex;
      align-items: center;
    }

  }

  .save_event {
    height: 30px;
    border: 1px solid #07243026;
    border-radius: 6px;
    display: flex;
    margin-right: 10px;
    .heart_img {
      padding: 0px 10px;
      border-right: 1px solid #07243026;
      background-color: #07243026;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    .save_title {
      padding: 0px 15px;
      font-family: $neutralfontMedium;
      font-size: 12px;
      color: $primary-color;
      display: flex;
      align-items: center;
      text-decoration: none;
    }

  }

  .attend {
    height: 30px;
    background-color: #007192;
    font-family: $basefontMedium;
    font-size: 15px;
    color: $white;
    border-radius: 6px;
    text-decoration: none;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    img {
      margin-right: 8px;
    }

  }

  @media screen and (max-width:$medium) {
    flex-wrap: wrap;
    justify-content: center;
    .attendees {
      margin-bottom: 8px;
    }
  }
}

}

.tasks_list {
  ul {
    padding: 20px 20px 0;
    background-color: $white;
    margin: 0;
    list-style-type: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .task_item {
      margin-bottom: 25px;
      p {
        font-family: $neutralfontMedium;
        font-size: 15px;
        color: $primary-color;
        margin: 0;
        display: flex;
        align-items: center;
        line-height: 16px;
        &::before {
          content: '';
          display: inline-flex;
          align-items: center;
          width: 18px;
          height: 18px;
          border: 1.5px dashed $primary-color;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  
  }
}


.org_forum {
  width: 100%;
  overflow: auto;
  table {
    width: 100%;
    thead tr {
      background-color: #E6F9FF;
      th {
        padding: 8px 20px;
        font-family: $neutralfontMedium;
        font-size: 13px;
        color: $primary-color;
        font-weight: normal;
      }
    }
    tr {
      background-color: $white;

      td {
        padding: 0px 20px;
        font-family: $neutralfontMedium;
        font-size: 14px;
        color: $primary-color;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        height: 50px;
        align-items: center;
        vertical-align: middle;

        ul {
          display: flex;
          padding: 0;
          margin: 0px;

          li {
            list-style-type: none;
            margin-left: -5px;
            position: relative;
            figure {
              width: 32px;
              height: 32px;
              display: block;
              border: 2px solid $white;
              border-radius: 50%;
              overflow: hidden;
              padding: 0px;
              margin: 0px;
              img {
                width: 100%;
                height: auto;
              }
            }
            figcaption {
              position: absolute;
              bottom: 100%;
              left: 50%;
              transform: translateX(-50%);
              background: $primary-color;
              color: $white;
              padding: 3px 10px;
              border-radius: 4px;
              white-space: nowrap;
              display: none;
              &:after {
                top: 100%;
                left: 50%;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(7, 36, 48, 0);
                border-top-color: #072430;
                border-width: 6px;
                margin-left: -6px;
              }
            }
            &:hover {
              z-index: 9;
              .figcaption {display: block;}
            }
          }
        }
      }
    }
  }
}

.latest_news { 
  .articles {
    background-color: $white;
    padding:0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 0px;
    .article_card {
      border: 1px solid #0071921A;
      border-radius: 10px;
      width: 260px;
      figure {
        display: block;
        margin: 0px;
        height: 145px;
        img {
          height: 145px;
          width: auto;
        }
      }
      .news_info {
        padding: 20px 20px 10px;
        label {
          font-family: $neutralfontMedium;
          font-size: 13px;
          color: $secondary-color;
          margin-bottom: 10px;
        }
        p{
          font-family: $neutralfontMedium;
          font-size: 15px;
          color: $primary-color;
          max-width: 226px;

        }
      }


    }
    .swiper {
      padding: 20px;
    }
  }
}



.chart_item {
  width: 100%;
  display: flex;
  align-items: center;
  .chart_name {
    width: 90px;
    font-family: $neutralfontMedium;
    font-size: 12px;
    color: $primary-color;
    padding: 0px;
    margin: 0px;
  }
  .chart_details {
    width: calc(100% - 120px);
    position: relative;
    display: flex;
    align-items: center;
    .chart_value {
      width: 0px;
    //  max-width: calc(100% - 40px);
      background: #197BAB;
      height: 14px;
    }
    .chart_count {
      width: 35px;
      font-family: $neutralfontSemiBold;
      font-size: 14px;
      color: $primary-color;
      padding: 0px 0 0 2px;
      margin: 0px;
      position: absolute;
      top: -3px;
      sub {
        bottom: 0px;
      }
    }
  }
}

.vertical_chart_item {
  width: 100%; 
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .chart_name {     
    font-family: $neutralfontMedium;
    font-size: 12px;
    color: $primary-color;
    padding: 0px;
    margin: 0px;
    border-top: 1px solid rgba($secondary-color , 0.15);
    height: 35px;
    line-height: 14px;
    padding-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .chart_value {
    width: 42px;
   // max-height: calc(100% - 45px);
    background: #55D2FE;
    height: 0px;
    margin: 0 auto;
  }
  .chart_count { 
    font-family: $neutralfontSemiBold;
    font-size: 18px;
    line-height: 22px;
    color: $primary-color;
    padding: 0px 0 0px 0px;
    margin: 0px;
    
    sub {
      bottom: 0px;
    }
  }
  .chart_details {
    width: calc(100% - 90px);
    display: flex;
    align-items: center;    
  }
  .chart_value_cnt {
    height: calc(100% - 35px);
    display: flex;
    align-items: flex-end;
    position: relative;
    .chart_count {
      position: absolute;
      left: 0px;
      bottom: 13%;
      text-align: center;
      display: block;
      width: 100%;
    }
  }
}
 

.chart_cnt {
  width: 100%;
  padding: 20px 10px 10px 20px;
  h5 {
    font-family: $basefontMedium;
    font-size: 16px;
    color: $black;
    padding: 0px;
    margin: 0px 0 5px 0;
  }
  ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
    li {
      width: 100%;
      padding: 8px 0;
    }    
  }
  &.vertical_chart_cnt {
    height: 100%;     
    padding-bottom: 5px;
    ul {
      display: flex;
      justify-content: space-around; 
      height: 100%; 
      li {
        text-align: center;
        padding: 0;
        height: 100%;
      }
    }
  }
}

// Analysis styles
.dashboard_head {
  background-color: $primary-color;
  padding: 0 20px;
  position: relative;
  z-index: 9;
  .dashboard_head_cnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    > ul {
      padding: 0px;
      margin: 0px;
      list-style-type: none;
      > li {
        padding: 0 0 0 10px;
      }
    }
    @media screen and (max-width:$medium) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
     > ul > li {
        padding: 0 10px 0 0;
      }
    }
  }
  h2 {
    font-family: $basefontBold;
    font-size: 28px;
    color: $white;
    margin: 0;
  }
  a {
    background-color: #007192;
    border-radius: 6px;
    font-family: $basefontBold;
    color: $white;
    font-size: 18px;
    height: 45px;
    padding: 0px 30px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
  }
  .more_menu {
    margin: 0 0 0 15px;
    cursor: pointer;
    span {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $white;
      margin: 0 0 2px;
      display: flex;
    }
  } 
}
.comp_card {
  width: calc(50% - 12px);
  height: 163px;
  display: flex;
  border: 1px solid #0071921A;
  border-radius: 6px;
  background-color: $white;
  padding: 0px 5px;
  .comp_details {
    background-color: #B9EEFE;
    width: 170px;
    min-width: 170px;
    text-align: center;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 5px 0px;
    text-decoration: none;
    span {
      width: 26px;
      margin: 0 auto 12px;
      img {
        width: 100%;
      }
    }
    h4 {
      font-family: $neutralfontBold;
      font-size: 26px;
      color: $primary-color;
      margin: 0 0 7px;
    }
    label {
      font-family: $neutralfontMedium;
      font-size: 14px;
      color: $primary-color;
    }
    &::after {
      content: "";
      background: url('../images/icons/down-right.svg') no-repeat;
      width: 8px;
      height: 8px;
      position: absolute;
      bottom: 10px;
      right: 10px; 
    }
  }
  .comp_slide {
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    span {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid #00719233;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 5px;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
      }
    }
    h5 {
      font-family: $neutralfontMedium;
      font-size: 15px;
      color: $primary-color;
      margin: 0 0 2px;
    }
    label {
      font-family: $neutralfontMedium;
      font-size: 12px;
      color: #ED4747;
    }
    &::after {
      // // content: "Coming up for renewal";
      // background: #E6F9FF;
      // width: 160px;
      // height: 28px;
      // position: absolute;
      // border-radius: 0px 0px 4px 4px;
      // font-family: $neutralfontMedium;
      // font-size: 12px;
      // color: $primary-color;
      // top: 0;
      // left: 50%;
      // transform: translateX(-50%);
      // line-height: 28px;
    }
    .renewal_btn{
      width: 100px;
      font-size: 12px;
      color: #000000;
      background-color: transparent;
      padding: 2px 0;
      border-radius: 12px;
      border: 1px solid #E2E2E2;
     
    }
  }
  &.talent_card {
    .comp_slide::after {
      display: none;
    }
  }
  &.latest_login {
    .comp_slide::after {
     content: "Last Logged In";
    }
  }
  .nocardTemplate {
    .card_info {
      height: 100%;
      align-items: center;
      justify-content: center;
     .line.dp {
      width: 50px;
      height: 50px;
     }
      .line {
        &.samll {
          display: none;
        }
      }
    }
  }
}

.job_card_comp {
  width: calc(50% - 12px);
  height: 163px;
  display: flex;
  border: 1px solid #0071921A;
  border-radius: 6px;
  background-color: $white;
  padding: 0px 5px;
  .job_details {
    background-color: #B9EEFE;
    width: 170px;
    text-align: center;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 5px 0px;
    span {
      width: 26px;
      margin: 0 auto 12px;
      img {
        width: 100%;
      }
    }
    h4 {
      font-family: $neutralfontBold;
      font-size: 26px;
      color: $primary-color;
      margin: 0 0 7px;
    }
    label {
      font-family: $neutralfontMedium;
      font-size: 14px;
      color: $primary-color;
    }
    &::after {
      content: "";
      background: url('../images/arrow-down-black.png');
      width: 16px;
      height: 16px;
      position: absolute;
      bottom: 10px;
      right: 10px;
      transform: rotate(-45deg);
    }
  }
  .jobsCard_slide {
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    h5 {
      font-family: $neutralfontMedium;
      font-size: 15px;
      color: $primary-color;
      margin: 0 0 5px;
    }
    span {
      display: flex;
      align-items: center;
      font-family: $neutralfontMedium;
      font-size: 12px;
      color: #6D6E70;
      margin: 0 0 10px;
    }
    label {
      font-family: $neutralfontMedium;
      font-size: 12px;
      color: $primary-color;
    }
    &::after {
      // content: "Coming up for renewal";
      // background: #E6F9FF;
      // width: 160px;
      // height: 28px;
      // position: absolute;
      // border-radius: 0px 0px 4px 4px;
      // font-family: $neutralfontMedium;
      // font-size: 12px;
      // color: $primary-color;
      // top: 0;
      // left: 50%;
      // transform: translateX(-50%);
      // line-height: 28px;
    }
  }
}

.details_cnt {
  // min-width: 321px;
  width: 100%;
  border: 1px solid #0071921A;
  border-radius: 6px 0px 0px 6px;
  .details_head {
      background-color: #F9FAFB;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      h4 {
        font-family: $basefontMedium;
        color: $primary-color;
        font-size: 16px;
        margin: 0;
      }
      span {
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        border-radius: 4px;
      }
  }
  .details_content {
    background-color: $white;
    padding: 20px;
    .details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0px 0px 20px;
      .account_details {
        display: flex;
        align-items: center;
      }
      .icon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin: 0 16px 0 0;
        display: flex;
        img {
          width: 100%;
        }
      }
      .name {
        font-family: $neutralfontMedium;
        font-size: 14px;
        color: $primary-color;
        line-height: 15px;
        width: calc(100% - 48px);
        span {
          font-size: 12px;
          color: $secondary-color;
        }
      }
      .number_of_jobs {
        font-family: $basefontMedium;
        font-size: 14px;
        color: $primary-color;
        border: 1px solid #00719233;
        border-radius: 13px;
        padding: 0px 10px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.card_summary_sec {
  width: 360px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  background-color: $white;
}

.cards_summary {
  padding: 18px 20px;
  width: 168px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  background-color: #E6F9FF;
  border-radius: 6px;
  margin: 0px 0px 4px 0px;
  .price {
    font-family: $neutralfontBold;
    font-size: 28px;
    color: $black;
    line-height: 20px;
    margin: 0 0 11px;
    span {
      font-size: 16px;
      font-family: $neutralfontMedium;
    }
    .duration {
      font-size: 14px;
    }
  }
  .plan_type {
    font-family: $neutralfontMedium;
    font-size: 14px;
    color: $black;
  }
}

.nav_cnt {
  width: 220px;
  margin: 0 0px 0 0;
  position: sticky;
  top: 0px;
  .cd_nav_menu {
    width: 100%;
    position: static;
    margin-bottom: 10px;
    border: 1px solid #0071921A;
    border-top: 0px;
    border-radius: 0 0 6px 6px; 
  }
    .profile_card {
      width: 100%;
      border: 1px solid #0071921A;
      border-radius: 6px;
      background-color: $white;
      text-align: center;
      .profile_cnt {
      padding: 20px;
      .image {
        width: 64px;
        height: 64px;
        border: 1px solid #00719233;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 10px;
        overflow: hidden;
        img {
          max-width: 100%;
        }
      }
      h4 {
        margin: 0 0 5px;
        font-family: $neutralfontSemiBold;
        color: $primary-color;
        font-size: 15px;
      }
      .private {
        height: 25px;
        padding: 0px 15px;
        border-radius: 14px;
        background-color: #E6F9FF;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: $neutralfontMedium;
        font-size: 12px;
        color: $primary-color;
        margin: 0 0 5px;
        svg {
          margin: 0 5px 2px 0;
        }
      }
      .followers {
        font-family: $neutralfontMedium;
        font-size: 12px;
        color: #6D6E70;
        display: block;
      }
    }
    .navigation {
      text-decoration: none;
      font-family: $neutralfontMedium;
      font-size: 16px;
      color: $secondary-color;
      display: block;
      padding: 15px 0px;
      border-top: 1px solid #E5F0F4;
      text-decoration: none;
    }
  }

    .job_cards {
      width: 100%;
      padding: 19px 10px;
      border: 1px solid #0071921A;
      border-radius: 0px 0px 6px 6px;
      background-color: $white;
      margin: 0 0 10px;
      ul {
        padding: 0;
        list-style: none;
        margin: 0;
        li {
          font-family: $neutralfontMedium;
          font-size: 14px;
          margin: 0 0 10px;
          padding: 9px 4px;
          a {
            text-decoration: none;
            color: $primary-color;
            display: flex;
            align-items: center;
            span {
              display: flex;
              width: 28px;
              height: 28px;
              align-items: center;
              justify-content: center;
              background-color: $white;
              border-radius: 5px;
              margin: 0 14px 0 0;
            }
          }
          &:last-child {
            margin-bottom: 0px;
          }
          &:hover {
            background-color: #E6F9FF;
            border-radius: 6px;
            a {
              color: $secondary-color;
            }
          }
        }
      }
  }
}

.skills_card {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0px;
  border: 1px solid #0071921A;
  background-color: $white;
  border-radius: 6px;
  span {
    min-width: 50px;
    padding: 0px 15px;
    height: 30px;
    margin: 0 5px 5px 0;
    border: 1px solid #6D6E7027;
    border-radius: 100px;
    font-family: $neutralfontMedium;
    font-size: 13px;
    color: $primary-color;
    display: inline-flex;
    align-items: center;
  }
}

.hiring_sec {
  width: calc(100% - 500px);
  padding: 15px;
  margin: 0 0px 0 0;
  .subscription_sec {
    .card {
      width: 230px;
      padding: 22px;
      background-color: #114C68;
      border-radius: 6px;
      text-align: center;
      position: relative;
      span {
       margin: 0 auto 13px;
        img {
          width: 100%;
        }
      }
      h4 {
        font-family: $neutralfontBold;
        font-size: 28px;
        color: $white;
        margin: 0 0 5px;
      }
      label {
        font-family: $neutralfontMedium;
        font-size: 14px;
        color: $white;
      }
      &::after {
        content: "";
        background: url('../images/Arrow-down.png');
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 14px;
        height: 20px;
        transform: rotate(-45deg);
      }
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
  .swiper {
    width: calc(100% - 170px);
    .swiper-pagination-bullet {
      width: 15px;
      height: 3px;
      border-radius: 24px;
      margin: 0 3px !important;
      background: rgba($secondary-color , 0.5);
      &.swiper-pagination-bullet-active {
        background: $secondary-color;
      }
    }
  } 
  .comp_cnt {
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px;
  }
  .summary_sec {
    display: flex;
    justify-content: space-between;
  }
  .Emp_users_Sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    margin: 0 0 11px;
    h4 {
      margin: 0;
      font-family: $basefontMedium;
      font-size: 16px;
      color: $primary-color;
    }
    a {
      text-decoration: none;
      color: $secondary-color;
      font-family: $basefontMedium;
      font-size: 14px;
    }
    select {
      border: 1px solid #E2E2E2;
      border-radius: 15px;
      font-family: $basefontMedium;
      font-size: 14px;
      color: $primary-color;
      padding: 0px 15px;
      height: 30px;
      &:focus-visible {
        outline: none;
      }
    }
  }
  .details_sec {
    display: flex;
    margin: 0 0 21px;
    .details_cnt {
      border-right: 0px;
      &:last-child {
        border-right: 1px solid rgba(0, 113, 146, 0.1019607843);
      }
    }
  }
  .job_cnt {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: $white;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 30px;
  }
}

.companies_details {
  width: 280px;
  padding: 15px 0px;
  .list_block {
    margin-bottom: 15px;
  }
}

.Analysis_cnt {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 0px 10px;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  @media screen and (max-width:1200px) {
    flex-wrap: wrap;
    .hiring_sec {
      width: calc(100% - 220px);
      padding-right: 10px;
    }
    .companies_details {
      width: calc(100% - 240px);
      margin-left: auto;
      padding-right: 10px;
    }
  }
  @media screen and (max-width:991px) {
    .hiring_sec { 
      .comp_cnt {
        flex-wrap: wrap;
        .comp_card {
          width: 100%;
          margin-bottom: 15px;
        }
      }
    }
  }
  @media screen and (max-width:$medium) {
    flex-direction: column;
    .nav_cnt {
      width: 100%;
      position: static;
      padding-bottom: 15px;
    }
    .hiring_sec {
      width: 100%;
      padding: 0px;
      .comp_cnt {
        flex-direction: column;
        .comp_card {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .employer_sec {
        .employer_cnt {
          flex-direction: column;
          .employer_block {
            width: 100%;
            &.w-50 {
              width: 100% !important;
            }
          }
          .empmatch_cnt {
            flex-wrap: wrap;
            .match_candidate {
              width: 100%;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    .companies_details {
      width: 100%;
    }
  }
}

.jobCards {
  width: 100%; 
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  background-color: $white;
  margin: 0 0 5px;
  height: 100%;
  justify-content: center;
  label {
    font-family: $neutralfontMedium;
    font-size: 15px;
    line-height: 19px;
    color: $primary-color;
  }
  h5 {
    font-family: $neutralfontMedium;
    font-size: 15px;
    line-height: 19px;
    color: $primary-color;
    margin: 0 0 5px 0;
  }
  .location {
    font-family: $neutralfontMedium;
    font-size: 12px;
    color: #6D6E70;
    margin: 0 0 10px 0;   
    flex-wrap: wrap;
    display: inline-flex;
    justify-content: center;
    span {
      padding-right: 5px;
      &:nth-child(1) {
        background: url('../images/icons/location.svg') no-repeat 0 2px;
        padding: 0 0 0 15px;
        background-size: 11px auto; 
      }
    }
  }
  .job-labels {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    span {
      font-family: $primaryfontRegular;
      font-size: 12px;
      color: #072430;
      position: relative;
      padding: 0 6px 0 5px;
      &:after {
        content: "|";
        display: block;
        position: absolute;
        right: 0px;
        top: 0px;
      }
      &:nth-last-child(1):after {
        display: none;
      }
    }
  }
  // &::after {
  //   content: "";
  //   background: url('../images/arrow-down-black.png');
  //   width: 16px;
  //   height: 16px;
  //   position: absolute;
  //   bottom: 10px;
  //   right: 10px;
  //   transform: rotate(-45deg);
  // }
}

.avail_jobs {
  margin: 0 0 10px 0;
  padding: 20px;
  background-color: $white;
  border: 1px solid #0071921A;
  border-radius: 6px;
  
}
.avail_cnt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 21px;
  .text_cont {
    width: calc(100% - 28px);
      h4 {
        font-family: $neutralfontMedium;
        font-size: 15px;
        color: $primary-color;
        margin: 0;
      }  
      label {
        font-family: $neutralfontMedium;
        font-size: 13px;
        color: #6D6E70;
      }
    }
  
    a {
      margin: 0 0 0 0px;  
      img {
        opacity: 0.4;
      }
  } 
}
.list_block {
  width: 100%;
  display: block;
  border-radius: 6px;
  border: 1px solid #0071921A;
  background: $white;
  .list_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 15px;
    background: #F9FAFB;
    border-radius: 6px 6px 0 0;
    h4 {
      font-family: $basefontMedium;
      font-size: 16px;
      color: $black;
      padding: 0px;
      margin: 0;
    }
    svg {
      width: 26px;
    }
  }
  .list_items {
    display: block;
    padding:15px;
    ul {
      list-style-type: none;
      padding: 0px;
      margin: 0px;
      li {
        margin-bottom: 15px;
        &:nth-last-child(1) {
          padding-bottom: 0px;
        }
      }
    }
  }
}


.company-item {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  .company_details {
    display: flex;
    align-items: center;
    .icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 0 0px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #00719233;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
      }
    }
    .name {
      font-family: $neutralfontMedium;
      font-size: 14px;
      color: $primary-color;
      line-height: 15px;
      width: calc(100% - 40px);
      padding-left: 10px;
      span {
        font-size: 12px;
        color: #6D6E70;
        display: block;
        background: url('../images/icons/location.svg') no-repeat 0 0;
        padding: 0 0 0 13px;
        background-size: 10px auto;
        margin: 4px 0 0 0;
        &.time_msgd{
          float: right;
          background: none;
          color: grey;
          font-size: 10px;
          width: 25%;
        }
        &.emp_email{
          word-break: break-all;
        }
      }
    }
    .right_msg_cnt{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: calc(100% - 40px);
    }
    .msg_text{
      font-size: 12px;
      color: grey;
      padding: 0 0 0 10px;
      white-space: nowrap;
      width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  .right_info {
    font-family: $basefontMedium;
    font-size: 14px;
    color: $primary-color;
    border: 1px solid #00719233;
    border-radius: 13px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    height: 26px;
    white-space: nowrap;
  }
  &.user {
    .company_details {
      .icon {
        width: 32px;
        height: 32px;
        img {
          max-width: 100%;
        }
      }
      .name {
        width: calc(100% - 32px);
        span {
          background: none;
          padding: 0px;
          color: #007192;
          padding-right: 5px;
        }
      }
    }
  }
}

.employer_sec {
  .employer_cnt {
    display: flex;
    background: $white;
    border: 1px solid #0071921A;
    .employer_block {
      width: 33.3%;
      border-right: 1px solid #0071921A;
      .dt_card_header {
        border-radius: 0px;
        .title {
          font-size: 16px;
        }
      }
      .employer_list {
        ul {
          padding:15px;
          margin: 0px 0px;
          list-style-type: none;
          li {
            padding: 0px 0 15px 0;
            margin: 0px;
            &:nth-last-child(1) {
              padding-bottom: 0px;
            }
          }
        }
        .nodataTemplate {
          padding: 15px;
        }
        &.chart {
          padding-left: 20px;
          .card {
            width: 200px;
          }
        }
      }
      
      &:nth-last-child(1) {
        border-right: 0px;
      }
      .chart_cnt.vertical_chart_cnt {
        height: calc(100% - 54px);
      }
    }
  }
}


.demand_jobs {
  .dt_card_header {
    padding: 0 0 10px 0;
  }
  .demand_chart {
    height: 300px;
    background: $white;
    border: 1px solid #0071921A;
    border-radius: 6px;
    .chart_cnt.vertical_chart_cnt ul {
      height: 100%;
    }
  }
}



.pagination{
  justify-content: flex-end;
  margin: 0;
  .page-item {
    .page-link {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $primaryfontRegular;
      font-size: 13px;
      color: $primary-color;
      border: 0px;
      background: transparent;
      &:focus {
          box-shadow: none;
      }
      &.active {
          background: #65667B;
          color: $white;
      }
    }
    &:nth-child(1) .page-link {
      background: url('../images/icons/right-arrow.png') no-repeat center;
      font-size: 0px;
      background-size: 16px auto;
    }
    &:nth-last-child(1) .page-link {
      background: url('../images/icons/left-arrow.png') no-repeat center;
      font-size: 0px;
      background-size: 16px auto;
    }
    &.disabled {
      opacity: 0.5;
    }
    &.active .page-link{
      background: #65667B;
      color: $white;
  }
  }
  .active>.page-link, .page-link.active{
    border: 1px solid $base-color5;
    background: $white;
    color: $primary-color;
    width: 32px;
    height: 32px;
    border-radius: 6px;
  }
}

.no_data_db {
  font-family: $basefontMedium; 
  color: $primary-color;
  font-size: 20px;
  text-align: center;
  padding: 50px 0 30px;
}

.event_card_wrap {
  width: 100%;
  .event_card_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    .event_card {
      width: calc(33.3% - 10px);
      margin: 0 5px 10px;
    }
    @media screen and (max-width:$medium) {
      padding: 0 10px;
      .event_card { 
        width: 100%;
      }
    }
  }
}


.without_img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 125px;
  background: url('../images/event_bg.jpg') no-repeat 0 0;
  background-size: cover;
  font-family: $primaryfontMedium;
  color: rgba($primary-color , 0.6);
  font-size: 35px;
  text-transform: uppercase;
}

.less-padd{
  padding: 10px 10px !important;
}
.timeline-container{
  width: 50%;
  padding: 0 0 0 20px; 
  display: block;
  position: relative;
  margin: 0px 0;
  &:after {
    background: rgba(#072430 , 0.5);
    content: '';
    position: absolute;
    left: 20px;
    width: 1px;
    height: calc(100% - 40px);
    top: 20px;
    
  }
  .timeline-item {
    display: flex; 
    padding:10px 0px 10px 18px;
    position: relative;
    margin: 0; 
    p {
      font-size: 15px;
      font-family: $neutralfontMedium;
      line-height: 16px;
      margin: 6px 0 6px 0px; 
    }
    .circle {
      background-color: #fff;
      border: 2px solid #007192;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left:0;
      transform: translate(-50% , -50%);
      width: 12px;
      height: 12px;
      z-index: 100;
      &.rejected{
        background: url('../images/icons/rejected.svg') no-repeat center;
        width: 20px;
        height: 20px;
        border: 0;
      }
      &.hired{
        background: url('../images/icons/timelinecheck.svg') no-repeat center;
        width: 20px;
        height: 20px;
        border: 0;
      }
    }
  }

 
  
}
 
    

.speed-section{
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.emp-time-line{
  display:  flex;
  padding: 10px 20px;
  width: 100%;
  margin: 0;  
  .no_work_days{
    background-color: #E6F9FF;
    text-align: center;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 50%;
    margin: 0px 0px 0 0;
    text-decoration: none;
    min-height: 100%;
    h4{
      font-family: "Inter Bold";
      font-size: 26px;
      color: #072430;
      margin: 0 0 7px;
    }
    span{
      font-family: "Inter Medium";
      font-size: 14px;
      color: #072430;
    }
  }
}
.card{
  border: none;
  border-radius: 0;
}


.recent_msg {
  width: 100%;

  .name_time {
    width: 100% !important;
  }
}


.chart {
  width: 300px;
}
.messages-list {
  padding-bottom: 60px;
}
.job_item{
  display: flex;
  flex-direction: column;
  .title{
    font-family: "Inter Medium";
    font-size: 14px;
    color: #072430;
    line-height: 15px;
    width: calc(100% - 40px);
    padding-left: 10px;
    &:hover{
      cursor: pointer;
    }
  }
  
  .applied_count{
    font-family: "gestamedium";
    font-size: 14px;
    color: #007192;
    padding: 0px 10px;
    display: flex;
    height: 26px;
    white-space: nowrap;
  }
}


.pie_chart_div{
  width: 200px;
  height: 200px;
  .card{
    canvas {
      width: 100%;
    }
  }
}
