$primaryfontLight: 'Roboto Light';
$primaryfontRegular: 'Roboto Regular';
$primaryfontMedium: 'Roboto Medium';
$primaryfontBold: 'Roboto Bold';

$secondaryfontLight: 'Rubik Light';
$secondaryfontRegular: 'Rubik Regular';
$secondaryfontMedium: 'Rubik Medium';
$secondaryfontSemiBold: 'Rubik Semi Bold';
$secondaryfontBold: 'Rubik Bold';

$neutralfontLight: 'Inter Light';
$neutralfontRegular: 'Inter Regular';
$neutralfontMedium: 'Inter Medium';
$neutralfontSemiBold: 'Inter Semi Bold';
$neutralfontBold: 'Inter Bold';

$basefontLight: 'gestalight';
$basefontRegular: 'gestaregular';
$basefontMedium: 'gestamedium';
$basefontBold: 'gestabold';

//// Rubik fonts
@font-face {
    font-family: 'Rubik Light';
    src: url("../../../public/fonts/Rubik/Rubik-Light.woff") format('woff'),
         url("../../../public/fonts/Rubik/Rubik-Light.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik Regular';
    src: url("../../../public/fonts/Rubik/Rubik-Regular.woff") format('woff'),
         url("../../../public/fonts/Rubik/Rubik-Regular.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik Medium';
    src: url("../../../public/fonts/Rubik/Rubik-Medium.woff") format('woff'),
         url("../../../public/fonts/Rubik/Rubik-Medium.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik Semi Bold';
    src: url("../../../public/fonts/Rubik/Rubik-SemiBold.woff") format('woff'),
         url("../../../public/fonts/Rubik/Rubik-SemiBold.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik Bold';
    src: url("../../../public/fonts/Rubik/Rubik-Bold.woff") format('woff'),
         url("../../../public/fonts/Rubik/Rubik-Bold.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}

//// Roboto fonts
@font-face {
    font-family: 'Roboto Light';
    src: url("../../../public/fonts/Roboto/Roboto-Light.woff") format('woff'),
         url("../../../public/fonts/Roboto/Roboto-Light.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Regular';
    src: url("../../../public/fonts/Roboto/Roboto-Regular.woff") format('woff'),
         url("../../../public/fonts/Roboto/Roboto-Regular.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Medium';
    src: url("../../../public/fonts/Roboto/Roboto-Medium.woff") format('woff'),
         url("../../../public/fonts/Roboto/Roboto-Medium.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Bold';
    src: url("../../../public/fonts/Roboto/Roboto-Bold.woff") format('woff'),
         url("../../../public/fonts/Roboto/Roboto-Bold.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}

//// Inter fonts
@font-face {
    font-family: 'Inter Light';
    src: url("../../../public/fonts/Inter/Inter-Light.woff") format('woff'),
         url("../../../public/fonts/Inter/Inter-Light.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter Regular';
    src: url("../../../public/fonts/Inter/Inter-Regular.woff") format('woff'),
         url("../../../public/fonts/Inter/Inter-Regular.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter Medium';
    src: url("../../../public/fonts/Inter/Inter-Medium.woff") format('woff'),
         url("../../../public/fonts/Inter/Inter-Medium.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter Semi Bold';
    src: url("../../../public/fonts/Inter/Inter-SemiBold.woff") format('woff'),
         url("../../../public/fonts/Inter/Inter-SemiBold.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter Bold';
    src: url("../../../public/fonts/Inter/Inter-Bold.woff") format('woff'),
         url("../../../public/fonts/Inter/Inter-Bold.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}




// Gesta Fonts 
@font-face {
    font-family: 'gestaregular';
    src:url("https://use.typekit.net/af/f79662/00000000000000000001417b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/f79662/00000000000000000001417b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/f79662/00000000000000000001417b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}
@font-face {
    font-family: 'gestamedium';
    src:url("https://use.typekit.net/af/465856/00000000000000007735989f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/465856/00000000000000007735989f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/465856/00000000000000007735989f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}
@font-face {
    font-family: 'gestalight';
    src:url("https://use.typekit.net/af/cd15d4/000000000000000077359898/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/cd15d4/000000000000000077359898/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/cd15d4/000000000000000077359898/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}
@font-face {
    font-family: 'gestabold';
    src:url("https://use.typekit.net/af/771564/00000000000000000001417c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/771564/00000000000000000001417c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/771564/00000000000000000001417c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}
