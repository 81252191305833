
@import "includes.scss"; 
@import "../styles/candidates.scss"; 
@import "buttons.scss";
@import "dashboard_v2.scss";
.wrapper {
  display: flex;
  align-items: flex-start;
} 
.maincontent {
  width: 100%; 
  background: #F8F8F8;
  min-height: calc(100vh - 246px);
  // @media screen and (max-width:$large) {
  //   padding-top: 60px;
  // }

}
.maincontent > .community_dashboard  {
  .page-header {
    &.header-space {
      @media screen and (max-width:$large) {
        padding-top: 100px; 
      }
      @media screen and (max-width:$medium) {
        padding-top: 80px !important;
      }
    }
  } 
} 

.maincontent > .container {
  .page-header {
    &.header-space {
      @media screen and (max-width:$large) {
        padding-top: 100px; 
      }
      @media screen and (max-width:$medium) {
        padding-top: 80px !important;
      }
    }
  } 
}


.layout {
    &.-low {background: black;}
}


body.before-login {
  .header, .footer {display: none;}
}
 


////////page Header styles ////////////

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0;
  position: relative;
  z-index: 99;
  .page-title {
    @include pageTitle;
  }
  .page-actions-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &.page-actions-v2 {
      width: calc(100% - 310px);
      justify-content: space-between;     
    }
    .page-actions .invite_btn-wrap {
      
    }
    @media screen and (max-width:$medium) {
      width: 100% !important;
      .page-actions ul li {
        width: 100% !important;
      }
    }
  }
  .page-actions {
    ul {
      display: flex;
      align-items: center;
      padding: 0px;
      margin: 0 -5px;
      li {
        a{
          text-decoration: none;
        }
        list-style-type: none;
        margin: 0 5px;
        @media screen and (max-width:$medium) {
          margin-bottom: 5px;
        }
        .form-group {margin: 0px;
          .form-label {display: none;}
          .form-control {
            height: 40px; 
          }
        }
        .form-group .simpleselect.multi_select {
          min-height: 40px;
          .simpleselect__control {
            min-height: 38px;
          }
        }
        .form-group.simpleselect_container {
          min-height: 40px;
          .simpleselect , .simpleselect__control {
            min-height: 40px;
            height: 40px;
          }
        }      
        .simpleselect_container {
          .MuiAutocomplete-root  {
            width: 200px;
            // .MuiFormControl-root  {
            //   .MuiFormLabel-root  {
            //     padding-left: 30px;
            //   }
            //   .MuiInputBase-root {
            //     .MuiInputBase-input {
            //       padding-left: 30px !important;
            //     }
            //   }
            // }
            // .MuiOutlinedInput-notchedOutline {
            //   background: url('../images/landing/location.svg') no-repeat 10px center;
            //   padding-left: 30px;
            //   background-size: 10px;
            // }
          }
        }  
      }
    }
    &.upload_actions {
      @media screen and (max-width:$medium) {
        ul {
          flex-direction: row;
        }
      }
    }
  }
  .title_wrap {
    .mobile_actions {
      display: none;
    }
  }
  @media screen and (max-width:$extraExtraLarge) {
     height: auto;
     padding: 20px 0 10px;
     .page-actions-wrap{
      flex-wrap: wrap;
      .page-actions{
        ul {
          li{
            margin-bottom: 10px;
          }
        }
      }
     }
  }
  @media screen and (max-width:$medium) {
    flex-direction: column;
    align-items: flex-start;
    .page-actions-wrap{
      width: 100%;
    }
    .page-title{
      margin-bottom: 20px;
    }
  }
  // @media screen and (max-width: $small) {
  //   .page-title{ 
  //     margin-bottom: 0;
  //   }
  // }
  @media screen and (max-width: $medium) {
    padding: 20px 15px;
    .page-actions-wrap{
      flex-direction: column-reverse;
      align-items: stretch;
      width: 100%;
      &.page-actions-v2 {
        width: 100%;
      }
    }
    .page-actions {
      position: relative;
      ul{
        flex-direction: column-reverse;
        align-items: stretch;
        li {
          .form-group .form-control{
            width: 100%;
          }
          .filters-switch {
            display: inline-flex;
            .switch-label{
             
            }
          } 
          .add_job {
            position: absolute;
            right: 0px;
            bottom: calc(100% + 10px);
          }
        }
      }
    } 
    .title_wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      .page-title {
        margin-bottom: 0;
      }
      .mobile_actions {
        display: flex;
        align-items: center;
        .filter_actions_btn {
          width: 40px;
          height: 40px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          margin-right: 115px;
          svg {
            width: 30px;
          }
        }
      }
    }
  }
}

.no_sidebar {
  .page-header {
    .page-title {white-space: nowrap; padding-right: 20px;}
    .page-actions-wrap {width: 100%;}
  }
  .page_wrapper {    
    .main_details_sec {
      width: 100%;
      padding-left: 0px;
    }
  }
}


.page-loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  font-family: $primaryfontRegular;
  font-size: 24px;
  color: $primary-color;
  background: #F8F8F8;
  z-index: 999;
  .b-loader {
    width: 100%;
    height: 100%;
    border: 3px solid $secondary-color;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  &.page-loader_v2 {
    background: rgba($white,0.6);
  }
}


.modal-footer {
  justify-content: flex-start;
  .btn-primary {
      width: auto !important;
  }
}

.form-labels {
  @include formLabel;
}
.radio-buttons {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .form-check {
      margin: 0 10px 0 0;
      cursor: pointer;
      .form-check-input {
        width: 18px;
        height: 18px;
        border: 1px solid #8A898966;
        box-shadow: none !important;        
        &:checked {
          background-color: #4D575D;
          border-color: #4D575D;
        }
      }  
      .form-check-label {
        cursor: pointer;
      }    
    }
}



.tabs-sec {
  padding: 20px 0px;
  .nav-tabs { 
    justify-content: center;
    align-items: flex-end;
    border: none;
    position: relative;
    height: 158px;
    &:before{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: transparent linear-gradient(96deg, rgba($color: #F15B28, $alpha:0.8) 0%, rgba($color: #F1A428, $alpha:0.8) 100%);
      border-radius: 6px 6px 0 0;
      color: $white;
      z-index: 1;      
    }
    &:after{
      content: 'Start by building your profile to help find the best job for you!';
      width: 100%;
      max-width: 100%;
      color: $white;
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      top: 14px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      padding: 30px 15px;
      line-height: 24px;
      font-size: 24px;
      font-family: $secondaryfontMedium;
      @media screen and (max-width:575px) {
        line-height: 26px;
        font-size: 20px;
      }
    }
    .nav-item {
      margin: 0 5px;
      z-index: 2;
      .nav-link {
        color: $white;
        font-family: $primaryfontRegular;
        font-size: 14px;
        border-radius: 6px 6px 0px 0px;
        background: rgba($color: $black, $alpha: 0.1);
        border-radius: 6px 6px 0px 0px;
        height: 50px;
        padding: 0 40px;
        border: none;
        &.active {
          color: $primary-color;
          background: $white;
        } 
      }
    }
  }
  .tab-content {
    padding: 30px 40px;
    background: $white;
    .form-container {
      .action_buttons .action_btn_container{
        max-width: 900px;
        padding: 0 10px;
      }
      .form-group{
      //  margin-bottom: 30px;
      }
      // .files-upload {
      //   .fileuploader {
      //     .files-list {
      //       margin-bottom: 30px;
      //       position: relative;
      //       ul {
      //         position: absolute;
      //         bottom: -40px;
      //       }
      //     }
      //   }
      // }
      form > .row {
        max-width: 900px;
        margin: 0 auto;
      }
      .btn-primary{
        max-width: 100px;
      }
      .radio-buttons{
        position: relative;
        margin-bottom: 30px;
        display: block;
        .form-error{
          @include formError;
        }
        .form-check{
          // width: 95px;
          // height: 45px;
          // border: 1px solid #07243033;
          // border-radius: 6px;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          padding: 0;
          position: relative;
          display: inline-block;
          .form-check-input{
            visibility: hidden;
            position: absolute;
            width: 100%;
            height: 100%;
          }
          .form-check-label{
            font-family: $primaryfontRegular;
            color: $primary-color;
            font-size: 15px;
            width: 95px;
            height: 45px;
            border: 1px solid #07243033;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin-left: 0px;
          }
          .form-check-input[value="true"]:checked ~ .form-check-label{
            border: 1px solid #00CC37;
            background: url('@/../../images/icons/checkmark_yes.svg') no-repeat left 20px center;
            padding-left: 10px;
          }
          .form-check-input[value="false"]:checked ~ .form-check-label{
            border: 1px solid #FA939F;
            color: #FF364D;
          }
        }
      }
      .add_job {
        width: auto;
        margin-bottom: 20px;
        margin-top: -12px;
        margin-left: 12px;
      }
    }
    @media screen and (max-width: $medium) {
      padding: 30px 10px;
    }
  }
  .row_lable {
    padding: 15px 0;
    border: none;
    background: #FFECE6;
    margin: 20px -40px 20px;
    span{
      font-family: $primaryfontMedium;
      color: $primary-color;
      font-size: 15px;
      max-width: 900px;
      margin: 0 auto;
      padding: 0 10px;
      display: block;
      position: relative;
      &:after{
        top: calc(100% + 15px);
        left: 20px;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(14, 29, 72, 0);
        border-top-color: #FFECE6;
        border-width: 10px;
        margin-left: -8px;
      }
    }
    @media screen and (max-width: $small)  {
      margin: 20px -12px 20px;
    }
  }
  .qs-title {
    font-family: $secondaryfontMedium;
    font-size: 15px;
    color: $primary-color;
    margin-bottom: 15px;
    max-width: calc(900px - 24px);
    margin: 0 auto 20px;
    padding: 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #C8CFD2;
    .qs-info{
      color: $base-color;
      font-size: 11px;
      max-width: 215px;
      text-align: right;
      line-height: 16px;
    }
  }
  @media screen and (max-width: $medium) { 
    padding: 20px 10px;
    .nav-tabs { 
      &:after {
        padding: 22px 15px;
      }
      .nav-item .nav-link {
        padding: 0 20px;
      }
    }
  }
  &.profile-tabs {
    .nav-tabs {padding-bottom: 0px;
      .nav-link.active:after{
        display: none;
      }
    }
    @media screen and (max-width:$medium) {
      padding: 0;
      .row_lable {
        margin: 20px -10px 20px;
      }
      .tab-content {
        padding: 30px 10px;
      }
    }
  }
}

.trade-info {
  background: $base-color6;
  max-width: 875px;
  margin: 0 auto;
  padding: 20px 20px 5px 20px; 
  margin-bottom: 25px;
  position: relative;
  .checkbox-list {
    margin-bottom: 20px;
    .category_heading {
      font-family: $primaryfontMedium;
      font-size: 14px;
      color: $primary-color;
    }
    ul {
      padding: 0px;
      margin: 0px;
      overflow: auto;
      max-height: 150px;
      li {
        list-style-type: none;
        padding: 3px 0;
      }
    }
  }
  .delete-sec {
    position: absolute;
    top: -5px;
    right: -5px;
    background: $primary-color;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    svg path {
      fill: $white;
    }
  }
}

.action_buttons{
  background: $white;
  border-radius: 6px;
  padding: 20px;
  .action_btn_container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 830px;
      margin: 0 auto;
      .btn{
          width: 140px;
          margin-left: 10px;
          &.btn-primary:focus-visible{
              background: $secondary-color;
              box-shadow: none;
          }
          &:only-child{
              margin-left: auto;
          }
          &:nth-child(1) {
            margin-left: 0px;
          }
          @media screen and (max-width:$large) {
            width: auto;
            &.btn-primary {
              font-size: 14px;
              padding: 0 10px;
            }
          }
      }
      .back-btn{
          border: 1px solid $base-color;
          border-radius: 6px;
          background: $white;
          color: $base-color;
          &:hover {
            background: $base-color;
            color: $white;
          }
      }
      .btn-secondary {
        @include cancelBtn;
      }
  }
}

.nodatafoundtext{
  background: $white;
  background: url('@/../../../assets/images/no_data.jpg')  no-repeat center 70px $white;
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $primaryfontMedium;
  font-size: 20px;
  padding-top: 175px;
  background-size: 300px;
}

.db_main {padding-top: 20px;}



.ql-editor {min-height: 100px; max-height: 300px;}
.emailbody .ql-editor {min-height: 240px;}



.text-editor{
  margin-bottom: 20px;
  position: relative;
  .form-label{
      @include formLabel;
  }
  .text-editor-outer{
      @include formField;
      height: auto;
      .ql-toolbar.ql-snow{
          border: none;
      }
      .ql-container.ql-snow{
          border: none;
          min-height: 100px;
          overflow: auto;
          padding:10px 20px;
          .ql-editor{
              font-family: $primaryfontRegular;
              font-size: 15px;
              color: $primary-color;
              padding: 0;
          }
      }
      .form-error {
        @include formError;
      }
  }
}


.details_list {
  h5 {
    font-family: "Roboto Medium";
    font-size: 14px;
    color: #007192;
    display: block;
    margin-bottom: 5px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    margin: 0px;
    li {
      display: block;
      width: 50%;
      padding-bottom: 25px;
      span {
        font-family: $primaryfontRegular;
        font-size: 12px;
        color: $base-color;
        display: block;
       
      }
      label {
        font-family: $primaryfontRegular;
        font-size: 14px;
        color: $primary-color;
        display: block;
        padding: 0px 20px 0px 0px;
        margin: 0px;
      }
      &.listtitle {
        width: 100%;
        padding-bottom: 10px;
        span {
          font-family: $primaryfontMedium;
          font-size: 14px;
          color: $secondary-color;
        }
      }
      &.pb0{
        padding-bottom: 0px;
      }
    }
  }
  &.skills {
    span {
      font-family: "Roboto Medium";
      font-size: 14px;
      color: #007192;
      display: block;
      margin-bottom: 5px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0px;
      margin: 0px 0 20px 0;
      list-style-type: disclosure-closed;
      padding: 0 0 0 18px;
      color:#222D35;
      li {
        width: 33.3%;
        font-family: $primaryfontRegular;
        color:#222D35;
        padding: 0 0 6px 0;
        font-size: 14px;    
        display: list-item;
      }
    }
  }
}

 
.ratings-view {
  width: calc(100% - 400px);
  p {
    background: transparent linear-gradient(270deg, #FF5D5E 0%, #FFBD3C 33%, #2FD1B9 67%, #A5D12F 100%) 0% 0% no-repeat padding-box;
    display: block;
    width: 100%;
    height: 5px;
    border-radius: 20px;
    margin: 0px;
    position: relative;
    span {
      position: absolute;
      left: calc(0% - 12px);
      border: 2px solid $white;
      width: 24px;
      height: 24px;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -10px;
      background: $black url('../images/main/checkmark.svg') no-repeat center center;
      z-index: 1;
      &.one {left:calc(0% - 9px);}
      &.two {left: calc(25% - 12px);}
      &.three {left: calc(50% - 12px);}
      &.four {left: calc(75% - 12px);}
      &.five {left: calc(100% - 15px);}
    }
  }
  label {
    display: flex;
    justify-content: space-between;
    padding-top: 7px;
    small {
      font-family: $primaryfontRegular;
      font-size: 12px;
      color: rgba($primary-color,0.7);
    }
  }
}
.rate-title {
  font-family: $primaryfontRegular;
  font-size: 18px;
  color: $primary-color;
  padding: 10px 30px;
  margin: 20px -30px 0;
  background: rgba($base-color , 0.2);
  position: relative;
  &:after {
    top: 100%;
    left: 40px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(232, 231, 231, 0);
    border-top-color: rgba($base-color , 0.2);
    border-width: 10px;
    margin-left: -10px;
  }
}
.mt20 {margin-top: 20px;}
  

.loader-cnt {
  width: 100px;
  height: 100px;
  position: relative;
  .loader-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50% , -50%);
    display: block;
    height: 50px;
  }
}



@keyframes rotation {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
} 

.profile_img {
  margin-bottom: 20px;
  .form-label {
    display: block;
    font-size: 14px;
    margin-bottom: 3px;
    line-height: 16px;
    font-family: $primaryfontMedium;
    color: rgba($primary-color,0.7);
  }
  .fileuploader {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    margin:0 auto 10px;
    border: 2px dashed rgba(34, 45, 53, 0.4);
    .files-dropzone-list {
      width: 100% !important;
      height: 100% !important;
      border-radius: 50%;
      font-size: 0px;
      position: absolute;
      top: 0;
      left: 0;
      border: 0px;
      opacity: 0;
      z-index: 999;
      img {margin: 0px;}
    }
    .files-list {
      
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      img {
        max-width: 90px;
      }
    }
  }
  display: block;
  text-align: center;
}
.profile_info figure {
  border-radius: 50%;
  overflow: hidden;
  img {
    max-width: 100%;
    min-height: 100%;
  }
}

.relative {
  position: relative;
}

.padt30 {padding-top: 30px}
.w-full {width: 100% !important;}
.mr-0 {margin-right: 0px !important;}


.tooltip  {
  .tooltip-inner {
    background-color: $primary-color;
    font-family: $primaryfontRegular;
    font-size: 12px;
    color: $white;
    max-width: 300px;
  }
  &.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: $primary-color
  }
}

.uploads_list {
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    margin: 0px;
    li {
      list-style-type: none;
      .file_icon {
        width: 70px;
        height: 70px;
        border: 1px solid rgba($base-color , 0.2);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden; 
        .file_actions {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;     
          display: none;   
          span {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            background: rgba($primary-color,0.9);
            font-family: $primaryfontMedium;
            font-size: 12px;
            color: $white;
            cursor: pointer;
            &:hover {
              background: $primary-color;
            }
          }
        }
      }
      
     
      
      &:hover {
        .file_actions {display: block;}
      }
    }
  }
}


.cnt-loader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba($primary-color,0.5);
  z-index: 9;
}

.forums_wrap {
  width: 100%;
  .Jobs-list {
    width: 100%;
    .job-list .job-item {
      display: block;
      .job-info {
        display: flex;
        align-items: center;
        padding: 0;
      }
      .forum-info {
        ul {
          display: flex;
          align-items: center;
          padding: 0px;
          margin: 0px;
          li {
            display: flex;
            align-items: center;
          }
        }
      }
      @media screen and (max-width:$small) {
        .job-list {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}



.events_wrap {
  display: flex;
  //align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -7px; 
  .evernt-cards {
    width: 25%;
    padding:0 7px 14px 7px;
    min-height: 100%;
    .event-card {
      width: 100%;
      background: $white;
      border-radius: 6px;
      border: 1px solid #F2F2F2;
      min-height: 100%;
      .event-img {
        position: relative;
        display: block;
        figure {
          width: 100%;
          height: 125px;
          //http://localhost:3000/events image height
          //background: #fafafa;
          // background: url('@/../../images/events_banner.png') no-repeat 0 0;
           background-size: cover;
          padding: 0px;
          margin: 0px;
          overflow: hidden;
          background: #fafafa;
          img { 
            height: auto;
            width: 100%;
            margin: 0 auto;
          }
          .without_img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            min-height: 125px;
            background: url('../images/event_bg.jpg') no-repeat 0 0;
            background-size: cover;
            font-family: $primaryfontMedium;
            color: rgba($primary-color , 0.6);
            font-size: 35px;
            text-transform: uppercase;
          }
        }
        .status {
          padding: 0 15px;
          height: 26px;
          background: $white;
          border-radius: 16px;
          color: #072430;
          font-size: 14px;
          font-family: $basefontMedium;
          display: flex;
          align-items: center;
          position: absolute;
          bottom: 10px;
          right: 10px;
          text-transform: capitalize;
        }
        .qr_code {

        }
      }
      .event-info {
        display: block; 
        padding: 15px 10px 15px 15px;
        h4 {
          font-family: $neutralfontMedium;
          font-size: 18px;
          color: #0D0D0D;
        }
        .date {
          color: #007191;
          font-family: $basefontMedium;
          font-size: 13px;
          display: block;
          background: url('../images/icons/calendar.svg') no-repeat left center;
          padding: 0 0 0 22px;
          line-height: 16px;
          margin-bottom: 10px;
          b {
            font-family: $primaryfontBold;
            font-weight: normal;
          }
        }
        .update_on {
          font-family: $neutralfontMedium;
          font-size: 12px;
          color: #6D6E70;
          margin-bottom: 15px;
          display: block;
          b {
            font-weight:normal;
            font-family: $neutralfontBold;
          }
        }
        .service_cnt {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          // .mode {
          //   padding: 0 15px;
          //   height: 25px;
          //   background: #E6F9FF;
          //   border-radius: 16px;
          //   color: #072430;
          //   font-size: 14px;
          //   font-family: $basefontMedium;
          //   display: flex;
          //   align-items: center;    
          // }
          // .service {
          //   color: #072430;
          //   font-family: $basefontMedium;
          //   font-size: 14px;
          //   display: block;
          //   padding-left: 10px;
          // }
          span { 
            background: #E6F9FF;
            padding: 0 10px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            color: $primary-color;
            font-size: 13px;
            font-family: $basefontMedium;
            padding: 0px 15px 0 30px;
            margin: 0px 5px 0 0;
            border: 0px;
            height: 25px;
            &.virtual {
              background: url('../images/icons/virtual.svg') no-repeat 10px center #E6F9FF;
            }
            &.physical {
              background: url('../images/icons/physical.svg') no-repeat 10px center #E6F9FF;
            }
            &.paid {
              background: url('../images/icons/paid.svg') no-repeat 15px 6px #E6F9FF;
            }
            &.free {
              background: url('../images/icons/free.svg') no-repeat 15px 6px #E6F9FF;
            }
          }
        }
        .pointer {
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .nav-tabs {
          padding-bottom: 20px;
          margin-bottom: 5px;
          display: flex;
          flex-flow: nowrap;
          overflow: auto;
          .nav-item {
            white-space: nowrap;
          }
        }
      }
      
      .actions {
        display: flex;
        align-items: center;
        margin: 0px 0 0 0;
        flex-wrap: wrap;
        a {
          font-family: $basefontMedium;
          font-size: 16px;
          display: flex;
          align-items: center;
          height: 32px;
          padding: 0 20px;
          border-radius: 6px;
          margin-right: 10px;
          margin-bottom: 5px;
          cursor: pointer;
          white-space: nowrap;
          &.edit {          
            background: #007192; 
            color: $white;      
            &.w-full {
              justify-content: center;
            }    
          }
          &.delete {
            background: transparent;
            border: 1px solid #007192;            
            color: $primary-color;  
            line-height: 12px;
            text-align: center;
            font-size: 15px;   
              
          }
          &.deleteV2 {
            width: calc(50% - 10px);
            padding: 0;
            justify-content: center;
            font-size: 13px;
          }

        }        
      }      
    }
  }
  @media screen and (max-width:$extraLarge) {
    .evernt-cards {
      width: 33.3%;
    }
  }
  @media screen and (max-width:$medium) {
    .evernt-cards {
      width: 50% !important;
    }
  }
  @media screen and (max-width:$small) {
    .evernt-cards {
      width: 100% !important;
    }
  }
}


.fileuploader .files-list {
  position: relative;
    top: 2px;
    width: 100%;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin-top: 15px;
    ul li.upload__img {
      width: 100%;
      margin: 0;
      border-radius: 7px;
      .remove_btn {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 30px;
        height: 30px;
        padding: 0;
        background: #fff;
        border-radius: 6px;
        padding-bottom: 8px;
      }
      figure {
        height: 83px;
        display: block;
        text-align: center;
        background: transparent;
        margin: 0;
        width: 100%;
        img {
          width: auto;
          height: 100%;
        }
      }
      &.grid_type {
        height: auto;
        width: auto;
        margin: 0 18px 20px 0;
        position: relative;
        padding: 0;
        .remove_btn {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          padding: 0;
          border: 0px!important;
          img {
            width: 100%!important;
          }
        }
        figure {
          height: 100px;
          img {
            width: 100%;
          }
        }
        .preview-img {
          border-radius: 6px;
        }
      }
    }
    &.multi{
      position: relative;
    }
}

.profile_img {
  .fileuploader {
   overflow: visible;
    .files-list {
      position: absolute;
      top: 0px;
      width: 100%;
      left: 0px;
      width: 100%;
      height: 100%;
      overflow: visible;
      margin: 0;
      ul li.upload__img {
        width: 100%;
        margin: 0;
        padding: 0px;
        border-radius: 0;
        background: transparent;
        .remove_btn {
          position: absolute;
          top: -2px;
          right: -2px;
          width: 30px;
          height: 30px;
          padding: 0;
          background: #fff;
          border-radius: 50%;
          padding-bottom: 8px;
        }
        figure {
          height: 100%;
          display: block;
          text-align: center;
          background: transparent;
          margin: 0;
          width: 100%;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: auto;
            height: 100%;
          }
        }
      }
    }
    .preview-img {
        width: 100%;
        border-radius: 50%;

    }
  }
}


.pagination{
  justify-content: flex-end;
  margin: 0;
  .page-item {
    .page-link {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $primaryfontRegular;
      font-size: 13px;
      color: $primary-color;
      border: 0px;
      background: transparent;
      &:focus {
          box-shadow: none;
      }
      &.active {
          background: #65667B;
          color: $white;
      }
    }
    &:nth-child(1) .page-link {
      background: url('../images/icons/right-arrow.png') no-repeat center;
      font-size: 0px;
      background-size: 16px auto;
    }
    &:nth-last-child(1) .page-link {
      background: url('../images/icons/left-arrow.png') no-repeat center;
      font-size: 0px;
      background-size: 16px auto;
    }
    &.disabled {
      opacity: 0.5;
    }
    &.active .page-link{
      background: #65667B;
      color: $white;
  }
  }
  .active>.page-link, .page-link.active{
    border: 1px solid $base-color5;
    background: $white;
    color: $primary-color;
    width: 32px;
    height: 32px;
    border-radius: 6px;
  }
}

.sb_profile {
  .sb_profile_cnt {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    .subscription_block {
      margin-bottom: 40px;
      h6 {
        font-family: $neutralfontMedium;
        font-size: 18px;
        color: $primary-color;
        padding: 0px;
        margin: 0 0 5px 0;
      }
      .sb_card {
        width: 100%; 
        border-radius: 6px;
        border: 1px solid rgba($secondary-color , 0.3);
        background: $white;
        .card_view {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px;
          .card-info {
            display: flex;
            align-items: center; 
            figure {
              width: 64px;
              height: 64px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgba($secondary-color , 0.05);
              border-radius: 50%;
              margin: 0px;
              img {
                display: block;
                max-width: 50px;
              }
            }
            p {
              display: block;
              margin: 0px 0 0 15px;
              font-family: $basefontMedium;
              font-size: 20px;
              color: $primary-color;
              display: flex;
              align-items: center;
              em {
                padding: 0 5px 0 3px;
              }
              span.exp {
                font-size: 16px;
                color: rgba($primary-color , 0.8);
                margin: 0 4px 0 0;
              }
            }
            &.personal-info {
              figure img {
                width: 100%;
                max-width: inherit;
              }
              .personal_info {
                padding-left: 20px;
                label {
                  font-family: $basefontMedium;
                  font-size: 20px;
                  color: $primary-color;
                  display: block;
                  line-height: 20px;
                  margin-bottom: 5px;
                }
                span {
                  font-family: $basefontMedium;
                  font-size: 16px;
                  color: #6D6E70;
                  display: block;
                  line-height: 16px;
                }
              }
            }
          }
        }
        @media screen and (max-width:$medium) {
          .card_view {
            flex-direction: column;
            align-items: flex-end;
            .card-info {
              flex-flow: wrap;
            }
          }
        }
      }
    }
    .profile-tabs {
      padding: 10px 0 0 0;
      .nav-tabs {
        &:after {
          display: none;
        }
        height: auto;
        padding: 10px 0 0 0;
      }
    }
  }
}



.search_sec .search_cnt .form-group .simpleselect.multi_select .simpleselect__control {
  min-height: 44px;
  height: 58px !important;
  overflow: auto;
}


.without_img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 125px;
  background: url('../images/event_bg.jpg') no-repeat 0 0;
  background-size: cover;
  font-family: $primaryfontMedium;
  color: rgba($primary-color , 0.6);
  font-size: 35px;
  text-transform: uppercase;
}


.paymentcard_sec {
  width: 100%;
  //background: red;
  form {
    width: 450px;    
    .StripeElement {
      width: 100%;
      background: $white;
      padding: 20px;
      margin-bottom: 15px;
    }
    .primary-btn {
      width: auto;
      border-radius: 6px;
    }
  }
  .paymentcard_actions {
    display: flex;
    align-items: center;
    .btn {
      margin-right: 10px;
      height: 36px; 
      font-size: 14px;
    }
  }
}

.saved_card_sec {
  .saved_card {
    background: $white;
    padding: 20px;
    border-radius: 6px;
    width: 400px;
    margin-bottom: 10px;
    h4 {
      font-family: $neutralfontSemiBold;
      font-size: 18px;
      color: $primary-color;
      line-height: 22px;
    }
    label {
      font-family: $neutralfontMedium;
      font-size: 18px;
      color: $primary-color;
      line-height: 22px;
    }
    .cvv_info {
      display: flex;
      align-items: center;
      span {
        font-family: $neutralfontMedium;
        font-size: 16px;
        color: $primary-color;
        line-height: 22px;
        margin-right: 20px;
        display: block;
      }
  
    }

    
  }
  .primary-btn {
    width: auto;
    border-radius: 6px;
  }
  .card_view {
    display: block;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: $white;
    width: 450px;
    margin-bottom: 15px;
    border-radius: 6px;
    border: 1px solid #ddd;
    h5 {
      font-family: $neutralfontMedium;
      font-size: 18px;
      color: $primary-color;
    }
    .card-info {
      display: flex;
      align-items: center; 
      figure {
        width: 64px;
        height: 64px;
        min-width: 64px; 
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($secondary-color , 0.05);
        border-radius: 50%;
        margin: 0px 20px 0 0;
        img {
          display: block;
          max-width: 50px;
        }
      }
      label {
        display: block;
        margin: 0px 0 0 0px;
        font-family: $basefontMedium;
        font-size: 20px;
        color: $primary-color;
        display: flex;
        align-items: center;
        text-transform: uppercase;
      }
      .cvv_info {
        display: flex;
        align-items: center;
        span {
          font-family: $neutralfontMedium;
          font-size: 14px;
          color: rgba($primary-color , 0.8);
          line-height: 22px;
          margin-right: 20px;
          display: block;
        }
    
      }
      p {
        display: block;
        margin: 0px 0 0 15px;
        font-family: $basefontMedium;
        font-size: 20px;
        color: $primary-color;
        display: flex;
        align-items: center;
        em {
          padding: 0 5px 0 3px;
        }
      }
      // &.personal-info {
      //   figure img {
      //     width: 100%;
      //     max-width: inherit;
      //   }
      //   .personal_info {
      //     padding-left: 20px;
      //     label {
      //       font-family: $basefontMedium;
      //       font-size: 20px;
      //       color: $primary-color;
      //       display: block;
      //       line-height: 20px;
      //       margin-bottom: 5px;
      //     }
      //     span {
      //       font-family: $basefontMedium;
      //       font-size: 16px;
      //       color: #6D6E70;
      //       display: block;
      //       line-height: 16px;
      //     }
      //   }
      // }
    }
  }
}

.block_sub-title {
  font-family: $basefontMedium;
  font-size: 16px;
  color: $primary-color;
}

.plans-info {
  padding: 20px;
  border: 5px solid rgba($primary-color , 0.1);
  border-radius: 6px;
  position: relative;
  min-height: 190px;
  label {
    font-family: $neutralfontBold;
    font-size: 50px;
    color: $secondary-color;
    display: block;
    em{
      font-family: $primaryfontMedium;
      font-size: 30px;
      margin: 0px 2px 0px 0px;
      display: inline-block;
      position: relative;
      bottom: 2px;
      font-style: normal;
    }
    sub{
      font-family: $primaryfontMedium;
      font-size: 18px;
      bottom: 0px;
      margin: 0 0 0 -10px;
      color: $primary-color;
    }
  }
  span {
    font-family: $neutralfontMedium;
    font-size: 20px;
    color: $primary-color;
    padding: 0px;
    margin: 0px;
  }
  h6 {
    font-family: $neutralfontMedium;
    font-size: 20px;
    color: $primary-color;
    padding: 0px;
    margin: 0px;
  }
  p {
    font-family: $neutralfontMedium;
    font-size: 14px;
    color: $primary-color;
    padding: 0px;
    margin: 0px;
  }
  
  &.selected {
    border: 5px solid $primary-color;
    &:after {
      content: '';
      background: url('../images/icons/checkmark.svg') no-repeat $primary-color;
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0px;
      top: 0px;
      border-radius: 0 0 0 46px;
      background-size: 18px;
      background-position: 10px 4px;
    }
  }
}

.nocardTemplate {
  background: #FFF;
  width: 100%;
  padding: 0px;
  .line {margin: 0px;}
  .card_info {
    display: flex;
    align-items: center;
    .dp {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 15px;
    }
    .tg-cly1 {
      display: block;
      width: calc(100% - 95px);
      .line {
        margin-bottom:10px;
        max-width: inherit;
        &.small {
          width:80%;
          height: 13px;
          margin-bottom: 0px;
        }
      }
    }
    &.card_block {
      flex-direction: column;
      width: 200px;
      height: 180px;
      align-items: flex-start;
      justify-content: center;
      background: #fff;
      padding: 20px;
      .dp {
        width: 70px;
        height: 70px;
        margin: 0 0 20px 0;      
      }
      .tg-cly1 {
        width: 100%; 
        .line {
          height: 12px;
        }
      }
    }
  }
  &.no-bg {
    background: transparent;
  }
}

.community_page {
  background: #F9FAFB;
  .page-header .page-actions-wrap {
    width: calc(100% - 330px);
    .form-group .form-control.search {
      width:100%;
    }
    .page-actions {width:100%;
      ul { 
        li {width:100%;
          &:nth-child(1) {
            width: 70%;
          }
          &:nth-child(2) {
            width: 30%;
            position: relative;
            height: 40px;
          }
          .simpleselect_container {
            .MuiAutocomplete-root {
              width: 100%;
              position: absolute;
              top: 0px;
              right: 0px;
              background: #fff;
              .MuiFormControl-root {
                .MuiInputBase-root {
                  padding-right: 0;
                  padding-left: 9px;
                  .MuiInputBase-input {
                    padding-left: 0px !important;
                  }
                }
                
              }
              .MuiAutocomplete-endAdornment {
                top: 20px !important;
              }
            }
          }
        }
      }
    }
  }
  
  @media screen  and (max-width:$medium) {
    .db_main {
      .page-header {
        padding: 10px 0px;
        .page-actions-wrap {
          .page-actions {
            ul {
              margin:0;
              li {
                margin: 0 0 10px 0;
              }
            }
          }
        }
      }
      .communities_wrap {
        .communities_sec {
          .container {
            .communities_list {
              margin: 0px;
              .community-item {
                margin: 0;
                .community {
                  height: auto;
                  overflow: auto;
                  > figure {
                    width: 100%;
                  }
                  .swiper {
                    .swiper-wrapper {
                      .swiper-slide figure {
                        height: auto;
                        img {
                          width: 100%;
                          height: auto;
                          max-height: inherit;
                          max-width: 100%;
                        }
                      }
                    }
                  }
                  .community_info {
                    .community_actions {
                      flex-direction: column;
                      align-items: flex-start;
                      > div {
                        margin-bottom: 15px;
                      }
                    }
                  }                  
                }
              }
            }
          }
        }
      }
    }
  }
}
.communities_wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .communities_filters {
    width: 300px;
    position: sticky;
    top: 10px;
    background: $white;
    border-radius: 10px;
    .filters_head {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      border-bottom: 1px solid #0724300D;
      
      h5 {
        font-family: $basefontMedium;
        font-size: 20px;
        color: #072430;
        padding: 0px;
        margin: 0px;
      }
      span {
        font-family: $basefontMedium;
        font-size: 14px;
        padding: 0px;
        margin: 0px;
        color: #6D6E70;
        cursor: pointer;
      }
    }
    .filters_cnt {
      padding: 20px;
      .filters_list {
        h6 {
          font-family: $neutralfontMedium;
          font-size: 14px;
          color: $primary-color;
          margin: 0px 0 10px 0;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          list-style-type: none;
          padding: 0px;
          li {
            height: 24px;
            display: flex;
            align-items: center;
            padding: 0 12px;
            border: 1px solid #E2E2E2;
            border-radius: 24px;
            font-family: $neutralfontMedium;
            font-size: 12px;
            color: #6D6E70;
            margin: 0 5px 5px 0px;
            position: relative;   
            cursor: pointer;
            &.active {
              background: #E6F9FF;
            }          
          }
        }
        &.filters_list_update {
          .form-group {
            margin: 0 0 10px;
            .search {
              width: 100%;
              height: 35px;
            }
          }
          ul {
            max-height: calc(100vh - 234px);
            overflow: auto;
            li {
              min-height: 24px;
              height: auto;
              border-radius: 4px;
              padding: 0px 8px;
              width: 100%;
              border: 0px;
              .form-check-label {
                font-size: 13px;
              }
            }
            &::-webkit-scrollbar {
              width: 5px;
              height: 5px;
            }
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              -webkit-border-radius: 10px;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
              -webkit-border-radius: 10px;
              border-radius: 10px;
              background: rgba(255, 255, 255, 0.3);
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
            }
            
            &::-webkit-scrollbar-thumb:window-inactive {
              background: $primary-color;
            }
            
          }
        }
      }
    }
    @media screen and (max-width:$medium) {
      position: fixed;
      left: -350px;
      width: 300px;
      top: 60px;
      z-index: 9;
      height: 100vh;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1019607843);
      transition: all 0.25s ease-in-out;
      z-index: 999;
      border-radius: 0;
      .filters_cnt .filters_list.filters_list_update ul {
        max-height: calc(100vh - 280px);
      }
      .filters_content {
        position: relative;
        z-index: 99;
        background: $white;
        min-height: 100%;
      }
      &.open{
       left:0;
       .overlay {
        display: block;
        opacity: 1;
        visibility: visible;
        background: rgba($primary-color , 0.6);
        width: 100%;
        height: 100%;
        display: block;
        position: fixed;
        top: 60px;
        left: 0px;
       }
      }
      .filter_actions_btn{
           display: flex;
      }
       .filters_wrapper{
           height: 100%;
           border-radius: 0;
           position: relative;
           z-index: 999;
       }
   }
   .sibebar_overlay {
       opacity: 0;
       visibility: hidden;
       transition: 0.5s ease-in-out all;
       background: rgba($secondary-color , 0.5);
       width: 100%;
       height: 100vh;
       display: block;
       position: fixed;
       top: 60px;
       right: 0px;
   }
   &.open .sibebar_overlay {
       opacity: 1;
       visibility: visible;
   }
  }
  .communities_sec {
    width: calc(100% - 330px);
    padding-top: 0px;
    background: transparent;
    .container {
      padding: 0px;
    }
    .communities_list {
      .community-item {
        width: 100%;
        .community {
          width: 100%;
          display: flex;
          align-items: flex-start;
          height: 100%;
          figure {
            width: 220px;
            height: 100%;
            display: flex;
            align-items: center;
          }
          .community_info {
            width: calc(100% - 220px);
            h5 {
              font-size: 18px;
              padding: 0 0 5px 0;
              cursor: pointer;
            }
            .dis {
              font-family: $primaryfontRegular;
              font-size: 14px;
              color: #6D6E70;
              padding: 0px;
              margin: 0 0 12px 0;
              display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  max-height: 42px;
  /* Fallback for non-webkit */ 
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
            }
            .community_tags {
              display: block;
              width: 100%;
              h6 {
                font-family: $neutralfontSemiBold;
                font-size: 12px;
                color: rgba($primary-color , 0.7);
                margin: 0px 0 4px 0;
              }
              ul {
                display: flex;
                list-style-type: none;
                padding: 0px;
                li {
                  display: flex;
                  align-items: center;
                  padding: 3px 12px;
                  border: 1px solid #6D6E7027;
                  border-radius: 24px;
                  font-family: $neutralfontMedium;
                  font-size: 12px;
                  color: #6D6E70;
                  margin: 0 5px 0 0px;
                  position: relative;
                  em {
                    font-style: normal;
                  }
                  &.more_list {
                    height: auto;
                    cursor: pointer;
                    &:hover ol{
                      display: block;
                    }
                  }
                  ol {
                    border: 1px solid #6D6E7027;
                    position: absolute;
                    top: 100%;
                    left: 0px;
                    padding: 0;
                    margin: 0;
                    background: $white;
                    border-radius: 6px;
                    max-height: 120px;
                    overflow: auto;
                    display: none;
                    width: auto;
                    li {
                      border: 0px;
                      border-radius: 0px;
                      margin: 0px;
                      white-space: nowrap;
                    }
                  }
                }
              }
              &:hover {
                position: relative;
                z-index: 9;
              }
            }
            .community_actions {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .btn  {width: auto; padding: 0 20px; color: $white;
                &.secondary-btn  {
                  color: $primary-color;
                  &:hover {
                    color: $white;
                  }
                }
              }
            }
          }
          .swiper  {
            width: 220px;
            height: 100%;
          }
        }
      }
    }
    @media screen and (max-width:$large) { 
      .communities_list {
        .community-item {
          .community {
            align-items: flex-start;
            flex-direction: column;
            figure {
              width: 100%;
            }
            .swiper {
              width: 100%;
            }
            .community_info {
              width: 100%;
            }
          }
        }
      }
    }
  }
 
  @media screen and (max-width:$belowMedium) {
    width: 100%;
    flex-wrap: wrap;
    .communities_sec {
      width: 100%;
    }
  }
}


.offcanvas  {
  z-index: 9999;
}
.pad20 {
  padding: 20px !important;
}
.padlr20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.offcanvas-header-req {
  background: #F7F9FF!important;
  padding: 10px 20px!important;
  .offcanvas-title {
    font-family: "Rubik Regular";
    font-size: 16px;
    color: #072430;
  }
  .btn-close {
    background: url('../images/Close.svg') no-repeat center center!important;
    background-size: 12px!important;
  }
}
.tags__list {
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  margin: -10px -3px 10px -3px;
  li {
      list-style-type: none;
      display: flex;
      align-items: center;
      padding: 5px 10px 5px 15px;
      background: #F2F2F2;
      border-radius: 16px;
      min-height: 30px;
      font-family: $neutralfontMedium;
      font-size: 13px;
      color: $primary-color;
      margin: 0 3px 5px 0;
      em {
          width: 20px;
          height: 20px;
          background: url('../images/icons/close.svg') no-repeat center;
          display: block;
          cursor: pointer;
          margin-left: 10px;
      }
  }
}

.tool_tip {
  position: relative;
  z-index: 999999;
}

.events_modal  {
  .fileuploader .files-list {
    padding-top: 10px;
    ul li.upload__img {
      .remove_btn {
        border: 1px solid #ddd;
        border-radius: 50%;
        top: 0;
        right: -10px;
      }
      figure img {
        border-radius: 4px;
      }
    } 
  }
}

body .community_details_modal.offcanvas {
  .profile_info .profile_details.community_profile{
    label {
      font-family: "Roboto Medium";
      font-size: 16px;
      line-height: 18px;
      color: #072430;
      padding: 0 0 4px 0;
      margin: 0;
    }
    p {
      font-family: "Roboto Regular";
      font-size: 14px;
      color: #072430;
      padding: 0px 0 0 22px;
      margin: 0px;
      &.email { background: url('../images/icons/mail.svg') no-repeat left center;}
      &.address { background: url('../images/icons/location.svg') no-repeat left center;}
      &.phone { background: url('../images/icons/phone.svg') no-repeat left center;}
    }
  }
  .offcanvas-body {
    padding: 10px 20px 30px;
    .description {
      .data {
          padding-top: 20px;
      
          h5 {
            font-family: $primaryfontRegular;
            font-size: 13px;
            color: $secondary-color;
            padding: 0px;
            margin: 0px 0 5px 0;
          }
      
          p {
            font-family: $primaryfontRegular;
            font-size: 15px;
            line-height: 22px;
            color: $primary-color;
            padding: 0px;
            margin: 0 0 10px 0;
          }
      
          ul {
            li {
              font-family: $primaryfontRegular;
              font-size: 15px;
              line-height: 22px;
              color: $primary-color;
            }
      
            &.filters {
              display: flex;
              flex-wrap: wrap;
              margin: 0 -5px;
              padding: 0px;
      
              li {
                background: $base-color1;
                padding: 5px 20px;
                display: flex;
                margin: 0 5px 5px;
                border-radius: 20px;
                font-family: $primaryfontRegular;
                font-size: 14px;
      
                span {
                  margin: 0 0 0 15px;
                  cursor: pointer;
                  display: block;
                }
              }
            }
          }
      }
      .sub_title {
        padding: 0;
        margin: 20px 0 -10px 0;
        &:after {
          display: none;
        }
      }
      .swiper {
        padding: 10px 0 0 0;
        .swiper-wrapper .swiper-slide {
          height: 100%;
          width: auto;
          figure {
            margin: 0px;
            img {
              height: 100%;
              width: auto;
            }
          }
        }
      }
    }
  }
}



.community_details {
  
  .community_details_cnt {
    border: 1px solid rgba(138, 137, 137, 0.3);    
    border-radius: 4px;
    background: $white;
    padding-bottom: 20px;
    .profile_info {
      background: #ffffff;
      border: 0px;
      border-radius: 4px; 
      padding-bottom: 10px;
      align-items: center;
    }
    .description {      
      padding: 0 20px;
      width: 100%;
      .data {
          padding: 0 0 20px 0;      
          h5 {
            font-family: $primaryfontRegular;
            font-size: 13px;
            color: $secondary-color !important;
            padding: 0px;
            margin: 0px;
          }
          label {
            font-family: $primaryfontRegular;
            font-size: 14px;
            line-height: 22px;
            color: $primary-color;
            padding: 0px;
            margin: 0 0 0px 0;
          }
          p {
            font-family: $primaryfontRegular;
            font-size: 14px;
            line-height: 22px;
            color: $primary-color;
            padding: 0px;
            margin: 0 0 0px 0;
          }
      
          ul {
            li {
              font-family: $primaryfontRegular;
              font-size: 15px;
              line-height: 22px;
              color: $primary-color;
            }
      
            &.filters {
              display: flex;
              flex-wrap: wrap;
              margin: 5px -5px 0 -5px;
              padding: 0px;
      
              li {
                background: $base-color1;
                padding: 5px 20px;
                display: flex;
                margin: 0 5px 5px;
                border-radius: 20px;
                font-family: $primaryfontRegular;
                font-size: 14px;
      
                span {
                  margin: 0 0 0 15px;
                  cursor: pointer;
                  display: block;
                }
              }
            }
          }
          .description_info {
            font-family: $primaryfontRegular;
            font-size: 14px;
            color: $primary-color;
            padding: 5px 0 0 0;
            ul, ol {
              padding: 0 0 0 18px;
            }
          }
      }
      .sub_title {
        padding: 0;
        margin: 0 0 8px 0;
        &:after {
          display: none;
        }
      }
      .swiper {
        padding: 0;
        margin-bottom: 20px;
        .swiper-wrapper .swiper-slide {
          
          width: auto;
          figure {
            margin: 0px;
            img {
              height: 100%;
              width: auto;
            }
          }
        }
      }
    }
  }
  .emp-dwn{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .community_address{
    font-family: $primaryfontMedium;
    font-size: 13px;
    margin-top: 7px;
    display: flex;
    align-items: center;
    line-height: normal;
    img{
        margin-right: 5px;
    }
  }
}

.profile-info-sec.community_info_sec {
  .community_details_cnt {
    border: 1px solid rgba(138, 137, 137, 0.3);
    padding: 0 15px 20px;
    .description {
      .data {
        padding: 0 0 20px 0;      
        h5 {
          font-family: $primaryfontRegular;
          font-size: 13px !important;
          color: $secondary-color !important;
          padding: 0px;
          margin: 0px;
        }
        label {
          font-family: $primaryfontRegular;
          font-size: 14px;
          line-height: 22px;
          color: $primary-color;
          padding: 0px;
          margin: 0 0 0px 0;
        }
        p {
          font-family: $primaryfontRegular;
          font-size: 14px;
          line-height: 22px;
          color: $primary-color;
          padding: 0px;
          margin: 0 0 0px 0;
        }
    
        ul {
          li {
            font-family: $primaryfontRegular;
            font-size: 15px;
            line-height: 22px;
            color: $primary-color;
          }
    
          &.filters {
            display: flex;
            flex-wrap: wrap;
            margin: 5px -5px 0 -5px;
            padding: 0px;
    
            li {
              background: $base-color1;
              padding: 5px 20px;
              display: flex;
              margin: 0 5px 5px;
              border-radius: 20px;
              font-family: $primaryfontRegular;
              font-size: 14px;
    
              span {
                margin: 0 0 0 15px;
                cursor: pointer;
                display: block;
              }
            }
          }
        }
        .description_info {
          font-family: $primaryfontRegular;
          font-size: 14px;
          color: $primary-color;
          padding: 5px 0 0 0;
          ul, ol {
            padding: 0 0 0 18px;
          }
        }
      }
      .sub_title {
        font-family: "Roboto Medium";
        font-size: 14px;
        color: #007192;
        margin: 0 0 3px 0;
        &:after {
          display: none;
        }
      }
    }
  }
} 

.offcanvas.event_details_modal {
  min-width: 750px;
  width: 750px !important;
  .offcanvas-body {
    .events_wrap  {
      margin: 0;
      .evernt-cards {
        width: 100% !important;
        border: 0px;
        padding: 0;
        .event-card {
          border: 0px;
          .event-img figure {
            height: auto;
          }
          .event-info {
            h4 {
              padding: 10px 0 5px 0;
            }
            h5 {
              font-family: "Roboto Regular";
              font-size: 13px;
              color: #007192;
              padding: 0px;
              margin: 0;
            }
            p {
              font-family: "Roboto Regular";
              font-size: 14px;
              color: $primary-color; 
              display: block;
            }
            a{
              font-family: "Roboto Regular";
              font-size: 14px;
              color: $primary-color;
              text-decoration: underline;
              display: block;
            }
            .data {
              margin-bottom: 15px;
              ol, ul {
                padding: 0 0 0 20px;
                li {
                  font-family: "Roboto Regular";
                font-size: 14px;
                color: $primary-color;  
                }
              }
              .info_table {
                margin-top: 10px;
                thead {
                  tr {
                    th {
                      font-family: "Roboto Regular";
                      font-size: 14px;
                      color: #072430;
                      font-weight: normal;
                      background-color: #FFF6EC;
                      border: 0px;
                    }
                  }
                }
                tbody {
                  tr {
                    td {
                      font-family: "Roboto Regular";
                      font-size: 12px;
                      color: #072430;
                      font-weight: normal;
                      border: 0;
                    }
                    &:nth-child(even) {
                      td {
                        background-color: #FdFdFd;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width:$medium) {
    width:calc(100% - 30px) !important;
    min-width: inherit;
  }
}


.plans_sec {
  .events_wrap  {
    width: auto !important;
    .evernt-cards {
      width: 100%;
      .event-card {
        cursor: pointer;
        background: transparent;
        border: 0px;
        .plans-info {
          width: 450px;
          border-radius: 14px;
          background: $white;
          label {line-height: 60px;
            cursor: pointer;
            sub {    bottom: 2px;}
            em { line-height: 30px;}
          }
          p {
            font-family: $secondaryfontMedium;
          }
        }
        .plan_features {
          padding: 15px 0 0 0;
          ul {
            padding: 0px;
            list-style-type: none;
            margin: 0;
            li {
              font-family: $primaryfontRegular;
              color: $primary-color;
              font-size: 15px;
              background: url('../images/icons/checked.svg') no-repeat 0 0;
              padding: 0 0 10px 30px;
              background-size: 20px auto;
            }
          }
        }
      }
    }
  }
  
  @media screen and (max-width:$small) {
    padding: 0 20px;
    .events_wrap .evernt-cards .event-card .plans-info {
      width: 100%;
    }
  }
}


.offcanvas-body .community_details {
  display: flex;   
  justify-content: space-between;
  width: 100%;
  // border: 1px solid rgba($primary-color , 0.1);
  // border-radius: 6px;
  // background: $white;
  // margin-bottom: 20px;
  .emp_db_left {
    width: 260px;
    display: block;
    background: $primary-color;
    border-radius: 6px 0 0 6px;
    
    .emp_db_titles {
      padding: 20px 0 0 0;
      h5 {
        color: #55D2FE;
        font-family: $basefontBold;
        font-size: 18px;
        line-height: 21px;
        border-left: 3px solid #55D2FE;
        padding: 0 0 0 13px;
        margin-bottom: 10px;
      }
      ul {
        padding: 0 0 0 13px;
        list-style-type: none;
        li {
          margin:2px 0;
          a {
            padding: 10px 0 10px 15px;
            font-family: $neutralfontSemiBold;
            font-size: 14px;
            color: rgba($white , 0.8);
            margin: 0px;
            border-radius: 6px 0 0 6px;
            display: block;
            cursor: pointer;
            &:hover {
              background: $white;
              color: $primary-color;
            }
          }
          &.active a {
            background: $white;
            color: $primary-color;
          }
        }
      }
    }
  }

  .emp_db_right {
    width: 100%;
    padding: 20px;
    .db_right_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      .emp-title {
        display: flex;
        align-items: center;
        span.dp {
          width: 60px;
          height: 60px;
          border: 1px solid #E2E2E2;
          border-radius: 50%;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          img {
            width: 100%;
          }
        }
        ul {
          display: flex;
          align-items: center;
          padding: 0px;
          margin: 0px;
          list-style-type: none;
          li {
            margin: 0 10px 0 0;
            span {
              background: #E6F9FF;
              padding: 0 15px;
              border-radius: 14px;
              font-family: $neutralfontMedium;
              font-size: 12px;
              color: $primary-color;
              display: flex;
              align-items: center;
              height: 25px;
              svg {
                display: block;
                margin: 0 5px 2px 0;
              }
            }
            label {
              color: #6D6E70;
              font-family: $neutralfontMedium;
              font-size: 12px;
              padding: 0px;
              margin: 0px;
              display: flex;
              align-items: center;
              height: 25px;
              svg {
                display: block;
                margin: 0 5px 2px 0;
              }
            }
          }
        }
      }
      .emp-rt {
        width: 100%;
        .forum-discription {
          font-family: $neutralfontRegular;
          font-size: 15px; 
          color: #072430;
          padding: 10px 0 0 0;
          ol, ul {
            padding: 0 0 0 18px;
          }
        }
        // h4 {
        //   font-family: $primaryfontMedium;
        //   font-size: 14px;
        //   color: $primary-color;
        // }
      }
      .emp_actions {
        display: flex;
        align-items: center;
        .status {
          font-family: $neutralfontMedium;
          font-size: 16px;
          color: $primary-color;
          padding: 0px 0 0 24px;
          margin:0 0px 0 0;
          background: url('../images/tick.svg') no-repeat left center;
        }
        .more-menu {
          padding-right: 0;
          margin-left: 20px;
          .dropdown {
            .btn-secondary {
              width: 25px;
              height: 36px;
              background-color: #E6F9FF;
              border: 0px;
            }
          }
        }
      }
    }
    .emp_db_main {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .db_left_cnt {
        width: calc(100% - 240px);
        padding: 0 10px 0 0;
        .emp_counter {
          width: 100%;
          padding-bottom: 10px;
          ul {
            padding: 0px;
            list-style-type: none;
            display: flex;
            margin: 0 -5px;
            li {
              width: 25%;
              display: block;
              padding: 0 5px;
              .count-item {
                width: 100%;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #E6F9FF;
                border-radius: 6px;
                p {
                  strong {
                    display: block;
                    font-family: $neutralfontSemiBold;
                    font-size: 24px;
                    color: $primary-color;
                  }
                  font-family: $neutralfontMedium;
                  font-size: 14px;
                  color: #6D6E70;
                  padding: 0px;
                  margin: 0px;
                  text-align: center;
                }
              }
            }
          }
        }
        .skills_sec {
          display: flex;
          //align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 10px;
          .skill_block {
            width: calc(50% - 10px);            
            border: 1px solid #F5F5F5;   
            height: 230px;    
            .chart_cnt.vertical_chart_cnt ul {
              height: calc(100% - 20px);
              &.spacing {
                padding-top: 17px;
              }
            }     
            .vertical_chart_item .chart_value_cnt {
              height: calc(100% - 34px);
            }
            &.locations_block {
              height: auto;
              .chart_item .chart_name {
                line-height: 16px;
              }
            }
          }
        }
      }
      .emp_db_events {
        width: 240px;
        .events__block {          
          .swiper {
            padding-bottom: 30px;
            .event_card {
              border: 0px;
            }          
            .swiper-pagination {
              bottom: 10px;
              .swiper-pagination-bullet {
                border: 1px solid $secondary-color;
                &.swiper-pagination-bullet-active {
                  background: $secondary-color;
                }
              }
            }
          }          
        }
      }
    }
  }
  @media screen and (max-width:$medium) {     
    .emp_db_right {
      .emp_db_main {
        flex-direction: column;
        .db_left_cnt {
          width: 100%;
          padding: 0;
          margin-bottom: 20px;
          .emp_counter {
            ul li {
              width: 33.3%;
              .count-item p {
                font-size: 12px;
                strong {
                  font-size: 14px;
                }
              }
            }
          }
          .skills_sec {
            flex-wrap: wrap;
            .skill_block {
              width: 100%;
              &.locations_block {
                margin-bottom: 20px;
              }
            }
          }
        }
        .emp_db_events {
          width: 100%;
        }
      }
    }     
  }
}

.forum-details-modal {
  width: 850px !important;
  .description {
    width: 100%;
    padding: 20px;
    .job-title {
      padding: 0 0 5px 0;
      h4 {
        font-family: $neutralfontLight;
        font-size: 28px;
        color: $primary-color;
      }
    }
    .jobs-details {
      padding-bottom: 0;
    }
    .discussion_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      .discussion-title {
        margin-bottom: 0px;  
        font-family: "Roboto Medium";
        font-size: 20px;
        color: #072430;
        font-weight: normal;
        margin-bottom: 0px;
        padding: 0px 0 0 0px;      
      }
      .btn-primary {
        border-radius: 22px;
        border: 0px;
        height: 34px;
        font-size: 13px;
      }
    }
    .job-discussions {
      .discussion_list {
        padding: 0px;
        margin: 0px;
        border: 1px solid #fafafa;
       > li {
          background: $white;
          padding: 10px;
          align-items: flex-start;
          list-style-type: none;
          display: block;
          align-items: flex-start;
          justify-content: space-between;
          padding: 10px;
          &:nth-child(odd) {
            background: #fafafa;
          } 
          .dis_left {
            display: flex;
            align-items: center;
            width: 50%;
            figure {
              width: 40px;
              height: 40px;
              min-width: 40px;
              display: flex;
              align-items: center;
              border: 1px solid #ddd;
              border-radius: 50%;
              margin: 0px;
              overflow: hidden;
              img {
                max-width: 100%;
              }
            }
            .dis_info  {
              display: block;
              width: calc(100% - 40px);
              padding-left: 10px;
              .name {
                font-family: $basefontBold;
                font-size: 15px;
                line-height: 18px;
                color: #072430;
                padding: 0px 0 3px 0;
                margin: 0px;
              }
            }
          }
          .dis_right {
            display: flex;
            align-items: center;
            .status {
              background: $white;
              border-radius: 18px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 15px;
              font-family: $primaryfontMedium;
              font-size: 13px;
              color: #222D35;
              margin-right: 10px;
              border: 1px solid #00719233;
            }
            .more-menu .dropdown {
              background: $white;
              .dropdown-toggle {
                width: 28px;
                height: 28px;
                border: 1px solid #00719233;
                
              }
            }
            .simpleselect_container {
              margin-bottom: 0;
              margin-right: 10px;
              .simpleselect {
                height: 30px;
                .simpleselect__control {
                  height: 30px;
                  .simpleselect__value-container  {
                    padding: 0px 8px;
                    .simpleselect__input-container {
                      margin: 0px;
                    }
                  }
                }
              } 
              .simpleselect__indicators {
                .simpleselect__dropdown-indicator {
                  padding: 0px;
                  height: 28px;
                  width: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
          .discussion__cnt {
            padding: 0 0 0 50px;
            font-family: $neutralfontRegular;
            color: $primary-color;
            font-size: 14px;
            ul, ol {
              padding: 0 0 0 18px;
            }
          }
        }
      }
      .discussion_filters {
        display: flex;
        padding: 0;
        list-style-type: none;
        margin: 0 0 20px 0; 
        li {
          padding-right: 20px;
        }
      }
    }
  }
}



.offcanvas-backdrop {
  z-index: 99;
}
.offcanvas {
  z-index: 999;
}

body .events_block_v2 {
  figure {
    height: auto;
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
}

body .forums_table {
  border: 1px solid rgba(0, 113, 146, 0.33);
  border-radius: 10px;
  .table {
    margin: 0;
    thead {
      tr {
        th {
          background-color: #E6F9FF;
          padding: 8px 20px;
          font-family: $neutralfontMedium;
          font-size: 13px;
          color: #072430;
          font-weight: normal;
          &:nth-child(1) {
            border-radius: 10px 0 0 0;
          }
          &:nth-last-child(1) {
            border-radius: 0 10px 0 0;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 0px 20px;
          font-family: $neutralfontMedium;
          font-size: 14px;
          color: #072430; 
          background: $white !important;
          .btn-primary {
            border-radius: 18px;
            width: 70px;
          }
          &:nth-child(1) {
            cursor: pointer;
          }

          ul.list-items {
            display: flex;
            padding: 0;
            margin: 0px;
  
            li {
              list-style-type: none;
              margin-left: -5px;
              position: relative;
              figure {
                width: 32px;
                height: 32px;
                display: block;
                border: 2px solid $white;
                border-radius: 50%;
                overflow: hidden;
                padding: 0px;
                margin: 0px;
                img {
                  width: 100%;
                  height: auto;
                }
              }
              figcaption {
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
                background: $primary-color;
                color: $white;
                padding: 3px 10px;
                border-radius: 4px;
                white-space: nowrap;
                display: none;
                &:after {
                  top: 100%;
                  left: 50%;
                  border: solid transparent;
                  content: "";
                  height: 0;
                  width: 0;
                  position: absolute;
                  pointer-events: none;
                  border-color: rgba(7, 36, 48, 0);
                  border-top-color: #072430;
                  border-width: 6px;
                  margin-left: -6px;
                }
              }
              &:hover {
                z-index: 9;
                .figcaption {display: block;}
              }
            }
          }
        }
        &:nth-last-child(1) {
          td:nth-child(1) {
            border-radius: 0 0 0 10px;
          }
          td:nth-last-child(1) {
            border-radius: 0 0 10px 0px;
          }
        }
      }
    }
  }
  .table-responsive-lg {
    padding-bottom: 0px !important;
  }
}

.offcanvas.community_modal {
  z-index: 9999;
}

.min-h-auto {
  min-height: auto !important;
}

.pad0 {
  padding: 0px !important;
}

.community_info_sec {
  .skill_sec .skill_body {
    margin-bottom: 20px;
    ul li {
      .skill_body_left {
        width: 150px;
        padding-right: 10px;
        span {
          font-size: 13px;
        }
      }
      .ratings-view {
        width: calc(100% - 160px);
      }
    }
    @media screen and (max-width:$medium) {
      ul li {
        flex-direction: column;
        .skill_body_left {
          width: 100%;
          margin-bottom: 10px;
        }
        .ratings-view {
          width: 100%;
        }
      }
    }
  }
  .description {
    .data {
      padding: 0 0 20px 0;      
      h5 {
        font-family: $primaryfontRegular;
        font-size: 13px !important;
        color: $secondary-color !important;
        padding: 0px;
        margin: 0px;
      }
      label {
        font-family: $primaryfontRegular;
        font-size: 14px;
        line-height: 22px;
        color: $primary-color;
        padding: 0px;
        margin: 0 0 0px 0;
      }
      p {
        font-family: $primaryfontRegular;
        font-size: 14px;
        line-height: 22px;
        color: $primary-color;
        padding: 0px;
        margin: 0 0 0px 0;
      }
 
      ul {
        li {
          font-family: $primaryfontRegular;
          font-size: 15px;
          line-height: 22px;
          color: $primary-color;
        }
 
        &.filters {
          display: flex;
          flex-wrap: wrap;
          margin: 5px -5px 0 -5px;
          padding: 0px;
 
          li {
            background: $base-color1;
            padding: 5px 20px;
            display: flex;
            margin: 0 5px 5px;
            border-radius: 20px;
            font-family: $primaryfontRegular;
            font-size: 14px;
 
            span {
              margin: 0 0 0 15px;
              cursor: pointer;
              display: block;
            }
          }
        }
      }
      .description_info {
        font-family: $primaryfontRegular;
        font-size: 14px;
        color: $primary-color;
        padding: 5px 0 0 0;
        ul, ol {
          padding: 0 0 0 18px;
        }
      }
    
      ul {
        li {
          font-family: $primaryfontRegular;
          font-size: 15px;
          line-height: 22px;
          color: $primary-color;
        }
  
        &.filters {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -5px;
          padding: 0px;
  
          li {
            background: $base-color1;
            padding: 5px 20px;
            display: flex;
            margin: 0 5px 5px;
            border-radius: 20px;
            font-family: $primaryfontRegular;
            font-size: 14px;
  
            span {
              margin: 0 0 0 15px;
              cursor: pointer;
              display: block;
            }
          }
        }
      }
    }
    .sub_title {
      padding: 0px;
      margin: 0px;
    }
  }
}

.chat_bot {
  height: 50px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 45px;
  background-color: #007192;
  z-index: 99;
  color: $white;
  cursor: pointer;
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    background: url(../images/main/message_white.svg) no-repeat  center;
    width: 50px;
    height: 50px;
  }

  label {
    font-family: "gestamedium";
    width: 0px;
    opacity: 0;
    white-space: nowrap;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    cursor: pointer;
  }
  &:hover {
    label {
      width: 125px;
      opacity: 1;
      text-align: left;
      padding-left: 15px;
    }
  }
}

.chat_container {
  .chat_content {
    position: relative;
    width: 100%;
    height: 100vh; 

    .cs-message-list {
      .cs-message {
        .cs-message__content {
          font-family: "Roboto Regular";
          font-size: 14px;
          background-color: #e5f1f4;
          border-radius: 0 6px 6px 6px;
        }
      }
      .cs-message--outgoing {
        .cs-message__content {
          background-color: #F7F9FF;
          border-radius: 6px 0 6px 6px;
      }
    }
    }
    .cs-message-input {
      background-color: #F7F9FF !important;
  
      .cs-message-input__tools {
        order: 1;
      }
      .cs-message-input__content-editor-wrapper {
        background-color: #F7F9FF;
        .cs-message-input__content-editor {
        background-color: #F7F9FF;
        }
      }
    }
  }
}

.profile_dp_wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.profile_cards {
  display: flex;
  flex-wrap: wrap;

  .profile_dp {
    width: calc(50% - 10px);
    border: 1px solid rgba($primary-color , 0.1);
    border-radius: 6px;
    padding: 10px; 
    margin: 5px 5px 10px;
      .profile_icon {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        figure {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-bottom: 0px;
          background: $white;
          border: 1px solid rgba($primary-color , 0.6);
          overflow: hidden;
          img {
            display: block;
            max-width: 100%;
          }
          span {
            font-family: $primaryfontMedium;
            text-transform: uppercase;
            font-size: 15px;
            line-height: 10px;
            color: rgba(7, 36, 48, 0.6);
          }
        }
  
        .profile_name {
          display: block;
          font-family: $primaryfontMedium;
          font-size: 16px;
          line-height: 20px;
          color: $primary-color;
          padding: 0 0 0 15px;
        }
        .show-curser:hover{
          cursor: pointer;
        }
        .signup-box{
          margin-left: auto;
        }
        .sign-up {
          display: block;
          padding: 0 10px;
          height: 20px;
          background: #8A8989;
          border-radius: 16px;
          color: #ffffff;
          font-size: 12px;
          font-family: "gestamedium";
          text-transform: capitalize;
        }
  
      }
  
      .refer_name {
        font-family: $primaryfontLight;
        font-size: 12px;
        display: flex;
        white-space: nowrap;
        align-items: baseline;
        span {
          font-family: $primaryfontMedium;
          font-size: 11px;
          display: block;
          white-space: pre-wrap;
          line-height: 14px;
          margin-left: 10px;
  
        }
      }
    @media screen and (max-width: $small) {
      width: 100%;      
    }
  }
  &.profile_cardsv2 {
    .profile_dp {
      display: flex;
      align-items: center;
      .profile_icon  {
        width: auto;
        margin-bottom: 0px;
          margin-right: 15px;
        figure {
          
        }
      }
      .profile_name {
        font-family: $primaryfontRegular;
        font-size: 16px;
        color: rgba($primary-color , 0.8);
      }
      .show-curser:hover{
        cursor: pointer;
      }
    }
  }
}


.terms_sec {
  max-width: 1024px;
  margin: 0 auto;
  display: block;
  background: $white;
  padding: 30px 50px;
 
}



.terms_sec {
  h3 {
    color: $primary-color;
    font-size: 20px;
    text-align: center;
    font-family: $neutralfontBold;
    padding-bottom: 40px;
  }
  h4{
    color: $primary-color;
    font-size: 18px;
    font-family: $neutralfontBold;
    font-weight: normal;
    strong {
      font-family: $neutralfontSemiBold;
      font-weight: normal;
    }
  }
  h6{
    color: $primary-color;
    font-size: 16px;
    font-family: $neutralfontBold;
    font-weight: normal;
    strong {
      font-family: $neutralfontSemiBold;
      font-weight: normal;
    }
  }
  > u {
    color: $primary-color;
    font-family: $primaryfontRegular;
    font-size: 14px;
    font-weight: normal;
    display: block; 
    margin-bottom: 1rem;
    strong {
      font-family: $neutralfontMedium;
      font-weight: normal;
    }
  }
  p {
    color: $primary-color;
    font-family: $primaryfontRegular;
    font-size: 14px;
    font-weight: normal;
    display: block;
    strong {
      font-family: $neutralfontSemiBold;
      font-weight: normal;
    }
    u {
      color: $primary-color;
      font-family: $primaryfontRegular;
      font-size: 14px;
      font-weight: normal;
    }
  }
  ul {
    padding: 0 0 0 18px;
    li {
      color: $primary-color;
      font-family: $neutralfontRegular;
      font-size: 14px;
      font-weight: normal; 
      strong {
        font-family: $neutralfontMedium;
        font-weight: normal;
      }
      u {
        color: $primary-color;
        font-family: $primaryfontRegular;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
  a {
    color: #007192;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  > strong {
    font-family: $neutralfontMedium;
    font-weight: normal;
    font-size: 14px;
    color: $primary-color;
    margin-bottom: 1rem;
    display: block;
  }
  .table {
    thead {
      th {
        font-family: $neutralfontSemiBold;
        font-weight: normal;
        color: $primary-color;
        font-size: 14px;
        vertical-align: middle;
      }
    }
    tbody {
     tr {
      td {
        font-family: $neutralfontRegular;
        font-weight: normal;
        color: $primary-color;
        font-size: 14px;
      }
     }
    }
    &.threecol {
      thead {
        th {
          text-align: center;
        }
      }
      tbody {
        tr td {
          width: 33.3%;
        }
      }
    }
  }
}

.mob_tabs {
  display: none;
  @media screen and (max-width:$medium) {
    display: block;
    background: #f9fafb;
    border: 0px;
    margin: 0 -1px;
    ul {
      display: flex;
      padding: 0px;
      margin: 0px;
      list-style-type: none;
      padding: 0 1px;
      justify-content: space-between;
      width: 100%;
    //  justify-content: center;
      li {
        color: $primary-color;
        font-size: 14px;
        font-family: $neutralfontMedium;
        padding: 0 20px;
        height: 40px;
        display: flex;
        align-items: center;
        background: $white;
        border-radius: 10px 10px 0 0;
        margin: 0;
        width: calc(50% - 5px);
        justify-content: center;
        &.active  {
          background: #072430;
          color: $white;
          border-radius: 10px 10px 0 0;
        }
      }
    }
  }
}
@media screen and (max-width:$small) {
  // .maincontent .container {
  //   padding: 0px;
  // }
  // .mobp0 {
  //   padding: 0px !important;
  // }
  .mobplr0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.padl10 {padding-left: 10px !important;}

.swiper_slider_sec {
  > figure {
    width: 100%;
    margin: 0;
    
    img {
      width: auto;
      height: 130px;

    }
  }
  .swiper {
    padding: 0px 0 0 0;
    .swiper-wrapper .swiper-slide {       
      figure {
        margin: 0px;
        height: 150px;
        img {
          height: 100%;
          width: auto;
        }
      }
    }
  }
}



.nav-tabs {
  &::-webkit-scrollbar {
    width: 2px;
    border: 1px solid #d5d5d5;
    height: 4px;  
  }
  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: rgba($base-color4 , 0.5);
    height: 2px;
    display: none;
  }
  &:hover::-webkit-scrollbar-thumb  {
    display: block;
  }
}

.sign_logo {
  display: inline-block;
  img {
    max-width: 320px;
    margin: 0 auto 20px !important;
  }
}
.page-header {
  @media screen and (max-width:$medium) {
    padding: 20px 0px;
    .page-title {
      margin-bottom: 10px;
      font-size: 20px;
    }
    .page-actions-wrap {
      .page-actions {
        ul {
          margin: 0;
          li {
            padding: 0;
            margin: 0 0 5px 0;
          }
        }
      }
    }
    &.bulk-job-header {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 15px;
      .page-title {
        margin: 0 0 10px 0;
        font-size: 20px;
      }
      .page-actions {
        ul {
          flex-direction: row;
          flex-wrap: wrap;
          li {
            &.mob_position {
              position: absolute;
              bottom:100%;
              right: 0px;
              .add_job {
                position: static;
              }
            }            
          }
        }
      }
    }
  } 
}

.padl5 {
  padding-left: 5px !important;
}
.padr5 {
  padding-right: 5px !important;
}

.mt30 {
  margin-top: 30px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.padl25 {
  padding-left: 25px !important;
}

.base-Popper-root {
  z-index: 99999 !important;
}

.simpleselect_container {
  .MuiAutocomplete-root {
    width: auto;
    .MuiFormControl-root {
      .MuiFormLabel-root {
        transform: inherit;
        margin: 0;
        font-family: $primaryfontRegular;
        font-size: 14px;
        color: $primary-color;
        padding: 0 10px;
        line-height: 40px;
        color: rgba($color: $primary-color, $alpha: 0.6);
        @media screen and (max-width: 768px) {
          line-height: 45px;          
        }
      }
      .MuiInputBase-root {
        padding: 0;
        .MuiChip-root {
          height: auto;
          span {
            font-size: 12px;
            font-family: $primaryfontMedium;
            color: $primary-color;

          }
          .MuiSvgIcon-root {
            font-size: 12px;
          }
        }
        .MuiInputBase-input {
          padding: 0 !important;
          height: 40px;
          background: $white;
          font-family: $primaryfontRegular;
          font-size: 14px;
          color: $primary-color;
          padding: 0 10px !important;
        }
      }
    }
  }
}

.qr_sec {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  .status {
    position: static !important;
  }
  .qr_code {
    background: #fff;
    padding: 0 10px;
    border-radius: 35px;
    height: 26px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    img {

    }
    label {
      color: #072430;
      font-size: 14px;
      font-family: "gestamedium";
      margin-left: 5px;
    }
  }
}

.qr_show.modal-dialog .modal-content {
  .modal-header {
    border-bottom: 0;
    background: transparent !important;
  }
  .modal-body {
    .message {
      .dwnld_qr {
        width: 110px;
        padding: 5px 10px;
        border-radius: 20px;
        background: $primary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px auto;
        opacity: 1;
        cursor: pointer;
        span {
          font-family: $primaryfontMedium;
          font-size: 13px;
          color: $white;
          margin-left: 5px;
        }
      }
      p {
        font-family: $primaryfontRegular;
        font-size: 16px;
        color: $primary-color;
        text-align: center;
      }
    }
  }
}

ul.attendes_stats {
  display: flex;
    list-style-type: none;
    padding: 0px;
    margin: 0px 0px 10px -20px !important;
    li {
      width: 33.3%;
      padding: 0 5px;
    }
}

.register.external_login {
  justify-content: center;
  height: calc(100vh - 60px);
  background: #fff;
  align-items: center;
  .register_from {
    height: auto;
    min-height: auto;
    .register-head {
      margin-bottom: 20px;
    }
    .form-container {
      form {
        .para-text {
          font-family: $primaryfontRegular;
          font-size: 14px;
          color: $primary-color;
          margin-bottom: 5px;
        }
        .new_access {
          span {
            width: 100%;
            display: block;
            text-align: center;
            padding-top: 15px;
            font-family: $primaryfontMedium;
            font-size: 14px;
            color: $primary-color;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 380px){
  
  .respon-dev
  
  {   
     flex-wrap: wrap;
        li.oval-btn{
        padding: 0px 4px;
      }
      li.Apply{
        padding: 10px 8px !important;
  
        font-size: 12px !important; }

  }

}
.padig-0{padding: 0px !important;}
.list-with-dots{
  ul{
    list-style-type: none;
    padding: 0px;
    display: flex;
   
    li{
      position: relative;
      padding: 0px 6px 0 0px;
    
    label{ white-space: nowrap; font-size: 12px !important;
      display: block;
      position: relative;
      padding: 0 10px 0 0;
      &:after{
        position: absolute;width: 4px;height: 4px;background-color: #072430a1;content: '';
        right: 0px;
        top: 7px;
        border-radius: 100%;

      }
    }
    &:nth-last-child(1) {
      padding: 0px;
      label::after{
        display: none;
      }
    }
   
 

  }
 
  }
}
.closed-postin{
  position: relative;
  .emp-title{
    .emp-rt{
      ul{
        position: absolute;
        right: 0px;
        top: 0px;
      }
    }
  }
}
.job-deatails-width{    width: 100% ;}
.respon-dev{
  float: right;
}