@import "includes.scss";

.form-group {
    display: block;
    margin-bottom: 20px;
    position: relative;

    .form-label {
        @include formLabel;
        display: flex;
        align-items: center;
    }

    .form-control {
        @include formField;

        &:focus {
            @include formFieldFocus
        }

        &.email {
            background: url('../images/icons/mail.svg') no-repeat 15px center;
            padding-left: 40px;
        }

        &.phone {
            background: url('../images/icons/smartphone.svg') no-repeat 15px center;
            padding-left: 40px;
        }

        &.password {
            background: url('../images/icons/lock.svg') no-repeat 15px center;
            padding-left: 40px;
        }

        &.search {
            background: url('../images/icons/search.svg') no-repeat 15px center $white;
            padding-left: 40px;
            width: 220px;
        }
        &.tags  {
            background: url('../images/icons/tags.svg') no-repeat 15px center $white;
            padding-left: 40px;
            width: 100%;
        }
        &.location {
            background: url('../images/icons/location.svg') no-repeat 15px center $white;
            padding-left: 40px;
            width: 200px;
        }

        &::placeholder {
            color: rgba($primary-color , 0.5);
        }

        &.threashold {
            width: 75%;
        }

        &.w90 {
            width: 90%;
        }
        
          &.disabled{
            cursor: not-allowed;
            opacity: 0.9;
            background:#e8eaed
          }
       
    }

    .form-error {
        @include formError;
    }

    .password_view {
        position: absolute;
        right: 12px;
        bottom: 10px;
        cursor: pointer;

        img {
            width: 25px;
        }
    }

    textarea.form-control {
        height: 90px;
        overflow: auto;
        padding: 10px 20px;
        font-family: $primaryfontRegular;
        font-size: 15px;
        color: $primary-color;
    }
}

.terms_group {
    .terms-label {
        padding:0 0 0 10px;
        font-family: $primaryfontRegular;
        color: rgba($primary-color , 0.8);
        font-size: 13px; 
        margin: 0px;
        line-height: 27px;
        a {
            color: #007191;
            text-decoration: underline;
        }
    }
}

.percentage {
    position: absolute;
    right: calc(25% - 10px);
    top: 28px;
    font-family: $primaryfontMedium;
    color: $secondary-color;
    font-size: 18px;
}

.form-actions {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    margin: 0px;

    a {
        font-family: $primaryfontRegular;
        color: $primary-color;
        font-size: 14px;
        padding: 5px 0 0 0;
        margin: 0px;

        &:hover {
            color: $secondary-color;
        }
    }
}

.strengthbar {
    >div {
        margin-top: 0px !important;
    }

    >p {
        font-family: $secondaryfontRegular;
        font-size: 14px;
        text-transform: capitalize;
    }
}

.form-title {
    font-family: $primaryfontMedium;
    font-size: 14px;
    color: $primary-color;
    padding-bottom: 5px;
}

.address_sec {
    .form-title {
        padding-bottom: 8px;
    }
}

.salary-details {
    .simpleselect_container {
        &:first-child {
            width: 43%;
        }
        &:last-child {
            width: 55%;
        }
    }
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}


