body {
    .header {
        background: transparent linear-gradient(117deg, #007192 0%, #072430 100%) 0% 0% no-repeat padding-box;
        .nav-menu > ul > li {
            a {color: rgba($white , 0.8);
                &.sublist:after {border-top-color: rgba($white , 0.8);}                
            } 
            &.active {
                a {color: $white; 
                    .sublist:after {border-top-color: $white;}
                } 
            }  
            &:hover {
               > a {color: $white; 
                    &.sublist:after {border-top-color: $white; }
                }                
            }
            ol li a {
                color: $primary-color !important;
            }
        }
    }


    .dashboard .profile-setting {
        background: $bg-color ;
        .profile-container h3 {
            color: $primary-color;
        }
    }
    .db_main_wrapper .widget_item .widget_body .widget_list.recent_emp_list li .icon {
        background: transparent linear-gradient(117deg, #007192 0%, #072430 100%) 0% 0% no-repeat padding-box;
    }
    .offcanvas .offcanvas-header {
        background: transparent linear-gradient(148deg, #007192 0%, #072430 100%) 0% 0% no-repeat padding-box;
    }
    .tabs-sec .nav-tabs:before {
        background: $primary-color;
    }
}