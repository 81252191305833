@import "includes.scss";

.range-slider {
  margin-bottom: 50px;
  position: relative;
  .form-label {
    @include formLabel;
    font-family: $neutralfontMedium; 
    color: $primary-color;
    font-size:14px;
  }
  .form-range {
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 5px;
      position: absolute;
      border-radius: 20px;
      top: 0px;
      left: 0px;
      background: transparent linear-gradient(270deg, #A5D12F 0%, #2FD1B9 33%, #FFBD3C 67%, #FF5D5E 100%) 0% 0% no-repeat padding-box;
    }
  }
  .rangeslider {
    background: transparent linear-gradient(270deg, #A5D12F 0%, #2FD1B9 33%, #FFBD3C 67%, #FF5D5E 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(270deg, #FF5D5E 0%, #FFBD3C 33%, #2FD1B9 67%, #A5D12F 100%) 0% 0% no-repeat padding-box;
    box-shadow: none;
    height: 6px;
    margin:10px 0 15px;
    .rangeslider__fill {
      background-color: transparent;
    }
    .rangeslider__handle{
      width: 19px;
      height: 19px;
      background: $primary-color; 
      box-shadow: none;
      border: 2px solid $white;
      &::after {
        display: block;
        width: 8px;
        height: 8px;
        background: $white;
        border-radius: 100%;
        box-shadow: none;
        top: 4px;
        left: 4px;
      }
    }
    .rangeslider__labels {
      display: none;
    }
    .rangeslider__handle-tooltip {
      background-color: $primary-color;
      font-family: $neutralfontMedium;
      font-size: 14px; 
      color: $white; 
      border: 0px;      
    }
  }
  .slides {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0px;
    margin: 0px;
    position: absolute;
    top: calc(100% + 2px);
    width: 100%;
    span {
      font-family: $primaryfontMedium;
      font-size: 14px;
      color: #8A8989;
    }
  }
}


.horizontal-slider {
  background: transparent linear-gradient(270deg, #FF5D5E 0%, #FFBD3C 33%, #2FD1B9 67%, #A5D12F 100%) 0% 0% no-repeat padding-box;
  height: 6px;
  margin-top: 15px;
  margin-bottom: 20px;
  z-index: 0;
  .example-track {
    background: transparent;
    box-shadow: none;
    height: 6px;
  }  
  .example-thumb {
    width: 30px;
    height: 30px;
    background: $primary-color;
    margin-top: -12px;
    border-radius: 50%;
    font-size: 12px;
    font-family: $primaryfontMedium;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }  
}