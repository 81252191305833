@import "includes.scss"; 

.uploads-sec {
  width: 100%;
  display: block;
  background: white; 
  padding: 30px;
  margin-bottom: 20px;
}
.fileuploader {
  position: relative;
  .files-dropzone-list {
    border-radius: 6px;
    background: $white;
    border: 2px dashed #222D3566;
    height: 100px;
    display: flex;
    align-items: center;
    font-family: $secondaryfontLight;
    font-size: 18px;
    color: $primary-color;
    justify-content: center;
    cursor: pointer;
    img {
      width: 28px;
      margin-right: 10px;
    }
    span {
      color: #F15B28;
      padding-left: 5px;
    }
  }
  .files-list {
    ul { 
      display: flex;
      align-items: center;
      padding: 0px 0 0 0;
      margin: 0px;
      flex-wrap: wrap;
      li {
        list-style-type: none;
        display: flex;
        align-items: center;
        background: #F7F9FF;
        padding: 7px 10px 7px 15px;
        margin: 5px 5px 0px 0;
        border-radius: 20px;
        .filename {
          font-family: $primaryfontRegular;
          font-size: 14px;
          color: $black;
        }
        .file-delete {
          background: url('../../assets/images/Close.svg') no-repeat center;
          width: 20px;
          height: 20px;
          cursor: pointer;
          margin-left: 5px;
        }
      }
    }
    .remove_btn {
      
    }
  }
  @media screen and (max-width: $large) { 
    margin-bottom: 20px;
  }
  .preview-img {
    width: 100%;
    &.updated-profile {
      border-radius: 50%;
    }
  }
  .cnt_loader {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100px; 
    background: rgba($white , 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    .b-loader {
      width: 50px;
      height: 50px;
      border: 3px solid $secondary-color;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }
  }
}
.proceed_btn_sec {
  position: relative;
  display: inline-block;
  .cnt_loader {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%; 
    background: rgba($white , 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    .b-loader {
      width: 25px;
      height: 25px;
      border: 3px solid $secondary-color;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }
  }
}

.link-upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F8F8F8;
    height: 100px;
    padding: 0 20px;
    border-radius: 6px;
    .form-group {
      width: calc(100% - 130px);
      margin: 0;
      .form-label {
        display: none;
      }
      .form-control {
        height: 60px;
      }
    }
    .upload-btn {
      width: 120px;
      height: 60px;
    }
    .manual-upload {
      width: 100%;
      height: 60px;
      font-size: 20px;
      background: $white;
      border: 2px dashed rgba($base-color , 0.3);
      color: $primary-color;
      &:hover, &:focus {
        background: $primary-color;
        color: $white !important;
      } 
    }
  }


@media screen and (max-width:$medium) {
  .uploads-sec {
    
  }
}