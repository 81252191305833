@import "includes.scss";

.offcanvas {
    min-width: 850px;
    height: 100vh;
    overflow: auto;
    .offcanvas-header{
        display: flex;
        justify-content: space-between;
        background: linear-gradient(to left, rgba(241, 164, 40, 0.8), rgba(241, 91, 40, 0.8));
        padding: 20px 30px;
        flex-flow: wrap;
        .btn-close{
            background: url('../images/icons/close_white.svg') no-repeat center center;
            background-size: 15px;
            &:focus, &:focus-visible{
                box-shadow: none;
                outline: none;
            }
        }

    .profile-info {
        display: flex;
        align-items: center;
        .profile-img {
            width: 96px;
            height: 96px;
            min-width: 96px;
            display: flex;
            justify-content: center;
            overflow: hidden;
            margin-right: 20px;
            margin-bottom: 0;
            border-radius: 50%;
                img {
                width: 100%;
                height: auto;
                object-fit: contain;
                background: $white;
                //border-radius: 50%;
            }
        }
        .profile-titles {
            display: flex;
            flex-direction: column;
            .profile-name {
                font-family: $primaryfontBold;
                font-size: 24px;
                color: $white;
                line-height: normal;
            }
            .profile-role {
                font-family: $primaryfontMedium;
                color: $white;
                font-size: 14px;
                line-height: normal;
            }
            .profile-location { 
                font-family: $primaryfontMedium;
                color: $white;
                font-size: 13px;
                margin-top: 7px;
                display: flex;
                align-items: center;
                line-height: normal;
                img{
                    margin-right: 5px;
                }
            }
        }
        @media screen and (max-width:$medium) {
            width: 100%;
            padding-bottom: 15px;
            .profile-img {
                width: 50px;
                min-width: 50px;
                height: 50px;
            }
        }
    }

    .profile-actions {
        .profile-top{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .simpleselect_container {
                margin-bottom: 0;
                .simpleselect {
                    height: 36px;
                    .simpleselect__control{
                        height: 36px;
                        border-radius: 18px;
                    }
                }
            }
            .primary-btn {
                border-radius: 24px;
                background: $white;
                color: $primary-color;
                &:hover {
                    background: $white !important;
                }
            }
        }
        .profile-matched {
            font-family: $primaryfontRegular;
            font-size: 13px;
            color: $primary-color;
            line-height: normal;
            padding: 0 15px 0 30px;
            background: $white url('../images/main/lightning.svg') no-repeat left 15px center;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 36px;
            border-radius: 18px;
            margin-right: 10px;
        }
        .member-since {
            font-family: $primaryfontMedium;
            color: $white;
            margin-top: 10px;
            font-size: 13px;
            display: flex;
            justify-content: flex-end;
        }
    }
}  

.offcanvas-body {
    padding: 0;
    overflow: visible;
    .messages {
        display: flex;
        margin-bottom: 30px;
            .messages-img {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                border-radius: 50%;
                margin-right: 10px;
                margin-bottom: 0px;
                    img {
                        max-width: 100%;
                        object-fit: contain;
                    }
            }

            .messages-details {
                width: 100%;
                .messenger-name {
                    font-family: $primaryfontMedium;
                    font-size: 14px;
                    color: $primary-color;
                    margin-bottom: 5px;
                }
                .message-content {
                    p {
                        font-family: $primaryfontRegular;
                        font-size: 14px;
                        color: $primary-color;
                        margin-bottom: 10px;
                        &:last-child{
                            margin: 0;
                        }
                    }
                    .pdf-content {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        figure {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            border: 1px solid #8A89894D;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            margin-bottom: 0px;
                            margin-right: 10px;
                                img {
                                    max-width: 100%;
                                    object-fit: contain;
                                }
                        }
                        .pdf-name {
                            font-family: $primaryfontRegular;
                            font-size: 15px;
                            color: $black;
                        }

                    }

                }

            }
            &:nth-child(even){
                .messages-details .message-content {
                    background-color: #F7F9FF;
                    padding: 15px 20px;
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                    border-bottom-left-radius: 6px;
                }
            }
        }  
        .profile_details_body {
            padding: 0px;
            .profile_exp_sec {
                margin-bottom: 10px;
                padding: 20px 0 0 20px;
            }
            .nav-tabs {
                position: sticky;
                top: 0;
                background: #fff;
                padding: 10px 0 20px 20px;
                z-index: 99;
                flex-wrap: nowrap;
                overflow: auto;
                .nav-item {
                    white-space: nowrap;
                }
            }  
            .tab-content {
                padding: 0 20px;
            }
        }
    }
    @media screen and (max-width:$large) {
        min-width: unset;
        width: 90% !important;
        max-width: unset;
    }
    @media screen and (max-width:$small) {
        .offcanvas-body {
            .profile_details_body {
                .tab-content {
                    .details_list li {
                        width: 100%;
                    }
                    .skill_sec {
                        .skill_body {
                            ul li {
                                flex-direction: column;
                                .skill_body_left {
                                    width: 100%;
                                    margin-bottom: 10px;
                                }
                                .ratings-view {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.offcanvas_footer{
    min-height: 60px;
    background: #F7F9FF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    input{
        border: 0;
        outline: none;
        background: transparent;
        font-family: $primaryfontRegular;
        font-size: 14px;
        color: $base-color;
        width: calc(100% - 200px);
    }
    .actions {
        display: flex;
        align-items: center;
        img{
            cursor: pointer;
        }
        > span{
            border: 1px solid #8A898933;
            border-radius: 6px;
            background: $white;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 20px;
            cursor: pointer;
        }
    }
    &.inner_footer{
        position: fixed;
        bottom: 0px;
        width: 999px;
        left: inherit;
        right: 0px;
        form {
            width:100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .form-group {
                width: calc(100% - 90px);
                .form-control {
                    border: 0px;
                    background: transparent;
                }
            }
            .actions {
                position: relative;
                .fileuploader {
                    margin-bottom: 0;
                    position: static;
                    .files-dropzone-list {
                        font-size: 0px; 
                        height: 40px !important;
                        width: 40px;
                        border: 1px solid rgba(138, 137, 137, 0.2) !important;
                        img {
                            display: none;
                        }
                        background: url('../images/attachment.png') no-repeat center #ffffff;
                    }
                    .files-list {
                        position: absolute;
                        bottom: calc(100% + 5px);
                        right: 0px;
                        white-space: unset;
                        background: #fff !important;
                        padding: 15px;
                        box-shadow: 0 0 6px 0px rgb(0 0 0 / 20%) !important;
                        border-radius: 6px;
                        
                        
                    }
                }
                .btn  {
                    border: 1px solid rgba(138, 137, 137, 0.2) !important;
                    border-radius: 6px;
                    background: #ffffff !important;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 5px;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    &:hover img {
                        transform: scale(1.3);
                    }
                }
                .simpleselect_container {
                    margin-right: 5px; 
                    .simpleselect {
                        height: 40px;
                        cursor: pointer;
                        border-radius: 50%;
                        .simpleselect__control {
                            width: 40px;
                            height: 40px;
                            min-width: 40px;
                            .simpleselect__value-container {
                                display: none;
                            }
                            .simpleselect__indicators {
                                width: 100%;
                                .simpleselect__indicator {
                                    background: url('../images/icons/profile-icon.svg') no-repeat center;
                                    width: 100%;
                                    height: 100%;
                                    padding: 0;
                                    background-size: 100%;
                                    cursor: pointer;
                                    svg {
                                        display: none;
                                    }
                                }
                            }
                        }
                        .simpleselect__menu {
                            min-width: 150px;
                            right: 0px;
                        }
                    }
                }
            }
        }
    }
     
}

.modal  {
    .modal-dialog  {
        .modal-content {
            .modal-header {
                background: $base-color1;
                height: 50px;
                padding: 0 20px;
                .modal-title {
                    font-family: $secondaryfontRegular;
                    font-size: 16px;
                    color: $primary-color;
                }
                .btn-close {
                    color: $base-color;
                    font-size: 12px;
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
            .modal-body {
                padding: 20px 25px 10px 25px;
                h5{
                    font-size: 14px;
                    color: rgba($color: $primary-color, $alpha: 0.7);
                    padding-bottom: 10px;
                }
                .message {
                    font-size: 20px;
                    color: rgba($color: $primary-color, $alpha: 0.85);
                    padding-bottom: 10px;
                    &.terms_msg {
                        p {
                            font-size: 16px;
                            font-family: $neutralfontMedium;
                            color: $primary-color;
                            a {
                                color: #007191;
                                font-size: 16px;
                                font-family: $neutralfontMedium;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
            .modal-footer {
                padding: 15px 25px;
                border-top: 1px solid rgba($base-color4 , 0.1);
                .btn-secondary {
                    @include cancelBtn;
                }
            }
            @media screen and (max-width:$small)  {
                .modal-header {
                    padding: 0 15px;
                }
                .modal-body {
                    padding: 20px 15px 10px 15px;
                    .row {
                        col {
                            flex: inherit;
                        }
                    }
                }  
                .modal-footer {
                    padding: 10px 15px;
                }
            }
        }
        &.profile-modal {
            .modal-header  {
                height: auto;
                padding: 0px;
            }
        }
    }
    &.modal-invite.modal-bulk-invite .modal-dialog{
        max-width: 765px;
    }
    &.modal-invite.modal-talent-invite .modal-dialog{
        max-width: 612px;
    }
}

// .modal {
//     &.invite-modal{
//         .modal-dialog{
//             max-width: 600px;
//             .modal-content{
//                 .modal-header{
//                     background: #F7F9FF;
//                     padding: 15px 20px;
//                     .modal-title{
//                         font-family: $secondaryfontRegular;
//                         font-size: 16px;
//                         color: $primary-color;
//                         line-height: normal;
//                     }
//                     .btn-close{
//                         background-size: 10px;
//                     }
//                 }
//                 .modal-body{
//                     padding: 30px;
//                     h5{
//                         font-size: 14px;
//                         color: rgba($color: $primary-color, $alpha: 0.7);
//                         padding-bottom: 10px;
//                     }
//                 }
//                 .modal-footer{
//                     display: flex;
//                     flex-direction: row;
//                     justify-content: flex-start;
//                     padding: 20px 30px;
//                     .btn{
//                         width: auto;
//                     }
//                     .btn-primary{
//                         font-family: $secondaryfontMedium;
//                         font-size: 15px;
//                     }
//                     .btn-secondary{
//                         font-family: $secondaryfontMedium;
//                         font-size: 15px;
//                         color: $base-color;
//                         background: transparent;
//                         border: none;
//                     }
//                 }
//             }
//         }
//     }
// }

.invite_talent_sec{
    ul{
        margin: 0 0 0px;
        padding: 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        &:last-child{
            margin: 0;
        }
        li{
            display: flex;
        }
        &.headings{
            margin: 0 0 10px 0px;
            li{
                font-family: $neutralfontMedium;
                font-size: 13px;
                color: #6D6E70;
                line-height: normal;
            }
        }
        &.inputs_list{
            li{
                width: calc(33.3% - 15px);
                .form-group{
                    width: 100%;
                   // margin-bottom: 0;
                    .form-control{
                        width: 100%;
                    }
                }
                img{
                    width: 10px;
                    cursor: pointer;
                    margin-top: 18px;
                }
                @media screen and (max-width:$medium) {
                    width:100%;
                    &.date {width: calc(50% - 13px);}
                }
            }
            &.list_inputs {
                flex-wrap: wrap;
                justify-content: start;
                margin: 0 0 10px;
                padding: 10px;
                position: relative;
                border: 1px solid $base-color1;
                border-radius: 4px;
                li {
                    margin: 0 12px 0 0;
                    padding-bottom: 2px;
                    &.delete {
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        background: $base-color1;
                        width: 30px;
                        height: 30px;
                        margin: 0;
                        border-radius: 0 0 0 49px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 0 4px 4px;
                        img {
                            margin: 0;
                        }
                    }
                }
                .custom-radio {
                    margin: 11px 0px;
                }
                // &:nth-child(odd) {
                //     background-color: $base-color2;
                // }
                // &:nth-child(even) {
                //     background-color: $base-color1;
                // }
            }
        }
    }
    .add_new_lable{
        font-family: $secondaryfontRegular;
        font-size: 14px;
        color: $secondary-color;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
}

.modal-close {
    position: absolute;
    right: 17px;
    top: 10px;
    cursor: pointer;
    z-index: 99;
}

.success_modal  {
    .modal-dialog {
        width: 100%;
        max-width: 600px;
    }
    .success_sec {
        display: block;
        text-align: center;
        padding-bottom: 20px;
        span {
            display: block;
            text-align: center;
            padding-bottom: 40px;
            img {
                display: block;
                margin: 0 auto;
            }
    
        }
        label {
            font-family: $neutralfontBold;
            font-size: 20px;
            display: block;
            text-align: center;
            color: $primary-color;
            margin: 0 0 25px 0;
        }
        p {
            font-family: $neutralfontRegular;
            font-size: 15px;
            display: block;
            text-align: center;
            color: $black;
            margin: 0 0 10px 0;
        }
        .copy_cnt {
            display: flex;
            align-items: center;
            border: 1px solid rgba($primary-color , 0.3);
            border-radius: 6px;
            max-width: 490px;
            margin: 0 auto;
            .form-group {
                width: 100%;
                margin: 0px;
                .form-control {
                    height: 50px;
                    border: 0px;
                    border: 6px 0 0 6px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                }
            }
            .copy_btn  {
                width: auto;
                padding: 0 15px;
                height: 50px;
                border-radius: 0 6px 6px 0;
                white-space: nowrap;
            }
        }
    }
    .copy_actions {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0;
        .btn {
            margin: 0 10px;
            height: 45px;
        }
    }
    &.copy_share_model {
        .modal-body {
            padding: 40px 25px!important;
            .copy_close {
                position: absolute;
                right: 10px;
                top: 5px;
                button {
                    padding: 0;
                }
            }
            .success_sec {
                padding: 0;
                .copy_cnt {
                    max-width: 100%;
                }
            }
        }
    }
}


.pad10 {
    padding: 10px !important;
}