

$white : #ffffff;
$black : #000000;
 
$primary-color : #072430;
$secondary-color : #007192;

$base-color: #0C3D54;
$base-color1: #F7F9FF;
$base-color2: #86939D ;
//$base-color2: rgb(138 137 137 / 20%);
$base-color3: #072430;
$base-color4: #072430;
$base-color5: #D2D2D2;
$base-color6: #fafafa;
$border-color: rgb(138 137 137 / 30%);
$border-color2: #EFEFEF; 

$error : #dc3545;

$bg-color : #A2E8FE;

///////////// 
/// 
$extraExtraLarge: 1400px;
$extraLarge: 1200px;
$large: 992px;
$medium: 768px;
$belowMedium:767px;
$small: 576px;

 
// @media screen and (max-width: $extraLarge) {
//     //do Smth
// }



@import "matchplicityStyles.scss";

