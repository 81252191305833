@import "includes.scss"; 

// .footer {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background: $white;
//     height: 60px;
//     padding: 0 10px;
//     ul {display: flex;}
//     p {
//         font-family: $primaryfontRegular;
//         color: $base-color;
//         font-size: 13px;
//         padding: 0px;
//         margin: 0px;
//         text-align: center;
//     }
// }  

.footer {
    background-color: $white;
    padding: 35px 0px;
    .footer_cnt {
        //max-width: 1140px;
        margin: 0 auto;
        width: 100%;
        .footer_nav {

            margin: 0px 0px 32px 0px;
            @media screen and (min-width:480px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .footer_logo {
                width: 221px;
                @media screen and (max-width:480px) {
                    display: block;
                    margin: 0 auto;
    
                }
                img {
                    width: 100%;
                }
            }
            ul {
                padding: 0;
                list-style: none;
                margin: 0;
                display: flex;
                justify-content: space-between;
                li {
                    margin-right: 10px;
                    a {
                        font-family: $neutralfontMedium;
                        font-size: 14px;
                        color: #6D6E70;
                        text-decoration: none;
                    }
                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }
        }
        .social_networks {
            display: flex;
            align-items: center;
            justify-content: space-between;
            label {
                font-family: $neutralfontMedium;
                font-size: 14px;
                color: #6D6E70;
                @media screen and (max-width:480px) {
                    text-align: center;
                }
            }
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                li {
                    display: flex;
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0px;
                    }
                    a {
                        width: 36px;
                        height: 36px;
                        border: 1px solid #9FA2A5;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
    @media screen and (max-width: $medium) {
        padding: 20px 10px;
        .footer_cnt {
            .footer_nav {
                flex-wrap: wrap;
                justify-content: center;
                margin: 0px 0px 15px 0px;
                ul {
                    margin: 15px 0px 0px;
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
            .social_networks {
                flex-wrap: wrap;
                justify-content: center;
                ul {
                    margin: 15px 0px 0px;
                }
            }
        }
    }
}