@import "includes.scss";

@import "modal.scss";
///////////////////////

@mixin formCheck {
    display: flex;
    align-items: center;
    margin: 0; 
    padding: 0;
}

@mixin formCheckInput {
    width: 18px;
    height: 18px;
    margin: 0;
    cursor: pointer;
    border: 1px solid #8A898966;
    border-radius: 4px;
    &:focus{
        outline: none;
        box-shadow: none;
    }
    &:checked{
        background-color: $base-color;
        border-color: $base-color;
    }
}

@mixin formCheckLabel{
    font-family: $primaryfontRegular;
    font-size: 14px;
    color: rgba($color: $primary-color, $alpha: 0.8);
    margin-left: 10px;
    cursor: pointer;
    line-height: normal;
}

.form-check{
    @include formCheck;
    .form-check-input{
        @include formCheckInput;
    }
    .form-check-label{
        @include formCheckLabel;
    }
}

///////////////////

// .maincontent{
//     min-height: 100vh;
//     height: auto;
// }

.page_wrapper{
    display: flex;
    padding: 0px 0 30px 0;
    width: 100%;
    &.no_sidebar {
        .main_details_sec {
            width: 100%;
            padding-left: 0px;
        }
    }
}

.side_bar_filters{
    width: 300px;
    position: sticky;
    top: 0;
    transition: all 0.5s ease;
    .filter_actions_btn{
        position: absolute;
        right: -30px;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        background: $primary-color;
        border-radius: 0 4px 4px 0;
        top: 20px;
        cursor: pointer;
        display: none;
        //box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1019607843);
        svg{
            width: 18px;
        }
    }
    .page_heading{
        font-family: $secondaryfontRegular;
        font-size: 24px;
        color: $primary-color;
        margin-bottom: 26px;
    }
    .filters_wrapper{
        background: $white;
        border-radius: 6px;
        padding: 20px; 
        position: sticky;
        top: 0px;
        .filters_list{
            .category_heading{
                font-family: $secondaryfontRegular;
                font-size: 12px;
                color: $secondary-color;
                display: block;
                line-height: normal;
                margin-top: 30px;
            }
            ul {
                padding: 0;
                margin: 0;
                li{
                    display: flex;
                    margin-top: 10px;
                }
            }
            &:first-child{
                .category_heading{
                    margin: 0;
                }
            }
            .form-group {
                margin: 0px;
                width: 100%;
                .simpleselect {
                    height: 40px;
                    .simpleselect__control {
                        height: 40px;
                    }
                }
                .form-control {
                    height: 40px;
                }                
            }
            .range-slider {
                margin-top: 10px;
                .slides {
                    display: none;
                }
            }
            .react-datepicker-wrapper .react-datepicker__input-container input {
                background-position: 8px center;
                padding: 0 30px;
                font-size: 13px;
            }
        }
    }
    @media screen and (max-width:$large) {
       position: fixed;
       left: -350px;
       top: 60px;
       z-index: 9;
       height: 100vh;
       box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1019607843);
       z-index: 999;
       &.open{
        left:0;
       }
       .filter_actions_btn{
            display: flex;
       }
        .filters_wrapper{
            height: 100%;
            border-radius: 0;
            position: relative;
            z-index: 999;
        }
    }
    .sibebar_overlay {
        opacity: 0;
        visibility: hidden;
        transition: 0.5s ease-in-out all;
        background: rgba($secondary-color , 0.5);
        width: 100%;
        height: 100vh;
        display: block;
        position: fixed;
        top: 60px;
        right: 0px;
    }
    &.open .sibebar_overlay {
        opacity: 1;
        visibility: visible;
    }
}

.main_details_sec{
    width: calc(100% - 250px);
    padding-left: 20px;
    .search_sec{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left_sec{
            display: flex;
        }
    }
    @media screen and (max-width:$large) {
        width: 100%;
        padding: 0;
    }
}

.details_list{
    .details_list_item{
        background: $white;
        border-radius: 6px;
        padding-bottom: 20px;
        margin-bottom: 10px;
        &:last-child{
            margin: 0;
        }
        .job_header_sec{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #8A89891A;
            padding: 15px 20px;
            .left_sec{
                display: flex;
                align-items: center;
                figure{
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background: rgba($color: $secondary-color, $alpha: 0.05);
                    margin: 0;
                }
                .job_title_sec{
                    display: flex;
                    flex-direction: column;
                    margin-left: 10px;
                    .job_title{
                        font-family: $primaryfontMedium;
                        font-size: 16px;
                        color: $secondary-color;
                    }
                    .location{
                        font-family: $primaryfontRegular;
                        font-size: 12px;
                        color: $primary-color;
                        line-height: normal;
                    }
                }
            }
            .matches{
                font-family: $primaryfontRegular;
                font-size: 13px;
                color: $primary-color;
            }
        }
        .matched_profile_sec{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 30px;
            &:nth-child(odd){
                background: #F8F8F8;
            }
            .profile_sec{
                display: flex;
                align-items: center;
                .profile_icon{
                    min-width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    border-radius: 50%;
                    img{
                        max-width: 100%;
                        object-fit: contain;
                    }
                }
                .profile_title_sec{
                    display: flex;
                    flex-direction: column;
                    margin-left: 10px;
                    .profile_name{
                        font-family: $primaryfontRegular;
                        font-size: 15px;
                        color: $primary-color;
                        &:hover{
                            font-family: $primaryfontMedium;
                            color: $secondary-color;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                    .role_exp{
                        font-family: $primaryfontRegular;
                        font-size: 13px;
                        color: $base-color;
                        line-height: normal;
                    }
                }
            }
            .action_sec{
                display: flex;
                align-items: center;
                .simpleselect_container.form-group{
                    margin-bottom: 0;
                    // .simpleselect.status_update {
                    //     height: 30px;
                    // }
                }
                > * {
                    margin-right: 20px;
                    &:last-child{
                        margin: 0;
                    }
                }
                span{
                    cursor: pointer;
                    &.matched{
                        font-family: $primaryfontRegular;
                        font-size: 13px;
                        color: $primary-color;
                        line-height: normal;
                        padding-left: 17px;
                        cursor: text;
                        background: url('@/../../../../public/images/main/lightning.svg') no-repeat left center;
                        white-space: nowrap;
                    }
                }
            }
            @media screen and (max-width:$belowMedium) {
                flex-wrap: wrap;
                .profile_sec{
                    margin-bottom: 15px;
                }
            }
            @media screen and (max-width:$small) {
                .action_sec{
                    flex-wrap: wrap;
                    > *{
                        margin-bottom: 10px !important;
                    }
                }
            }
        }
        .view_all_btn{
            width: auto;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $primaryfontRegular;
            font-size: 13px;
            color: $primary-color;
            line-height: normal;
            border: 1px solid #8A898933;
            border-radius: 20px;
            margin: 20px 30px 0 60px;
            cursor: pointer;
        }
    }
}

.simpleselect_container{
    label{
        @include formLabel;
    }
    .simpleselect {
        .simpleselect__control {
            border-color: #8A89894D;
            min-width: 150px;
            height: 45px;
            &.simpleselect__control--is-focused,
            &.simpleselect__control--menu-is-open{
                border-color: #8A8989;
                box-shadow: none;
            }
            .simpleselect__value-container {
                .simpleselect__placeholder, .simpleselect__input-container {
                    font-family: $primaryfontRegular;
                    font-size: 15px;
                    color: rgba($color: $primary-color, $alpha: 0.6);
                }
                .simpleselect__single-value{
                    font-family: $primaryfontRegular;
                    font-size: 15px;
                    color: $primary-color;
                }
            }
            .simpleselect__indicators .simpleselect__indicator-separator{
                display: none
            }
        }
        .simpleselect__menu{
            color: rgba($color: $primary-color, $alpha: 0.7);
            font-family: $primaryfontRegular;
            font-size: 15px;
            * {
                text-align: left;
            }
        }
    }
}
.profile_details_body{
    padding: 20px 30px;
}
.profile_exp_sec{
    margin-bottom: 30px;
    ul{
        padding: 0;
        margin: 0;
        display: flex;
        li{
            display: flex;
            margin-right: 50px;
            &:last-child{
                margin-right: 0;
            }
            .icon_sec{
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: #F15B281A;
                margin-right: 10px;
            }
            .details_sec{
                display: flex;
                flex-direction: column;
                span{
                    font-family: $primaryfontRegular;
                    color: $black;
                    font-size: 15px;
                    line-height: normal;
                }
                em{
                    font-family: $primaryfontRegular;
                    color: $base-color;
                    font-size: 12px;
                    font-style: normal;
                }
            }
        }
    }
}

.nav-tabs{
    border: none;
    padding-bottom: 30px;
    .nav-item{
        margin-right: 5px;
        &:last-child{
            margin: 0;
        }
    }
    .nav-link{
        border: 1px solid #8A898980;
        border-radius: 20px;
        background: $white;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        font-family: $primaryfontRegular;
        font-size: 14px;
        color: #414141;
        position: relative;
        line-height: normal;
        &.active{
            background: #8A8989;
            border: 1px solid #8A8989;
            color: $white;
            &:after{
                top: 100%;
                left: 50%;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-top-color: #8A8989;
                border-width: 6px;
                margin-left: -6px;
            }
        }
    }
    &.secondary_tabs{
        .nav-link{
            &.active{
                background: $secondary-color;
                border: 1px solid $secondary-color;
                &:after{
                    border-top-color: $secondary-color;
                }
            }
        }
    }
}

.notes {
    display: flex;
    margin-bottom: 30px;
    position: relative;

        .notes-img {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border-radius: 50%;
            margin-right: 10px;
            margin-bottom: 0px;

                img {
                    max-width: 100%;
                    object-fit: contain;
                }
                &:after {
                    content: "";
                    width: 0px;
                    height: 100%;
                    border: 2px solid #8A89891A;
                    position: absolute;
                    top: 40px;
                }   
        }
        &:last-child .notes-img:after{
            display: none;
        }

        .notes-details {
            width: 100%;

            .notes-name {
                font-family: $primaryfontMedium;
                font-size: 14px;
                color: $primary-color;
                margin-bottom: 5px;
            }

            .notes-content { 
                p {
                    font-family: $primaryfontRegular;
                    font-size: 14px;
                    color: $primary-color;
                    margin-bottom: 10px;
                    &:last-child{
                        margin: 0;
                    }
                }
                ul{
                    font-family: $primaryfontRegular;
                    font-size: 14px;
                    color: $primary-color;
                    margin-top: 10px;
                    margin-bottom: 0;
                    line-height: 32px;
                    li::marker{
                        color: #8A8989;
                    }
                }
            }

    }

}


.mail-contact-info {
    margin-bottom: 28px;

    img {
        margin-right: 10px; 
    }

    span {
        font-family: $primaryfontRegular;
        font-size: 15px;
        color: $black;
        margin-right: 30px;
    }
}

.candidate-info {
        font-family: $primaryfontRegular;
        font-size: 14px;
        color: $primary-color;
}

.certifications, .prefered-locations {
    margin-top: 25px;
    position: relative;
    
    .certificate-title, .location-title {
        font-family: $primaryfontMedium;
        font-size: 15px;
        color: $primary-color;
    } 

    .certification-list, .location-list {
        display: flex;
        margin-left: 10px;
        padding: 0;

        li {
            font-family: $primaryfontRegular;
            font-size: 14px;
            color: $black;
            list-style-type: none;
            height: 32px;
            background-color: #F7F9FF;
            border-radius: 20px;
            margin: 5px;
            padding: 6px 20px;
        }
    }
}

.certificate-title::before, .location-title::before {
    content: "";
    display: inline-block;
    width: 9px;
    height: 9px;
    background-color: #F8AD93;
    border-radius: 50%;
    margin-right: 6px;
}

.certificate-title::after, .location-title::after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 100%;
    background-color: #F8AD93;
    position: absolute;
    left: 4px;
    top: 10px;
}

.work-experience {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    align-items: center;
    margin-bottom: 15px;
    background-color: $white;
    margin: 0 -20px;
    .previous-org-names {
        display: flex;
        align-items: center;
        justify-content: center;


            .previous-org-logo {
                margin-right: 15px;
                //width: 64px;
                //height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                //border: 1px solid #8A898933;
                border-radius: 50%;
                img {
                    max-width: 100%;
                    object-fit: contain;
                }
            }
    

            .previous-org-role {

                h5 {
                    font-family: $primaryfontMedium;
                    font-size: 15px;
                    color: $black;
                    margin-bottom: 0;

                }

                p {
                    font-family: $primaryfontRegular;
                    font-size: 13px;
                    color: $black;
                    margin-bottom: 0;
                }
        }
    }       

    .duration-previous-org {

        p {
            font-family: $primaryfontRegular;
            font-size: 13px;
            color: $black;
        }

    }
}

.work-experience:nth-child(even) {
    background-color: #F7F9FF;
}

.skill_sec{
    .skill_header{
        background: #F7F9FF;
        padding: 0 20px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
            font-family: $primaryfontRegular;
            font-size: 13px;
            color: $base-color;
            line-height: normal;
        }
        .skill_left{
            display: flex;
            
        }
        .skill_right{
            display: flex;
            justify-content: space-between;
            width: calc(100% - 300px);
            padding: 0 0 0 25px;
        }
    }
    .skill_body{
        ul{
            padding: 0;
            margin: 0;
            li{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0px;
                margin-top: 20px;
                .skill_body_left{
                    display: flex;
                    width: 400px; padding-right: 30px;
                    span{
                        font-family: $primaryfontMedium;
                        font-size: 15px;
                        color: $black;
                        line-height: normal;
                    }
                }
                .skill_body_right{
                    display: flex;
                    justify-content: space-between;
                    width: calc(100% - 300px);
                    position: relative;
                    padding: 0 30px;
                    span{
                        width: 25px;
                        height: 25px;
                        &.skill_present{
                            border: 2px solid $white;
                            border-radius: 18px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: $black url('@/../../../../public/images/main/checkmark.svg') no-repeat center center;
                            z-index: 1;
                        }
                    }
                    &:before{
                        content: '';
                        width: 100%;
                        height: 5px;
                        background: transparent linear-gradient(270deg, #A5D12F 0%, #2FD1B9 33%, #FFBD3C 67%, #FF5D5E 100%);
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        border-radius: 100px;
                    }
                }
            }
        }
    }
}

//// Dashbaord CSS

.db_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent linear-gradient(93deg, rgba($color: #F15B28 , $alpha: 0.8) 0%, 
    rgba($color: #F1A428 , $alpha: 0.8) 100%);
    padding: 20px 30px;
    h5{
        font-family: $secondaryfontMedium;
        color: $white;
        font-size: 24px;
        margin: 0;
        line-height: normal;
    }
    .right_sec{
        display: flex;
        align-items: center;
        .last_logged{
            font-family: $primaryfontMedium;
            font-size: 13px;
            color: $white;
            text-align: right;
            padding-right: 20px;
            em{
                font-style: normal;
                display: block;
            }
        }
        .invite_btn{ 
            height: 40px;
            border: 1px solid $secondary-color;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $secondaryfontMedium;
            font-size: 14px;
            color: $secondary-color; 
            background: $white;
            cursor: pointer;
            padding: 0 20px;
            em{
                font-style: normal;
                margin-right: 6px;
            }
            span {
                display: block;
                padding: 0 0 0 10px;                
            }
            svg, path {
                fill: $secondary-color;
            }
        }
        ul {
            display: flex;
            margin: 0;
            padding: 0px;
            li {
                list-style-type: none;
                position: relative;
                padding: 0 0 0 20px;                
                ol {
                    display: none;
                    position: absolute;
                    top: 100%;
                    right: 0px;
                    padding: 5px 0;
                    margin: 0px;
                    min-width: 110px;
                    background: $white;
                    box-shadow: 0 0 10px #00000026;
                    border-radius: 6px;
                    li {
                        font-family: $primaryfontRegular;
                        font-size: 14px;
                        color: $primary-color;
                        padding: 5px 15px;
                        margin: 0px;
                        text-align: right;
                        white-space: nowrap;
                        cursor: pointer;
                        &:hover {
                            color: $secondary-color;
                        }
                    }
                }
                &:hover {
                    .invite_btn{
                        background: $secondary-color;
                        color: $white;
                        svg, path {
                            fill: $white;
                        }
                    }
                    ol {
                        display: block;
                    }
                }
            }
        }
        @media screen and (max-width:$small) {
            ul li {padding: 0 0 0 5px;
                &:nth-child(1) {
                    padding: 0;
                }
            }
        }
    }
}

.db_main_wrapper{
    padding: 20px 0;
    display: flex;
    .db_left{
        .employer_profile{
            border: 1px solid #0071921A;
            border-radius: 0px 0px 6px 6px;
            padding: 20px 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: $white;
            width: 230px;
            position: sticky;
            top: 10px;
            .employer_top{
                padding: 0 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .employer_logo{
                width: 64px;
                height: 64px;
                border: 1px solid #E2E2E2;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    max-width: 100%;
                    object-fit: contain;
                }
            }
            .employer_name{
                font-family: $primaryfontMedium;
                font-size: 16px;
                color: $primary-color;
                margin-top: 10px;
                line-height: normal;
                display: block;
            }
            .followers{
                font-family: $primaryfontMedium;
                font-size: 12px;
                margin-top: 5px;
                color: $base-color;
            }
            .go_to_profile{
                font-family: $secondaryfontRegular;
                color: $secondary-color;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 16px 20px;
                cursor: pointer;
                border-top: 1px solid #E5F0F4;
                margin-top: 20px;
                width: 100%;
            }
        }
    }
    .db_main{
        padding: 0 20px;
        width: 100%;
        .db_list{
            display: flex;
            padding: 0;
            margin: 0 -10px 20px;
            flex-wrap: wrap;
            li{
                display: flex;
                align-items: center;
                padding: 0 20px 0 30px;
                min-height: 120px;
                width: calc(25% - 20px);
                border-radius: 6px;
                margin: 0 10px;                 
                .counts{
                    display: flex;
                    flex-direction: column;
                    padding-left: 30px;
                    span{
                        color: $white;
                        font-family: $primaryfontBold;
                        font-size: 26px;
                    }
                    em{
                        color: $white;
                        font-family: $secondaryfontMedium;
                        font-size: 14px;
                        font-style: normal;
                        line-height: normal;
                    }
                }
                &.total_jobs{
                    background: #4C555B;
                }
                &.total_matched{
                    background: #F17951;
                }
                &.total_user{
                    background: #687175;
                }
                &.total_emp{
                    background: rgba($color: $secondary-color , $alpha: 0.8);
                }
                &.total_talents{
                    background: #F2A852;
                }
                &.total_emp_ren{
                    background: rgba($color: #F75A5A , $alpha: 0.8);;
                }
                &.clickable:hover {
                    cursor: pointer;
                    background: $secondary-color !important;
                }
            }
            @media screen and (max-width: $large) {
                li {
                    width: calc(50% - 20px);
                    margin-bottom: 10px;
                }
            }
            @media screen and (max-width: $small) {
                margin: 0 0px 20px;
                li {
                    width: 100%;
                    margin: 0 0 10px 0;
                }
            }
            &.db_list-v2 {
                li {
                    width: calc(50% - 20px);
                }
            }
            
            &.db_list-v3 {
                li { 
                    width: calc(20% - 20px);
                }
                @media screen and (max-width:$large) {                
                    justify-content: center;
                    padding: 0 5px;
                    li { 
                        width: calc(33.3% - 10px);
                        padding: 0 5px 0 15px;
                        min-height: 70px;
                        margin: 0 5px 10px;
                        img {
                            width: 20px;
                        }
                        .counts {
                            padding-left: 10px;
                            span {
                                font-size: 20px;
                            }
                            em {
                                font-size: 12px;
                            }
                        }
                    }                    
                }
                @media screen and (max-width:$medium) {  
                 justify-content: space-between;
                    margin: 0 -5px;
                    padding: 0;
                    li {
                        width: calc(50% - 10px);
                    }
                }
            }
        }
    }
    .db_right{
        .recent_activities{
            width: 300px;
        }
    }

    .widget_item{
        background: $white;
        border-radius: 6px;
        .widget_header{
            background: #FFF6EB;
            padding: 12px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span{
                font-family: $secondaryfontRegular;
                font-size: 16px;
                color: $primary-color;
                line-height: normal;
            }
            figure{
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 26px;
                height: 26px;
                border-radius: 4px;
                background: #fff;
                cursor: pointer;
                img{
                    transform: rotate(-90deg);
                }
            }
        }
        .widget_body{
            padding: 20px;
            .widget_list{
                padding: 0;
                margin: 0;
                display: flex;
                li{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-direction: column;
                    border: 1px solid #EFF2FD;
                    border-radius: 6px;
                    padding: 20px 10px;
                    margin-right: 10px;
                    width: 100%;
                    &:last-child{
                        margin: 0;
                    }
                    span{
                        text-align: center;
                        &.profile_icon{
                            width: 36px;
                            height: 36px;
                            border: 1px solid #707070;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            margin-bottom: 5px;
                            img{
                                max-width: 100%;
                                object-fit: contain;
                            }
                        }
                        &.profile_name{
                            font-family: $primaryfontMedium;
                            font-size: 15px;
                            color: $primary-color;
                        }
                        &.role_exp{
                            font-family: $primaryfontRegular;
                            font-size: 12px;
                            color: $primary-color;
                        }
                        &.location{
                            font-family: $primaryfontMedium;
                            font-size: 12px;
                            color: #6D6E70;
                        }
                        &.matched{
                            font-family: $neutralfontSemiBold;
                            font-size: 15px;
                            color: $secondary-color;
                            line-height: normal;
                            padding-left: 17px;
                            cursor: text;
                            background: url('@/../../../../public/images/main/lightningorange.svg') no-repeat left center;
                            margin: 15px 0;
                        }
                        &.view{
                            border: 1px solid #8A89894D;
                            border-radius: 14px;
                            padding: 0 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            min-height: 28px;
                            cursor: pointer;
                            font-family: $secondaryfontRegular;
                            font-size: 13px;
                            color: $primary_color;
                            line-height: normal;
                            &.no_cursor{
                                cursor: default;
                            }
                            &.no_border{
                                border: none;
                                padding: 0;
                                height: auto;
                                margin-top: 10px;
                            }
                        }
                        &.posted_by{
                            font-family: $neutralfontRegular;
                            font-size: 12px;
                            color: $primary-color;
                            padding: 20px 0 15px;
                            em{
                                color: $secondary-color;
                                font-style: normal;
                                text-decoration: underline;
                            }
                        }
                        &.dessig{
                            font-family: $primaryfontRegular;
                            font-size: 12px;
                            color: $secondary-color;
                        }
                    }
                    .match_comp_emp{
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        .icon{
                            width: 58px;
                            height: 58px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            overflow: hidden;
                            img{
                                max-width: 100%;
                                object-fit: contain;
                                width: 100%;
                            }
                        }
                        .checkmark{
                            width: 16px;
                            height: 16px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            overflow: hidden;
                            margin: 0 8px;
                            background: $secondary-color url('@/../../../../public/images/main/checkmark.svg') no-repeat center center;
                            background-size: 8px;
                        }
                    }
                    &.no_border{
                        border: none;
                        margin: 0;
                    }
                }
                &.recent_emp_list{
                    flex-direction: column;
                    li{
                        border: none;
                        align-items: center;
                        padding: 0;
                        flex-direction: row;
                        margin-bottom: 15px;
                        cursor: pointer;
                        .icon{
                            width: 48px;
                            height: 48px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            overflow: hidden;
                            background: transparent linear-gradient(270deg, #FF5D5E 0%, #FFBD3C 100%);
                            em{
                                font-style: normal;
                                color: $white;
                                font-family: $secondaryfontSemiBold;
                                font-size: 16px;
                                text-transform: uppercase;
                            }
                        }
                        .details{
                            display: flex;
                            flex-direction: column;
                            padding-left: 10px;
                            align-items: flex-start;
                        }
                        &:last-child{
                            margin: 0;
                        }
                        &:hover {
                            background:#fafafa;
                        }
                    }
                }
            }
            .recent_act_list{
                padding: 0;
                margin: 0;
                li{
                    display: flex;
                    font-family: $primaryfontRegular;
                    font-size: 14px;
                    color: $primary-color;
                    padding-left: 30px;
                    position: relative;
                    margin-bottom: 20px;
                    line-height: 18px;
                    &:last-child{
                        margin: 0;
                    }
                    &:before{
                        content: '';
                        width: 18px;
                        height: 18px;
                        border: 2px dashed #072430;
                        border-radius: 50%;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    em{
                        color: $secondary-color;
                        font-style: normal;
                    }
                }
            }
            .chart_demo{
                display: flex;
                flex-direction: column;
                align-items: center;
                .chart_circle{
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    border: 2px solid #02A8DF;
                    background: #02A8DF;
                    margin-bottom: 20px;
                }
                .chart_labels{
                    padding: 0;
                    margin: 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    li{
                        display: flex;
                        align-items: center;
                        position: relative;
                        padding-left: 20px;
                        line-height: normal;
                        margin-bottom: 8px;
                        min-width: 120px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                        span {
                            font-family: $neutralfontMedium;
                            font-size: 13px;
                        }
                        em{
                            font-family: $neutralfontSemiBold;
                            font-size: 16px;
                            font-style: normal;
                        }
                        &:before{
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 5px;
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                        }
                        &.hire{
                            &:before{
                            background: #89D16F;
                            }
                        }
                        &.process{
                            &:before{
                            background: #02A8DF;
                            }
                        }
                        &.reject{
                            &:before{
                            background: #F08484;
                            }
                        }
                        &.hold{
                            &:before{
                            background: #F3A852;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: $medium) {
            width: 100% !important;
            margin: 0 0 20px 0 !important;
        }
    }
    .recent_jobs_sec{
        display: flex;
        margin-top: 20px;
        .widget_item{
            &:first-child{
                width: 65%;
                margin-right: 20px;
            }
            &:last-child{
                width: 35%;
            }
        }
        &.two_equal_columns{
            .widget_item{
                &:first-child{
                    width: 50%;
                    margin-right: 20px;
                }
                &:last-child{
                    width: 50%;
                }
                .widget_body .widget_list li{
                    &.no_border{
                    padding-bottom: 0;
                    }
                    span.role_exp{
                        margin-bottom: 1rem;
                    }
                    span.view{
                        margin-top: auto;
                    }
                    span.posted_by{
                        padding-top: 15px;
                        padding-bottom: 0;
                        font-family: $secondaryfontRegular;
                        font-size: 12px;
                        color: $base-color;
                        margin-top: auto;
                    }
                    span.location{
                        font-family: $primaryfontRegular;
                    }
                }
            }
        }
        &.three_equal_columns{
            .widget_item{
                &:first-child{
                    width: 33%;
                    margin-right: 20px;
                }
                &:nth-child(2){
                    width: 33%;
                    margin-right: 20px;
                }
                &:last-child{
                    width: 33%;
                }
            }
        }
        @media screen and (max-width: $medium) {
            flex-wrap: wrap;
        }
    }
    &.emp_dashboard {
        .db_main {
            .db_list li {
                width: calc(33.3% - 20px);
            }
        }
        @media screen and (max-width: 1400px) {
            .db_main {
                .db_list {
                    li {
                        padding: 0 20px 0 20px;
                        .counts {
                            padding-left: 20px;
                        }
                    }
                    
                }
            }
        }
        @media screen and (max-width: 1200px) {
             flex-wrap: wrap;
             .db_left {
                width: 100%;
                .employer_profile {
                    width: 100%;
                    align-items: center;
                    flex-direction: row;
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                    justify-content: space-between;
                    .employer_top {
                        flex-direction: row;
                        justify-content: space-between;
                        .employer_logo {
                            margin-right: 10px;
                        }
                        .employer_name {
                            margin-top: 5px;
                        }
                    }   
                    .go_to_profile {
                        width: auto;
                        border-top: 0px;
                        margin-top: 0px;
                    }                
                }
            }
            .db_main {
                padding: 0px;
                width: 100%;
                padding-right: 0px;
                
            }
            .db_right {
                width: 300px;
                .recent_activities {
                    width: 100%;
                }
            }
        }
        @media screen and (max-width: $large) {
            .db_main  {width: 100%;     padding: 0 10px;}
            .db_right { width: 100%;}
        }
        @media screen and (max-width: $small) {
            .db_main  {
                .db_list li {
                    width: 100%;
                }
                .widget_item .widget_body .widget_list {
                    flex-wrap: wrap;
                    li {
                        margin-bottom: 10px;
                    }
                }
            }
            .db_right { width: 100%;}
        }


    }
    &.talent_matches {
        .widget_item {width: 100%;
            .widget_body {
                display: flex;
                width: 100%;
                .cards {
                    display: flex;
                    width: 100%;
                    border: 1px solid #ddd;
                }
            }
        }
    }
}

.export_btn{
    height: 40px;
    border: 1px solid #8A898966;
    color: $primary-color;
    border-radius: 6px;
    font-family: $secondaryfontMedium;
    font-size: 14px;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 15px;
    white-space: nowrap;
    em{
        width: 16px;
        height: 16px;
        background: url('@/../../../../public/images/main/export.svg') no-repeat center center;
        margin-right: 10px;
        font-style: normal;
        background-size: 100%;
    }
}

.bulk_upload{
    height: 40px;
    border: 1px solid $secondary-color !important;
    color: $secondary-color !important;
    border-radius: 6px;
    font-family: $secondaryfontMedium;
    font-size: 14px;
    background: $white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 15px;
    white-space: nowrap;
    box-shadow: none;
    em{
        width: 16px;
        height: 16px;
        background: url('@/../../../../public/images/main/bulk_upload.svg') no-repeat center center;
        margin-right: 10px;
        font-style: normal;
        background-size: 100%;
    }
    &:focus-visible{
        background: $white !important;
        border: 1px solid $secondary-color !important;
        color: $secondary-color !important;
        box-shadow: none !important;
    }
    &:after{
        display: none;
    }
}



.community_dashboard{
    .db_header{
       background: transparent;
       padding: 20px 0 0;
       h5{
         color: $primary-color;
         font-family: $primaryfontRegular;
         font-size: 30px;
       }
       @media screen and (max-width: $small) {
        h5 {
            font-size: 22px;
        }
       }
    }
    .db_main_wrapper{
       .db_main{
         padding: 0;
       }
    }
    @media screen and (max-width:$extraLarge) {
        > .container {
            max-width: 100% !important;
        }
    }
  }
.dropdown_invite{
  .dropdown-menu{
    background: $white;
    box-shadow: 0px 0px 10px #00000026;
    border-radius: 6px;
    border: none;
    text-align: right;
    min-width: 100px;
    .dropdown-item{
        font-family: $secondaryfontRegular;
        font-size: 14px;
        color: $primary-color;
        background: $white !important;
        &:hover{
            color: $secondary-color;
        }
    }
  }
}

.padlr0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.recent-matches {
     &.swiper {padding:20px;}
    .swiper-wrapper {
        .swiper-slide {
            width: 200px;
            height: 100%;
    min-height: 100%;
        }        
    }
    .swiper-button-next { 
        right: 0px;
        width: 32px;
        height: 40px;
        background: #F8F8F8;
        border-radius: 6px 0 0 6px;
        &:after {
            color: #072430; 
            font-size: 15px;
            font-weight: bold;
        }
        &.swiper-button-disabled {
            display: none;
        }
    }
    .swiper-button-prev{ 
        left: 0px;
        width: 32px;
        height: 40px;
        background: #F8F8F8;
        border-radius: 0 6px 6px 0;        
        &:after {
            color: #072430; 
            font-size: 15px;
            font-weight: bold;
        }
        &.swiper-button-disabled {
            display: none;
        }
    }
    .matches {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .matches-list {
        text-align: center;
        .match_comp_emp{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            .icon{
                width: 58px;
                height: 58px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                overflow: hidden;
                img{
                    max-width: 100%;
                    object-fit: contain;
                    width: 100%;
                }
            }
            .checkmark{
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                overflow: hidden;
                margin: 0 8px;
                background: $secondary-color url('@/../../../../public/images/main/checkmark.svg') no-repeat center center;
                background-size: 8px;
            }
        }
    }
    span{
        text-align: center;
        display: block;
        &.profile_icon{
            width: 36px;
            height: 36px;
            border: 1px solid #707070;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-bottom: 5px;
            img{
                max-width: 100%;
                object-fit: contain;
            }
        }
        &.profile_name{
            font-family: $primaryfontMedium;
            font-size: 15px;
            line-height: 18px;
            color: $primary-color;
            margin: 0 0 5px 0;
        }
        &.role_exp{
            font-family: $primaryfontRegular;
            font-size: 12px;
            color: $primary-color;
        }
        &.location{
            font-family: $primaryfontMedium;
            font-size: 12px;
            color: $primary-color;
            background: url('@/../../../../public/images/pin.png') no-repeat 0px center;
            background-size: 11px;
            display: inline-block;
            padding-left: 15px;
        }
        &.matched{
            font-family: $neutralfontSemiBold;
            font-size: 15px;
            color: $secondary-color;
            line-height: normal;
            padding-left: 17px;
            cursor: text;
            background: url('@/../../../../public/images/main/lightningorange.svg') no-repeat left center;
            margin: 15px 0;
        }
        &.view{
            border: 1px solid #8A89894D;
            border-radius: 14px;
            padding: 0 20px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            min-height: 28px;
            cursor: pointer;
            font-family: $secondaryfontRegular;
            font-size: 13px;
            color: $primary_color;
            line-height: normal;
            margin-top: 5px;
            &:hover {
                background: #8A89894D;
            }
            &.no_cursor{
                cursor: default;
            }
            &.no_border{
                border: none;
                padding: 0;
                height: auto;
                margin-top: 10px;
            }
        }
        &.posted_by{
            font-family: $neutralfontRegular;
            font-size: 12px;
            color: $primary-color;
            padding: 10px 0 5px;
            em{
                color: $secondary-color;
                font-style: normal;
                text-decoration: underline;
            }
        }
        &.dessig{
            font-family: $primaryfontRegular;
            font-size: 12px;
            color: $secondary-color;
        }
    }
}
